import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useClubs } from "src/features/club/ClubProvider";
import { CSVLink } from "react-csv";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";
import SoftDateRangePicker from "src/components/SoftDateRangePicker";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";
import DataTable from "src/components/Tables/DataTable";

// Data
import { getTableColumns } from "./tableColumns";
import { query, orderBy, where } from "firebase/firestore";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import { getClubTransactionsCollection } from "src/features/transaction/collection";
import { Modal } from "@mui/material";
import { AddTransactionModal } from "src/modals/AddTransactionModal";
import { AddDepositModal } from "src/modals/AddDepositModal";
import { RequestPaymentModal } from "src/modals/RequestPaymentModal";
import { getPaymentRequestsCollection } from "src/features/billing/collections";
import {
  format,
  startOfMonth,
  endOfMonth,
  subMonths,
  isWithinInterval,
  startOfDay,
  endOfDay,
} from "date-fns";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import WithPermissionWrapper from "src/components/WithPermissions/WithPermissionWrapper";

function TransactionList() {
  const navigate = useNavigate();
  const { selectedClubId, selectedClub, selectedLocation, selectedLocationId } =
    useClubs();
  const [menu, setMenu] = useState(null);
  const [transactionRows, setTransactionRows] = useState([]);
  const [openAddNewTransaction, setOpenAddNewTransaction] = useState(false);
  const [openAddNewDeposit, setOpenAddNewDeposit] = useState(false);
  const [openRequestPayment, setOpenRequestPayment] = useState(false);
  const [tableRows, setTableRows] = useState([]);
  const [csvData, setCsvData] = useState(null);
  const [isFiltered, setIsFiltered] = useState(false);
  const [dateRange, setDateRange] = useState([
    startOfMonth(subMonths(new Date(), 1)),
    endOfMonth(subMonths(new Date(), 1)),
  ]);

  const updateDateRange = (dates) => {
    dates[0] = startOfDay(dates[0]);
    dates[1] = endOfDay(dates[1]);

    setDateRange(dates);
  };

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = (filter) => {
    setMenu(null);
    if (filter === "paid") {
      setIsFiltered(true);
      const rows = [
        ...allTransactions,
        ...allPaymentRequests.map((value) => ({
          ...value,
          totalCost: value.amount,
          isPaymentRequest: true,
          member: {
            displayName: `${value.member?.firstName} ${value.member?.lastName}`,
          },
          type: "paymentRequest",
        })),
      ]
        .filter((row) => {
          const amountPaid =
            row?.payments?.reduce((acc, payment) => {
              if (payment?.status === "succeeded")
                return acc + (payment?.amount ?? 0) / 100;
              else return acc;
            }, 0) ?? 0;

          const totalCost = Math.round(row.totalCost * 100) / 100 || 0;

          return row.paid || amountPaid >= totalCost - (row.fuelCost ?? 0);
        })
        .sort((a, b) => {
          return b.createdAt.toDate() - a.createdAt.toDate();
        });

      setTransactionRows(rows);
      return;
    }
    if (filter === "unpaid") {
      setIsFiltered(true);
      const rows = [
        ...allTransactions,
        ...allPaymentRequests.map((value) => ({
          ...value,
          totalCost: value.amount,
          isPaymentRequest: true,
          member: {
            displayName: `${value.member?.firstName} ${value.member?.lastName}`,
          },
          type: "paymentRequest",
        })),
      ]
        .filter((row) => {
          const amountPaid =
            row?.payments?.reduce((acc, payment) => {
              if (payment?.status === "succeeded")
                return acc + (payment?.amount ?? 0) / 100;
              else return acc;
            }, 0) ?? 0;

          const totalCost = Math.round(row.totalCost * 100) / 100 || 0;

          return totalCost > 0 && amountPaid < totalCost - (row.fuelCost ?? 0);
        })
        .sort((a, b) => {
          return b.createdAt.toDate() - a.createdAt.toDate();
        });

      setTransactionRows(rows);
      return;
    }
    if (filter && filter !== "all") {
      setIsFiltered(true);
      const rows = [
        ...allTransactions,
        ...allPaymentRequests.map((value) => ({
          ...value,
          totalCost: value.amount,
          isPaymentRequest: true,
          member: {
            displayName: `${value.member?.firstName} ${value.member?.lastName}`,
          },
          type: "paymentRequest",
        })),
      ]
        .filter((row) => row.type === filter)
        .sort((a, b) => {
          return b.createdAt.toDate() - a.createdAt.toDate();
        });
      setTransactionRows(rows);
      return;
    } else if (filter === "all") {
      const rows = [
        ...allTransactions,
        ...allPaymentRequests.map((value) => ({
          ...value,
          totalCost: value.amount,
          isPaymentRequest: true,
          member: {
            displayName: `${value.member?.firstName} ${value.member?.lastName}`,
          },
          type: "paymentRequest",
        })),
      ].sort((a, b) => {
        return b.createdAt.toDate() - a.createdAt.toDate();
      });
      setTransactionRows(rows);
      setIsFiltered(false);
    }
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={Boolean(menu)}
      onClose={() => closeMenu()}
      keepMounted
    >
      {/* <MenuItem onClick={closeMenu}>Status: Paid</MenuItem>
      <MenuItem onClick={closeMenu}>Status: Refunded</MenuItem>
      <MenuItem onClick={closeMenu}>Status: Canceled</MenuItem> */}
      <MenuItem onClick={() => closeMenu("paid")}>Status: Paid</MenuItem>
      <MenuItem onClick={() => closeMenu("unpaid")}>Status: Unpaid</MenuItem>
      <MenuItem onClick={() => closeMenu("booking")}>Type: Booking</MenuItem>
      <MenuItem onClick={() => closeMenu("deposit")}>Type: Deposit</MenuItem>
      <MenuItem onClick={() => closeMenu("paymentRequest")}>
        Type: Payment Request
      </MenuItem>
      <Divider
        sx={{
          margin: "0.5rem 0",
        }}
      />
      <MenuItem onClick={() => closeMenu("all")}>
        <SoftTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </SoftTypography>
      </MenuItem>
    </Menu>
  );

  const clubsTransactionsCollectionRef = query(
    getClubTransactionsCollection(selectedClubId),
    where("createdAt", ">=", dateRange[0]),
    where("location.id", "==", selectedLocationId),
    orderBy("createdAt", "desc")
  );
  const { data: allTransactions } = useRealtimeCollectionData(
    clubsTransactionsCollectionRef,
    true
  );

  const clubsPaymentRequestsCollectionRef = query(
    getPaymentRequestsCollection(selectedClubId),
    where("location.id", "==", selectedLocationId),
    where("createdAt", ">=", dateRange[0])
  );
  const { data: allPaymentRequests } = useRealtimeCollectionData(
    clubsPaymentRequestsCollectionRef,
    true
  );

  useEffect(() => {
    const rows = [
      ...allTransactions.filter(
        (transaction) =>
          transaction?.createdAt &&
          isWithinInterval(transaction.createdAt.toDate(), {
            start: dateRange[0],
            end: dateRange[1],
          })
      ),
      ...allPaymentRequests
        .filter(
          (transaction) =>
            transaction?.createdAt &&
            isWithinInterval(transaction.createdAt.toDate(), {
              start: dateRange[0],
              end: dateRange[1],
            })
        )
        .map((value) => ({
          ...value,
          totalCost: value.amount,
          isPaymentRequest: true,
          member: {
            displayName: `${value.member?.firstName} ${value.member?.lastName}`,
          },
          type: "PaymentRequest",
        })),
    ].sort((a, b) => {
      return b.createdAt.toDate() - a.createdAt.toDate();
    });
    setTransactionRows(rows);
  }, [allTransactions, allPaymentRequests, selectedLocationId, dateRange]);

  useEffect(() => {
    updateCsvData(tableRows);
  }, [tableRows]);

  const updateCsvData = (rows) => {
    const csvDataFromRows = rows.map((row) => ({
      ID: row.id,
      Date: row.createdAt
        ? format(row.createdAt.toDate(), "yyyy/MM/dd hh:mm:ss")
        : "---",
      Member: row.member?.displayName || row.pilot?.displayName || "---",
      "Transaction Type": row.type,
      "Total Amount": Math.round(row.totalCost * 100) / 100,
      "Amount Paid":
        Math.round(
          (row.payments?.length > 0
            ? row.payments.reduce(
                (acc, payment) =>
                  payment?.status === "succeeded"
                    ? acc + payment.amount / 100
                    : acc,
                0
              )
            : 0) * 100
        ) / 100,
      "Service Fees": Math.round((row.fees?.applicationFee || 0) * 100) / 100,
      Revenue: Math.round((row.fees?.clubRevenue || 0) * 100) / 100,
    }));

    setCsvData(csvDataFromRows);
  };

  const onRowSelected = (row) => {
    if (row.type === "paymentRequest") {
      navigate(`/billing/paymentRequest/${row.id}`);
    } else {
      navigate(`/billing/invoices/${row.id}`);
    }
  };

  const addNewTransaction = () => {
    setOpenAddNewTransaction(true);
  };

  const addDeposit = () => {
    setOpenAddNewDeposit(true);
  };

  const requestPayment = () => {
    setOpenRequestPayment(true);
  };

  const handleNewTransactionClose = () => {
    setOpenAddNewTransaction(false);
  };

  const handleNewDepositClose = () => {
    setOpenAddNewDeposit(false);
  };

  const handleRequestPaymentClose = () => {
    setOpenRequestPayment(false);
  };

  const tableColumns = useMemo(() => getTableColumns(), []);
  const tableData = useMemo(() => transactionRows, [transactionRows]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={2}
        >
          <SoftBox>
            <WithPermissions permissions={systemPermissions.ADD_INVOICES}>
              <SoftButton
                variant="gradient"
                color="info"
                onClick={addNewTransaction}
              >
                New Transaction
              </SoftButton>
            </WithPermissions>
            <WithPermissions permissions={systemPermissions.DEPOSIT_FUNDS}>
              <SoftButton
                variant="gradient"
                color="secondary"
                sx={{ marginLeft: 3 }}
                onClick={addDeposit}
              >
                Deposit Funds
              </SoftButton>
            </WithPermissions>
            <WithPermissions permissions={systemPermissions.REQUEST_PAYMENT}>
              <SoftButton
                variant="gradient"
                color="secondary"
                sx={{ marginLeft: 3 }}
                onClick={requestPayment}
              >
                Request Payment
              </SoftButton>
            </WithPermissions>
          </SoftBox>
          <SoftBox display="flex">
            <SoftDateRangePicker
              value={dateRange}
              onChange={(newDates) => updateDateRange(newDates)}
            />
            <SoftBox mx={1}>
              <Divider orientation="vertical" />
            </SoftBox>
            <SoftButton
              variant={menu || isFiltered ? "contained" : "outlined"}
              color="dark"
              onClick={openMenu}
            >
              filters&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </SoftButton>
            {renderMenu}
            {csvData && (
              <SoftBox ml={1}>
                <SoftBox>
                  <CSVLink
                    data={csvData}
                    filename={`${selectedClub.name}-${
                      selectedLocation?.icao
                    }-billing_invoices-${format(new Date(), "MM/dd/yyyy")}.csv`}
                    target="_blank"
                  >
                    <SoftButton variant="outlined" color="dark">
                      <Icon>description</Icon>
                      &nbsp;export csv
                    </SoftButton>
                  </CSVLink>
                </SoftBox>
              </SoftBox>
            )}
          </SoftBox>
        </SoftBox>
        <Card>
          {transactionRows && transactionRows.length > 0 && (
            <DataTable
              onRowSelect={onRowSelected}
              entriesPerPage={{ defaultValue: 50, entries: [10, 20, 50, 100] }}
              table={{
                columns: tableColumns,
                rows: tableData,
              }}
              onRowsChanged={(rows) => {
                setTableRows(rows.map((row) => row.original));
              }}
              canSearch
            />
          )}
        </Card>
      </SoftBox>
      <Footer />
      <Modal
        open={openAddNewTransaction}
        onClose={handleNewTransactionClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <AddTransactionModal handleClose={handleNewTransactionClose} />
        </SoftBox>
      </Modal>
      <Modal
        open={openAddNewDeposit}
        onClose={handleNewDepositClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <AddDepositModal handleClose={handleNewDepositClose} />
        </SoftBox>
      </Modal>
      <Modal
        open={openRequestPayment}
        onClose={handleRequestPaymentClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <RequestPaymentModal handleClose={handleRequestPaymentClose} />
        </SoftBox>
      </Modal>
    </DashboardLayout>
  );
}

export default WithPermissionWrapper(
  systemPermissions.VIEW_INVOICES,
  TransactionList
);
