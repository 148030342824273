import { useParams } from "react-router-dom";
import { useClubs } from "src/features/club/ClubProvider";
import { doc } from "firebase/firestore";
import { useState } from "react";

import useRealtimeDocumentData from "src/features/firebase/firestore/useRealtimeDocumentData";
import { getCourseLessonsCollection } from "src/features/lms/collections";

import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";

import Card from "@mui/material/Card";
import SoftBox from "src/components/SoftBox";
import TaskTable from "./components/TaskTable";
import Grid from "@mui/material/Grid";
import SoftTypography from "src/components/SoftTypography";
import { LessonInfo, LessonHeader } from "../../components";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import { AddLessonModal } from "src/modals/AddLessonModal";
import { iconButtonStyles } from "src/components/Tables/DragAndDrop/iconButtonStyles";
import Modal from "@mui/material/Modal";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import SoftButton from "src/components/SoftButton";
import LectureNotes from "../../components/LectureNotes";

export default function LessonDetails() {
  const { selectedClubId } = useClubs();
  const { courseId, lessonId, stageId } = useParams();
  const [lessonModalIsOpen, setLessonModalIsOpen] = useState(false);
  const [lectureNotesOpen, setLectureNotesOpen] = useState(false);

  const openLessonModal = () => setLessonModalIsOpen(true);
  const closeLessonModal = () => setLessonModalIsOpen(false);

  const { data: lesson } = useRealtimeDocumentData(
    doc(getCourseLessonsCollection(selectedClubId, courseId, stageId), lessonId)
  );

  const toggleLectureNotes = () => {
    setLectureNotesOpen(!lectureNotesOpen);
  };

  const closeLectureNotes = () => {
    setLectureNotesOpen(false);
  };

  return (
    <DashboardLayout>
      {lectureNotesOpen ? (
        <LectureNotes
          onClose={closeLectureNotes}
          content={lesson.lectureNotes}
        />
      ) : null}
      <DashboardNavbar pageTitle="Lesson" />
      <SoftBox py={3}>
        {lesson ? (
          <Card>
            <Grid container p={2} spacing={2}>
              <Grid item xs={12} md={6}>
                <LessonHeader lesson={lesson} />
                <TaskTable
                  lesson={lesson}
                  courseId={courseId}
                  stageId={stageId}
                  selectedClubId={selectedClubId}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <LessonInfo lesson={lesson}>
                  <SoftBox display="flex" gap={2}>
                    {lesson.lectureNotes ? (
                      <WithPermissions
                        permissions={systemPermissions.VIEW_LMS_STUDENTS}
                      >
                        <SoftButton
                          onClick={toggleLectureNotes}
                          color="secondary"
                          variant="outlined"
                          size="small"
                        >
                          <SoftTypography
                            fontSize="small"
                            ml="4px"
                            fontWeight="medium"
                            color="secondary"
                          >
                            Lecture Notes
                          </SoftTypography>
                        </SoftButton>
                      </WithPermissions>
                    ) : null}
                    <WithPermissions
                      permissions={systemPermissions.EDIT_LMS_COURSES}
                    >
                      <IconButton
                        aria-label="edit"
                        sx={iconButtonStyles}
                        onClick={openLessonModal}
                      >
                        <Icon>edit</Icon>
                      </IconButton>
                    </WithPermissions>
                  </SoftBox>
                </LessonInfo>
                <Modal
                  open={lessonModalIsOpen}
                  onClose={closeLessonModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  sx={{
                    backdropFilter: "blur(2px)",
                  }}
                >
                  <SoftBox>
                    <AddLessonModal
                      courseId={lesson.courseId}
                      stageId={lesson.stageId}
                      lessonCount={0}
                      lessonData={lesson}
                      handleClose={closeLessonModal}
                    />
                  </SoftBox>
                </Modal>
              </Grid>
            </Grid>
          </Card>
        ) : (
          <SoftTypography>Loading...</SoftTypography>
        )}
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}
