import { useEffect, useState } from "react";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import { Select, Option } from "src/components/SoftMultiSelect";
import Checkbox from "@mui/material/Checkbox";

function CalendarFilter({
  label,
  placeholder,
  defaultValues,
  options,
  onChange,
}: {
  label: string;
  placeholder: string;
  defaultValues: { id: string; title: string; [key: string]: any }[];
  options: { id: string; title: string; [key: string]: any }[];
  onChange: (value: any[]) => void;
}) {
  const [selectedOptions, setSelectedOptions] = useState<any[] | null>(
    options.filter((option) =>
      defaultValues.some((value) => value.id === option.id)
    )
  );

  useEffect(() => {
    setSelectedOptions(
      options.filter((option) =>
        defaultValues.some((value) => value.id === option.id)
      )
    );
  }, [defaultValues, options]);

  return (
    <SoftBox px={1}>
      {label && (
        <SoftBox mb={1} ml={0.5}>
          <SoftTypography component="label" variant="caption" fontWeight="bold">
            {label}
          </SoftTypography>
        </SoftBox>
      )}
      {selectedOptions && (
        <Select
          // labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedOptions}
          onChange={(event, newValue) => {
            if (event) {
              setSelectedOptions(newValue ?? []);
              onChange(
                newValue.sort((a, b) =>
                  a.type === "Simulators" || b.type === "Simulators"
                    ? 0
                    : a.payload.index - b.payload.index
                ) ?? []
              );
            }
          }}
          renderValue={(selected) => {
            if (selected.length > 0) {
              return selected.length === options.length
                ? placeholder ?? "All"
                : `${selected?.length} Selected`; // selected?.map((s) => s.label).join(", ")
            } else {
              return "None Selected";
            }
          }}
        >
          {options.map((option) => (
            <Option key={option.id} value={option}>
              <SoftBox sx={{ display: "flex" }}>
                <Checkbox
                  checked={selectedOptions?.some(
                    (selected) => selected.id === option.id
                  )}
                />
                <SoftTypography variant="body2">{option.title}</SoftTypography>
              </SoftBox>
            </Option>
          ))}
        </Select>
      )}
    </SoftBox>
  );
}

export default CalendarFilter;
