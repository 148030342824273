import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";
import DataTable from "src/components/Tables/DataTable";

// import PieChart from './components/PieChart';
// import polarChartData from 'template-app/layouts/pages/charts/data/polarChartData';

import MaintenanceRemainingCell from "src/components/MaintenanceRemainingCell";
import DefaultCell from "src/pages/reports/components/DefaultTableCell";

import { useClubs } from "src/features/club/ClubProvider";
import { collection, getDocs } from "firebase/firestore";
import {
  getAircraftCollection,
  getAircraftReminder,
  getAircraftReminderTemplatesCollection,
} from "src/features/aircraft/collections";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import Modal from "@mui/material/Modal";
import { AddReminderModal } from "src/modals/AddReminderModal";
import { ReminderCompletedModal } from "src/modals/ReminderCompletedModal";
import { calculateReminderRemainingTime } from "src/features/aircraft/utils";
import Swal from "sweetalert2";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { format, add } from "date-fns";
import WithPermissionWrapper from "src/components/WithPermissions/WithPermissionWrapper";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";
import { systemPermissions } from "src/interfaces/roles/role.interface";

const columns = (handleEditClick, handleDeleteClick, handleCompletedClick) => [
  {
    Header: "Tail No.",
    width: 60,
    accessor: "aircraft.tailNumber",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Description",
    width: 150,
    accessor: "name",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Current Hobbs",
    width: 40,
    accessor: "aircraft.hobbs",
    Cell: ({ value }) => <DefaultCell value={Math.round(value * 100) / 100} />,
  },
  {
    Header: "Current Tach",
    width: 40,
    accessor: "aircraft.tach1",
    Cell: ({ value }) => <DefaultCell value={Math.round(value * 100) / 100} />,
  },
  {
    Header: "Remaining",
    accessor: "remaining",
    Cell: ({ row: { original } }) => (
      <MaintenanceRemainingCell reminder={original} />
    ),
  },
  {
    Header: "Actions",
    width: 40,
    Cell: ({ row: { original } }) => (
      <SoftBox display="flex" alignItems="center">
        <WithPermissions
          permissions={[systemPermissions.COMPLETE_MX_REMINDERS]}
        >
          <SoftButton
            color="success"
            style={{ padding: "0 5px", minWidth: "16px" }}
            variant="text"
            onClick={() => handleCompletedClick(original)}
          >
            <Icon className="material-icons-round">check</Icon>
          </SoftButton>
        </WithPermissions>
        <WithPermissions permissions={[systemPermissions.UPDATE_MX_REMINDERS]}>
          <SoftButton
            color="info"
            style={{ padding: "0 5px", minWidth: "16px" }}
            variant="text"
            onClick={() => handleEditClick(original)}
          >
            <Icon className="material-icons-round">edit</Icon>
          </SoftButton>
        </WithPermissions>
        <WithPermissions permissions={[systemPermissions.DELETE_MX_REMINDERS]}>
          <SoftButton
            color="info"
            style={{ padding: 0, minWidth: "16px" }}
            variant="text"
            onClick={() => handleDeleteClick(original)}
          >
            <Icon className="material-icons-round">delete</Icon>
          </SoftButton>
        </WithPermissions>
      </SoftBox>
    ),
  },
];

function Reminders() {
  const { selectedClubId, selectedClub, selectedLocation } = useClubs();
  const [menu, setMenu] = useState(null);
  // const [aircraft, setAircraft] = useState([]);
  // const [rowsTemplates, setRowsTemplates] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [rowsReminders, setRowsReminders] = useState([]);
  const [isAddReminderOpen, setIsAddReminderOpen] = useState(false);
  const [reminderToEdit, setReminderToEdit] = useState();
  const [isMarkAsCompletedModalOpen, setIsMarkAsCompletedModalOpen] =
    useState(false);
  const { deleteDataFromRef } = entityCrudUtils();

  const handleCloseAddReminder = (reminder) => {
    if (reminder?.name) {
      const remaining = calculateReminderRemainingTime(reminder);
      if (reminderToEdit) {
        const index = rowsReminders.findIndex(
          (r) => r.id === reminderToEdit.id
        );
        const reminderList = Array.from(rowsReminders);
        reminderList.splice(index, 1, { ...reminder, remaining });
        setRowsReminders(
          reminderList.sort((a, b) => {
            if (a.remaining.percentComplete > b.remaining.percentComplete) {
              return -1;
            }
            if (a.remaining.percentComplete < b.remaining.percentComplete) {
              return 1;
            }
            return 0;
          })
        );
      } else {
        setRowsReminders((prev) =>
          [...prev, { ...reminder, remaining }].sort((a, b) => {
            if (a.remaining.percentComplete > b.remaining.percentComplete) {
              return -1;
            }
            if (a.remaining.percentComplete < b.remaining.percentComplete) {
              return 1;
            }
            return 0;
          })
        );
      }
    }

    setReminderToEdit(null);
    setIsAddReminderOpen(false);
  };

  useEffect(() => {
    const csvDataFromRows = rowsReminders.map((row) => {
      let currentMeterReading;
      if (row?.meterBasedType?.value === "tach1") {
        currentMeterReading = row.aircraft.tach1;
      } else if (row?.meterBasedType?.value === "hobbs") {
        currentMeterReading = row.aircraft.hobbs;
      } else if (row?.meterBasedType?.value === "tt") {
        currentMeterReading = row.aircraft.tt;
      }

      let notifications = {};
      for (let i = 0; i < row.alerts.length; i++) {
        const alert = row.alerts[i];
        notifications[
          `Reminder Alert ${i + 1}`
        ] = `${alert?.value} ${alert?.type?.value}`;
      }

      const timeBasedLastCompletedOn =
        typeof row.timeBasedLastCompletedOn?.toDate === "function"
          ? row.timeBasedLastCompletedOn?.toDate()
          : row.timeBasedLastCompletedOn;

      let lastCompleted;
      let reminderDue;
      let remindEvery;
      if (row.type?.value === "meter") {
        lastCompleted = row.meterBasedLastCompleted;
        remindEvery = `${row.meterBasedRemindEvery} ${row.meterBasedRemindEveryUnit?.value}`;
        reminderDue = currentMeterReading + row.meterBasedRemindEvery;
      } else if (row.type?.value === "time") {
        lastCompleted = format(timeBasedLastCompletedOn, "dd MMM yyy");
        remindEvery = `${row.timeBasedRemindEvery} ${row.timeBasedRemindEveryUnit?.value}`;
        reminderDue = format(
          add(timeBasedLastCompletedOn, {
            [`${row.timeBasedRemindEveryUnit?.value}`]:
              row.timeBasedRemindEvery,
          }),
          "dd MMM yyy"
        );
      } else {
        lastCompleted = `${row.meterBasedLastCompleted} / ${format(
          timeBasedLastCompletedOn,
          "dd MMM yyy"
        )}`;
        remindEvery = `${row.meterBasedRemindEvery} ${row.meterBasedRemindEveryUnit?.value} / ${row.timeBasedRemindEvery} ${row.timeBasedRemindEveryUnit?.value}`;
        reminderDue = `${
          row.meterBasedLastCompleted + row.meterBasedRemingEvery
        } / ${format(
          add(timeBasedLastCompletedOn, {
            [`${row.timeBasedRemindEveryUnit?.value}`]:
              row.timeBasedRemindEvery,
          }),
          "dd MMM yyy"
        )}`;
      }

      return {
        "Tail #": row.aircraft?.tailNumber,
        Aircraft: row.aircraft?.displayName,
        Description: row.name,
        "Current Hobbs": row.aircraft?.hobbs,
        "Current Tach": row.aircraft?.tach1,
        "Last Completed": lastCompleted,
        "Remind Every": remindEvery,
        "Reminder Due": reminderDue,
        Remaining: row.remaining?.timeRemaining,
        "Percent Remaining": row.remaining?.percentRemaining,
        "Percent Complete": row.remaining?.percentComplete,
        ...notifications,
      };
    });

    setCsvData(csvDataFromRows);
  }, [rowsReminders]);

  // const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const { data: allAircraft, isDataLoaded: aircraftLoaded } =
    useRealtimeCollectionData(getAircraftCollection(selectedClubId), false);

  const fetchAircraftReminders = async (aircrafts) => {
    let reminders = [];
    await Promise.all(
      aircrafts.map(async (aircraftDoc) => {
        const remindersDocsArray = await getDocs(
          collection(
            getAircraftCollection(selectedClubId),
            aircraftDoc.id,
            "maintenanceReminders"
          )
        );

        reminders = reminders.concat(
          remindersDocsArray.docs
            .filter((doc) => !doc.data().deleted)
            .map((reminderDoc) => {
              const reminder = reminderDoc.data();
              const remaining = calculateReminderRemainingTime({
                ...reminder,
                aircraft: aircraftDoc,
                timeBasedLastCompletedOn:
                  reminder?.timeBasedLastCompletedOn?.toDate(),
              });

              return {
                ...reminderDoc.data(),
                aircraft: aircraftDoc,
                remaining,
                id: reminderDoc.id,
              };
            })
        );
      })
    );
    reminders.sort((a, b) => {
      if (a.remaining.timeRemaining < b.remaining.timeRemaining) {
        return -1;
      }
      if (a.remaining.timeRemaining > b.remaining.timeRemaining) {
        return 1;
      }
      return 0;
    });
    setRowsReminders(reminders);
  };

  useEffect(() => {
    if (aircraftLoaded && allAircraft) {
      // setAircraft(allAircraft);
      fetchAircraftReminders(allAircraft);
    }
  }, [allAircraft]);

  const { data: allTemplates, isDataLoaded: templatesLoaded } =
    useRealtimeCollectionData(getAircraftReminderTemplatesCollection(), false);

  useEffect(() => {
    if (templatesLoaded && allTemplates?.length > 0) {
      // setRowsTemplates(allTemplates);
    }
  }, [allTemplates, templatesLoaded]);

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={closeMenu}>Status: Paid</MenuItem>
      <MenuItem onClick={closeMenu}>Status: Refunded</MenuItem>
      <MenuItem onClick={closeMenu}>Status: Canceled</MenuItem>
      <Divider
        sx={{
          margin: "0.5rem 0",
        }}
      />
      <MenuItem onClick={closeMenu}>
        <SoftTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </SoftTypography>
      </MenuItem>
    </Menu>
  );
  const handleEditClick = (reminder) => {
    setReminderToEdit(reminder);
    setIsAddReminderOpen(true);
  };
  const handleMarkAsCompletedClick = (reminder) => {
    setReminderToEdit(reminder);
    setIsMarkAsCompletedModalOpen(true);
  };

  const handleDeleteClick = (reminder) => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.value) {
          await deleteDataFromRef(
            getAircraftReminder(
              selectedClubId,
              reminder.aircraft.id,
              reminder.id
            )
          );
          fetchAircraftReminders(allAircraft);
        }
      });
  };
  const handleCompletedModalChanged = () => {
    setIsMarkAsCompletedModalOpen(false);
    setReminderToEdit(null);
    fetchAircraftReminders(allAircraft);
  };

  const tableColumns = columns(
    handleEditClick,
    handleDeleteClick,
    handleMarkAsCompletedClick
  );
  return (
    <DashboardLayout>
      <Modal
        open={isAddReminderOpen}
        onClose={() => handleCloseAddReminder}
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <AddReminderModal
            handleClose={handleCloseAddReminder}
            existingData={reminderToEdit}
            existingReminders={rowsReminders}
          />
        </SoftBox>
      </Modal>
      <Modal
        open={isMarkAsCompletedModalOpen}
        onClose={() => handleCompletedModalChanged}
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <ReminderCompletedModal
            handleClose={handleCompletedModalChanged}
            existingData={reminderToEdit}
          />
        </SoftBox>
      </Modal>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Grid container spacing={3}>
          {/* <Grid item xs={12} md={7} xl={8}>
            <Card>
              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                mb={2}
                p={3}
              >
                <SoftBox lineHeight={1}>
                  <SoftTypography variant="h6" fontWeight="medium">
                    Templates
                  </SoftTypography>
                  <SoftTypography variant="button" fontWeight="regular" color="text">
                    Templates can be applied in bulk, to save time creating replicated reminders.
                  </SoftTypography>
                </SoftBox>
                <SoftBox display="flex">
                  <SoftButton color="info">
                    <Icon>add</Icon>
                    Add Template
                  </SoftButton>
                </SoftBox>
              </SoftBox>
              <DataTable
                table={{ columns: templateColumns, rows: rowsTemplates }}
                entriesPerPage={{ defaultValue: 10, entries: [10, 20, 50, 100] }}
                canSearch
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={5} xl={4}>
            <PieChart title="Aircraft Reminders" chart={polarChartData} height="300px" />
          </Grid> */}
          <Grid item xs={12}>
            <Card>
              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                mb={2}
                p={3}
              >
                <SoftBox lineHeight={1}>
                  <SoftTypography variant="h5" fontWeight="medium">
                    All Reminders
                  </SoftTypography>
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    All reminders for all aircraft.
                  </SoftTypography>
                </SoftBox>
                <SoftBox display="flex">
                  <WithPermissions
                    permissions={[systemPermissions.CREATE_MX_REMINDERS]}
                  >
                    <SoftButton
                      color="info"
                      onClick={() => setIsAddReminderOpen(true)}
                    >
                      <Icon>add</Icon>
                      Add Reminder
                    </SoftButton>
                  </WithPermissions>
                  {/* <SoftBox ml={1}>
                    <SoftButton
                      variant={menu ? 'contained' : 'outlined'}
                      color="dark"
                      onClick={openMenu}
                    >
                      filters&nbsp;
                      <Icon>keyboard_arrow_down</Icon>
                    </SoftButton>
                  </SoftBox> */}
                  {renderMenu}
                  <WithPermissions
                    permissions={[systemPermissions.EXPORT_MX_REMINDERS]}
                  >
                    <SoftBox ml={1}>
                      <CSVLink
                        data={csvData}
                        filename={`${selectedClub.name}-${
                          selectedLocation?.icao
                        }-mx_reminders-${format(new Date(), "MM/dd/yyyy")}.csv`}
                        target="_blank"
                      >
                        <SoftButton variant="outlined" color="dark">
                          <Icon>description</Icon>
                          &nbsp;export csv
                        </SoftButton>
                      </CSVLink>
                    </SoftBox>
                  </WithPermissions>
                </SoftBox>
              </SoftBox>
              <DataTable
                table={{ columns: tableColumns, rows: rowsReminders }}
                selectedRowId={null}
                entriesPerPage={{
                  defaultValue: 10,
                  entries: [10, 20, 50, 100],
                }}
                canSearch
              />
            </Card>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default WithPermissionWrapper(
  systemPermissions.VIEW_MX_REMINDERS,
  Reminders
);
