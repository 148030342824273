import {
  PersonOutlined,
  CircleOutlined,
  CheckCircleOutlined,
  HighlightOffOutlined,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import IconAvatar from "src/components/IconAvatar";
import SoftBox from "src/components/SoftBox";
import SoftButton from "src/components/SoftButton";
import SoftTypography from "src/components/SoftTypography";

import { GradeLessonModal } from "src/pages/lms/modals/GradeLessonModal";
import Modal from "@mui/material/Modal";
import { useMemo, useState } from "react";
import { getColorFromGrade, getUnapprovedMessage } from "src/pages/lms/utils";
import { useLms } from "src/features/lms/LmsProvider";
import { Tooltip } from "@mui/material";

const LessonGrade = ({ grade }) => {
  const color = getColorFromGrade(grade);
  return (
    <SoftBox display="flex" gap="0.3rem" alignItems="center">
      {grade === "S" && <CheckCircleOutlined fontSize="small" color={color} />}
      {grade === "U" && <HighlightOffOutlined fontSize="small" color={color} />}
      {grade === "I" && <CircleOutlined fontSize="small" color={color} />}
      <SoftTypography fontSize="small" fontWeight="medium" color={color}>
        {grade === "S" && "Satisfactory"}
        {grade === "U" && "Unsatisfactory"}
        {grade === "I" && "Incomplete"}
      </SoftTypography>
    </SoftBox>
  );
};

LessonGrade.propTypes = {
  grade: PropTypes.oneOf(["S", "U", "I"]).isRequired,
};

function LessonFooter({ lesson, studentName = "", refreshLesson = () => {} }) {
  const [gradeLessonModalIsOpen, setGradeLessonModalIsOpen] = useState(false);
  const { checkIsApprovedInstructor } = useLms();

  const openGradeLessonModal = () => setGradeLessonModalIsOpen(true);
  const closeGradeLessonModal = () => {
    setGradeLessonModalIsOpen(false);
    refreshLesson(true);
  };
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    !userIsApproved && setOpen(true);
  };

  const userIsApproved = useMemo(
    () => checkIsApprovedInstructor(lesson),
    [lesson, checkIsApprovedInstructor]
  );

  const getButtonColors = () => {
    const color = userIsApproved ? "primary" : "secondary";
    return {
      button: color,
      text: lesson.grade?.id ? color : "white",
    };
  };

  return (
    <SoftBox
      m={2}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <SoftBox display="flex" alignItems="center" gap={1}>
        <IconAvatar>
          <PersonOutlined fontSize="extra-small" color="secondary" />
        </IconAvatar>
        <SoftTypography variant="button" fontWeight="medium" color="text">
          {studentName}
        </SoftTypography>
      </SoftBox>
      <SoftBox display="flex" alignItems="center" gap={2}>
        <Modal
          open={gradeLessonModalIsOpen}
          onClose={closeGradeLessonModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            backdropFilter: "blur(2px)",
          }}
        >
          <SoftBox>
            <GradeLessonModal
              lesson={lesson}
              handleClose={closeGradeLessonModal}
            />
          </SoftBox>
        </Modal>
        {lesson.grade?.lessonGrade && (
          <LessonGrade grade={lesson.grade?.lessonGrade} />
        )}
        <Tooltip
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          title={getUnapprovedMessage(lesson)}
        >
          <span>
            <SoftButton
              onClick={openGradeLessonModal}
              color={getButtonColors().button}
              variant={lesson.grade?.id ? "outlined" : "contained"}
              disabled={!userIsApproved}
            >
              <SoftTypography
                fontSize="small"
                ml="4px"
                fontWeight="medium"
                color={getButtonColors().text}
              >
                {lesson.grade?.id ? "Edit Grade" : "Grade Lesson"}
              </SoftTypography>
            </SoftButton>
          </span>
        </Tooltip>
      </SoftBox>
    </SoftBox>
  );
}

LessonFooter.propTypes = {
  lesson: PropTypes.object.isRequired,
  studentName: PropTypes.string,
  refreshLesson: PropTypes.func,
};

export default LessonFooter;
