// react-quill components
import ReactQuill, { Quill } from "react-quill-new";

// react-quill styles
import "react-quill-new/dist/quill.snow.css";

// Custom styles for the SoftEditor
import SoftEditorRoot from "src/components/SoftEditor/SoftEditorRoot";

import ImageResize from "quill-image-resize-module-react";

Quill.register("modules/imageResize", ImageResize);

function SoftEditor({ sx = {}, ...rest }) {
  return (
    <SoftEditorRoot sx={sx}>
      <ReactQuill theme="snow" {...rest} />
    </SoftEditorRoot>
  );
}

export default SoftEditor;
