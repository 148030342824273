import { useState } from "react";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import { UserLookup } from "src/pages/resources/instructors/UserLookup";
import InstructorPreferences from "./InstructorPreferences";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "15px",
  overflow: "scroll",
  boxShadow: 24,
  maxHeight: "90vh",
  p: 4,
};

function NewInstructorDetails({ onCompleted }) {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedInstructor, setSelectedInstructor] = useState(null);
  const steps = ["User Details", "Preferences"];
  const handleOnNext = (instructor) => {
    setSelectedInstructor(instructor);
    setActiveStep(activeStep + 1);
  };

  const showStep = (index) => {
    switch (index) {
      case 0:
        return (
          <UserLookup onClose={() => onCompleted()} onNext={handleOnNext} />
        );
      case 1:
        return (
          <InstructorPreferences
            instructor={selectedInstructor}
            handleClose={() => onCompleted()}
          />
        );
      default:
        return (
          <UserLookup onClose={() => onCompleted()} onNext={handleOnNext} />
        );
    }
  };

  return (
    <SoftBox sx={style}>
      <SoftTypography textAlign="center" textTransform="capitalize">
        Add new instructor
      </SoftTypography>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {showStep(activeStep)}
    </SoftBox>
  );
}

export default NewInstructorDetails;
