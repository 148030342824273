import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Tabs,
  Tab,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import {
  CollectionReference,
  DocumentData,
  collection,
  getDocs,
  query,
  where,
  or,
  QuerySnapshot,
} from "firebase/firestore";
import { getUsersCollection } from "src/features/user/collections";
import { useEffect, useState } from "react";
import { INote } from "./interface";
import SingleNote from "./SingleNote";
import {
  getClubBookingsCollection,
  getNotesCollectionGroup,
} from "src/features/club/collections";
import { IUseClub } from "src/interfaces";
import { useClubs } from "src/features/club/ClubProvider";
import NoteInput from "./NoteInput";
import NotesList from "./NotesList";
import { useUser } from "src/features/user/UserProvider";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { usePermissions } from "src/hooks/usePermissions";
import { systemPermissions } from "src/interfaces/roles/role.interface";

import colors from "src/assets/theme/base/colors";
import borders from "src/assets/theme/base/borders";
const { light, grey, badgeColors } = colors;

interface INotesProps {
  selectedUserId?: string;
  fbRef?: CollectionReference<DocumentData>;
  defaultInternal?: boolean;
}

const tabStyles = {
  backgroundColor: light.main,
  lineHeight: 1,
  border: 1,
  borderColor: grey[400],
  borderRadius: borders.borderRadius.md,
  mr: 2,
  "& .Mui-selected": {
    color: `${badgeColors.primary.text} !important`,
    backgroundColor: badgeColors.light.background,
  },
  "& .MuiTab-root": {
    px: 2,
  },
};

export default function Notes({
  selectedUserId,
  fbRef,
  defaultInternal = false,
}: INotesProps) {
  const { userId } = useUser();
  const { hasAccess } = usePermissions();
  const { addData } = entityCrudUtils();
  const { selectedClubId, selectedLocationId } = useClubs() as IUseClub;
  const [notes, setNotes] = useState<INote[]>([]);
  const [userNotes, setUserNotes] = useState<INote[]>([]);
  const [bookingNotes, setBookingNotes] = useState<INote[]>([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const notesRef = fbRef
    ? collection(fbRef, "notes")
    : collection(getUsersCollection(), `${selectedUserId}/notes`);

  const sortNotes = (notesArray: INote[]) =>
    notesArray.sort((a, b) => {
      if (a.createdAt > b.createdAt) {
        return -1;
      }
      if (a.createdAt < b.createdAt) {
        return 1;
      }
      return 0;
    });

  const getNotes = async () => {
    setIsLoading(true);
    if (fbRef) {
      const noteDocs = await getDocs(
        query(notesRef, where("deleted", "==", false))
      );
      const newNotes = noteDocs.docs.map(
        (doc) => ({ ...doc.data(), id: doc.id } as INote)
      );
      setNotes(
        sortNotes(
          hasAccess(systemPermissions.VIEW_INTERNAL_NOTES)
            ? newNotes
            : newNotes.filter((note) => !note.internal)
        )
      );
    } else if (selectedUserId) {
      // get notes about or by the user
      const userNoteDocs: QuerySnapshot<DocumentData> = await getDocs(
        query(
          getNotesCollectionGroup(),
          where("clubId", "==", selectedClubId),
          where("sourceId", "==", selectedUserId),
          where("deleted", "==", false)
        )
      );
      const retrievedUserNotes = userNoteDocs.docs.map(
        (doc) => ({ ...doc.data(), id: doc.id } as INote)
      );

      setUserNotes(
        sortNotes(
          hasAccess(systemPermissions.VIEW_INTERNAL_NOTES)
            ? retrievedUserNotes
            : retrievedUserNotes.filter((note) => !note.internal)
        )
      );

      // get all bookings in this club where the selected user is a pilot or instructor
      const bookings = await getDocs(
        query(
          getClubBookingsCollection(selectedClubId, selectedLocationId),
          or(
            where("extendedProps.instructor.value", "==", selectedUserId),
            where("extendedProps.pilot.value", "==", selectedUserId),
            where("pilot2.value", "==", selectedUserId)
          )
        )
      );
      const retrievedBookingNotes: INote[] = [];
      await Promise.all(
        bookings.docs.map(async (booking) => {
          const bookingNoteDocs = await getDocs(
            query(
              collection(
                getClubBookingsCollection(selectedClubId, selectedLocationId),
                `${booking.id}/notes`
              ),
              where("deleted", "==", false)
            )
          );
          bookingNoteDocs.docs.forEach((bookingNote) => {
            if (
              hasAccess(systemPermissions.VIEW_INTERNAL_NOTES) ||
              !bookingNote.data().internal
            ) {
              retrievedBookingNotes.push({
                ...bookingNote.data(),
                id: bookingNote.id,
              } as INote);
            }
          });
        })
      );
      setBookingNotes(sortNotes(retrievedBookingNotes));
    }
    setIsLoading(false);
  };

  const handleNewNote = async (
    content: string,
    contentHTML: string,
    internal: boolean
  ) => {
    // get sourceType and sourceId from notesRef
    const refPathArr = notesRef.path.split("/");
    const sourceId = refPathArr[refPathArr.length - 2];
    const sourceType = refPathArr[refPathArr.length - 3].slice(0, -1);
    // set values into obj
    const newNoteObj = {
      sourceType,
      sourceId,
      content,
      contentHTML,
      internal,
      deleted: false,
      clubId: selectedClubId,
      locationId: selectedLocationId,
      createdBy: userId,
      createdAt: new Date(),
    };
    // send to firebase
    await addData(notesRef, newNoteObj);
    getNotes();
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    event.stopPropagation();
    setSelectedTab(newValue);
  };

  useEffect(() => {
    getNotes();
  }, [selectedUserId]);

  return (
    <Accordion
      defaultExpanded
      elevation={0}
      sx={{ border: 1, borderColor: light.main }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          background: "#E9ecef",
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <SoftTypography variant="h6" fontWeight="medium">
            Notes
          </SoftTypography>
          {selectedUserId && (
            <Tabs
              orientation="horizontal"
              value={selectedTab}
              onChange={handleTabChange}
              sx={tabStyles}
              onClick={(e) => e.stopPropagation()}
            >
              <Tab label="User" />
              <Tab label="Bookings" />
            </Tabs>
          )}
        </SoftBox>
      </AccordionSummary>
      <AccordionDetails>
        <SoftBox borderRadius="md">
          <NoteInput
            onNewNote={handleNewNote}
            defaultInternal={defaultInternal}
          />
          {isLoading ? (
            <SoftBox display="flex" justifyContent="center">
              <CircularProgress />
            </SoftBox>
          ) : selectedUserId ? (
            <NotesList
              userNotes={userNotes}
              bookingNotes={bookingNotes}
              selectedTab={selectedTab}
              refreshNotes={getNotes}
            />
          ) : (
            notes.map((note) => <SingleNote note={note} key={note.id} />)
          )}
        </SoftBox>
      </AccordionDetails>
    </Accordion>
  );
}
