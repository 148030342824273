import { useEffect, useState } from "react";
import ModalContainer from "src/components/ModalContainer";
import Modal from "@mui/material/Modal";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Divider from "@mui/material/Divider";
import SoftButton from "src/components/SoftButton";
import PropTypes from "prop-types";
import { useClubs } from "src/features/club/ClubProvider";

import { calculateFees } from "src/features/utils";
import { getFunctions, httpsCallable } from "firebase/functions";
import { query, where, getDocs, getDoc, doc } from "firebase/firestore";
import {
  getClubBookingsCollection,
  getClubInstructorPreferencesCollection,
  getClubCustomRatesCollection,
} from "src/features/club/collections";
import {
  getAircraftCollection,
  getSimulatorsCollection,
} from "src/features/aircraft/collections";

import { isBefore } from "date-fns";

import ReservationDate from "src/components/ReservationDate";
import AircraftDataForm from "./components/AircraftDataForm";
import InstructorDataForm from "./components/InstructorDataForm";
import FuelReimbursementForm from "./components/FuelReimbursementForm";
import PreHeatReimbursementForm from "./components/PreHeatReimbursementForm";
import SquawksList from "src/components/SquawksList";
import SelectPaymentMethod from "./components/SelectPaymentMethod";
import { ThreeDots } from "react-loader-spinner";
import { BookingSelector } from "src/components/BookingSelector";
import { ArrowBack } from "@mui/icons-material";
import { AwaitCardTerminalPayment } from "src/modals/AwaitCardTerminalPayment";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import { useLms } from "src/features/lms/LmsProvider";
import { v4 as uuidv4 } from "uuid";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import PassFailForm from "./components/PassFailForm";
import * as toastr from "toastr";
import { useUser } from "src/features/user/UserProvider";
import { getUsersCollection } from "src/features/user/collections";
import useRealtimeDocumentData from "src/features/firebase/firestore/useRealtimeDocumentData";

export function CheckInModal({ handleClose, selectedBooking }) {
  const { selectedClub, selectedClubId, selectedLocationId, globalUsers } =
    useClubs();
  const { userId } = useUser();
  const { checkIsStudent } = useLms();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingAircraft, setIsLoadingAircraft] = useState(false);
  const [isLoadingInstructor, setIsLoadingInstructor] = useState(false);
  const [isLoadingMember, setIsLoadingMember] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [booking, setBooking] = useState();
  const [aircraft, setAircraft] = useState();
  const [member, setMember] = useState();
  const [member2, setMember2] = useState();
  const [instructor, setInstructor] = useState();
  const [instructorPrefs, setInstructorPrefs] = useState();
  const [totalHobbs, setTotalHobbs] = useState();
  const [totalTach, setTotalTach] = useState();
  const [totalAmount, setTotalAmount] = useState(0);
  const [hobbsStart, setHobbsStart] = useState();
  const [hobbsEnd, setHobbsEnd] = useState();
  const [tachStart, setTachStart] = useState();
  const [tachEnd, setTachEnd] = useState();
  const [fuelReimbursement, setFuelReimbursement] = useState();
  const [receiptError, setReceiptError] = useState(null);
  const [receipt, setReceipt] = useState(null);
  const [addPreHeatFee, setAddPreHeatFee] = useState(false);
  const [preHeatCost, setPreHeatCost] = useState(false);
  const [totalFuelCost, setTotalFuelCost] = useState(0);
  const [instructorGroundHours, setInstructorGroundHours] = useState(0);
  const [totalInstructorHours, setTotalInstructorHours] = useState(0);
  const [recalculatingTotal, setRecalculatingTotal] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const [bookingsToSelectFrom, setBookingsToSelectFrom] = useState();
  const [openAwaitingPaymentModal, setOpenAwaitingPaymentModal] =
    useState(false);
  const [transaction, setTransaction] = useState();
  const [selectedRate, setSelectedRate] = useState();
  const [hideRateSelection, setHideRateSelection] = useState();
  const [passCheckride, setPassCheckride] = useState();
  const handleOpenAwaitingPaymentModal = () => {
    setOpenAwaitingPaymentModal(true);
  };
  const handleCloseAwaitingPaymentModal = () => {
    setOpenAwaitingPaymentModal(false);
    handleClose();
  };

  const { data: clubPrefs } = useRealtimeDocumentData(
    doc(
      getUsersCollection(),
      member?.uid || "none",
      "clubPrefs",
      selectedClubId
    )
  );

  const { isDataLoaded: isRateDataLoaded, data: clubRates } =
    useRealtimeCollectionData(
      getClubCustomRatesCollection(selectedClubId),
      false
    );

  useEffect(() => {
    if (selectedBooking) {
      setBooking(selectedBooking);
    }
  }, []);

  const onAddReceipt = (file) => {
    setReceiptError(null);
    setReceipt(file);
  };

  const setDefaultSelectedRate = async () => {
    if (isRateDataLoaded && clubRates.length > 0) {
      if (
        selectedClubId === "WIiWPjziBp5WbB27y0Y2" ||
        selectedClubId === "v1Xj2DtMWGnCJkp"
      ) {
        if (await checkIsStudent(booking?.extendedProps?.pilot?.value)) {
          const rate = clubRates.find((r) => r.label === "Student Rate");
          if (rate) {
            setHideRateSelection(true);
            setSelectedRate({ label: rate.label, value: rate.id });
            return;
          }
        }
      }
      const rate = clubRates.find((r) => r.id === "standard");
      if (rate) {
        setSelectedRate({ label: rate.label, value: rate.id });
      } else {
        setSelectedRate({ label: clubRates[0].label, value: clubRates[0].id });
      }
    }
  };

  useEffect(() => {
    setDefaultSelectedRate();
  }, [isRateDataLoaded, clubRates]);

  const calculateTotalHobbs = (start, end) => {
    if (!start || !end) {
      setTotalHobbs(null);
      return;
    }

    const previousHobbs = Number.parseFloat(start);
    const newHobbs = Number.parseFloat(end);

    setHobbsStart(previousHobbs);
    setHobbsEnd(newHobbs);
    setTotalHobbs(Math.round((newHobbs - previousHobbs) * 10) / 10);
  };

  const calculateTotalTach = (start, end) => {
    if (!start || !end) {
      setTotalTach(null);
      return;
    }

    const previousTach = Number.parseFloat(start);
    const newTach = Number.parseFloat(end);

    setTachStart(previousTach);
    setTachEnd(newTach);
    setTotalTach(Math.round((newTach - previousTach) * 100) / 100);
  };

  const calculateTotalInstructor = (data) => {
    if (!data) {
      setInstructorGroundHours(0);
      return;
    }

    const newGround = Number.parseFloat(data);

    setInstructorGroundHours(newGround);
  };

  const onFuelAmountChanged = (data) => {
    if (!data) {
      setFuelReimbursement(null);
      setTotalFuelCost(0);
      return;
    }

    if (data.type === "dollar") {
      const fuelAmount = Number.parseFloat(data.amount);
      setFuelReimbursement(data);
      setTotalFuelCost(Math.round(fuelAmount));
    } else {
      const fuelAmount = Number.parseFloat(data.quantity);

      setFuelReimbursement(data);
      setTotalFuelCost(
        Math.round(
          fuelAmount *
            (selectedClub.preferences?.fuelReimbursement?.rate ?? 0) *
            100
        ) / 100
      );
    }
  };

  const onAddPreHeatChanged = (data) => {
    if (!data) {
      setAddPreHeatFee(false);
      setPreHeatCost(0);
      return;
    }
    const addPreHeat = !addPreHeatFee;

    setAddPreHeatFee(addPreHeat);
    if (addPreHeat)
      setPreHeatCost(selectedClub.preferences?.preHeatFee?.rate ?? 0);
    else setPreHeatCost(0);
  };

  useEffect(() => {
    if (
      totalHobbs &&
      totalHobbs > 0 &&
      booking?.extendedProps?.instructor?.value
    ) {
      setTotalInstructorHours(
        Math.round((instructorGroundHours + totalHobbs) * 10) / 10
      );
    } else {
      setTotalInstructorHours(instructorGroundHours);
    }
  }, [totalHobbs, instructorGroundHours]);

  const fetchBooking = async () => {
    const q = query(
      getClubBookingsCollection(selectedClubId, selectedLocationId),
      where("dispatched", "==", true),
      where("completed", "==", false),
      where("cancelled", "==", false)
    );
    const docs = await getDocs(q);

    const bookingsList = [];
    if (docs.docs.length > 0) {
      docs.docs.map((document) => {
        const bookingData = document.data();
        if (
          bookingData?.extendedProps?.pilot?.value === userId ||
          bookingData?.extendedProps?.pilot2?.value === userId ||
          bookingData?.extendedProps?.instructor?.value === userId
        ) {
          bookingsList.push({
            id: document.id,
            ...bookingData,
            snapshot: document,
          });
        }
      });

      const sortedBookings = bookingsList.sort((a, b) =>
        isBefore(a.start.toDate(), b.start.toDate()) ? -1 : 1
      );
      if (bookingsList.length > 1) {
        setBookingsToSelectFrom(sortedBookings);
      } else if (bookingsList.length === 1) {
        setIsLoadingAircraft(true);
        setIsLoadingInstructor(true);
        setIsLoadingMember(true);
        setBooking(sortedBookings[0]);
      }
    }
    setIsLoading(false);
  };

  const fetchAircraft = async () => {
    let document = await getDoc(
      doc(
        getAircraftCollection(booking?.extendedProps?.clubId),
        booking?.extendedProps?.aircraft?.value
      )
    );

    if (!document.data()) {
      document = await getDoc(
        doc(
          getSimulatorsCollection(booking?.extendedProps?.clubId),
          booking?.extendedProps?.aircraft?.value
        )
      );
    }

    setAircraft(document);
  };

  const fetchMember = async () => {
    if (!booking?.extendedProps?.pilot?.value) return;
    const document = globalUsers.get(booking.extendedProps.pilot.value);
    setMember(document);

    if (!booking?.extendedProps?.pilot2?.value) return;
    const document2 = globalUsers.get(booking?.extendedProps?.pilot2?.value);
    setMember2(document2);
  };

  const fetchInstructor = async () => {
    if (!booking?.extendedProps?.instructor?.value) return;
    const document = globalUsers.get(booking?.extendedProps?.instructor?.value);
    setInstructor(document);

    const documentPrefs = await getDoc(
      doc(
        getClubInstructorPreferencesCollection(
          selectedClubId,
          selectedLocationId
        ),
        booking?.extendedProps?.instructor?.value
      )
    );
    setInstructorPrefs(documentPrefs);
  };

  useEffect(() => {
    if (!selectedBooking) fetchBooking();
    else setIsLoading(false);
  }, []);

  useEffect(() => {
    if (!booking || booking?.extendedProps?.aircraft?.value === "") {
      setIsLoadingAircraft(false);
      return;
    }

    fetchAircraft().then(() => {
      setIsLoadingAircraft(false);
    });
  }, [booking]);

  useEffect(() => {
    if (!booking || booking?.extendedProps?.instructor?.value === "") {
      setIsLoadingInstructor(false);
      return;
    }

    fetchInstructor().then(() => {
      setIsLoadingInstructor(false);
    });
  }, [booking]);

  useEffect(() => {
    if (!booking || booking?.extendedProps?.pilot?.value === "") {
      setIsLoadingMember(false);
      return;
    }
    fetchMember().then(() => {
      setIsLoadingMember(false);
    });
  }, [booking]);

  useEffect(() => {
    if (!aircraft || !totalHobbs || totalHobbs < 0) return;
    if (aircraft?.data()?.tachFollowsHobbs) {
      const originalTach = aircraft?.data()?.tach1;
      calculateTotalTach(originalTach + totalHobbs);
    }
  }, [aircraft, totalHobbs]);

  useEffect(() => {
    const billingHours =
      aircraft?.data()?.billFrom === "tach" ? totalTach : totalHobbs;

    if (
      (billingHours <= 0 &&
        totalInstructorHours <= 0 &&
        totalFuelCost <= 0 &&
        preHeatCost <= 0) ||
      selectedPaymentMethod === "nonRev"
    ) {
      setTotalAmount(0);
      setRecalculatingTotal(false);
      return;
    }
    let totalFlightCost = 0;

    const flightDiscount = clubPrefs?.flightDiscount
      ? clubPrefs?.flightDiscount / 100
      : 0;
    const instructionDiscount = clubPrefs?.instructionDiscount
      ? clubPrefs?.instructionDiscount / 100
      : 0;

    let tax = 0;
    let taxRate = (selectedClub.preferences?.taxRate || 0) / 100;
    let flightCost = 0;

    if (
      !selectedBooking?.extendedProps?.type?.enabledOptions?.aircraft?.nonRev &&
      billingHours &&
      billingHours > 0 &&
      selectedRate &&
      aircraft?.data().rates?.[`${selectedRate?.value}`]
    ) {
      flightCost +=
        billingHours *
        (aircraft?.data().rates?.[`${selectedRate?.value}`] || 0);
    } else if (
      !selectedBooking?.extendedProps?.type?.enabledOptions?.aircraft?.nonRev &&
      billingHours &&
      billingHours > 0 &&
      aircraft?.data().rates?.[`${selectedPaymentMethod}`]
    ) {
      flightCost +=
        billingHours *
        (aircraft?.data().rates?.[`${selectedPaymentMethod}`] || 0);
    }

    const discountAmountFlight =
      Math.round(flightCost * flightDiscount * 100) / 100;

    if (
      selectedBooking?.extendedProps?.type?.enabledOptions?.aircraft?.taxable
    ) {
      tax += (flightCost - discountAmountFlight) * taxRate;
    }

    let instructionCost = 0;
    let instructionCostGround = 0;
    if (
      !selectedBooking?.extendedProps?.type?.enabledOptions?.instructor
        ?.nonRev &&
      totalInstructorHours &&
      totalInstructorHours > 0 &&
      instructorPrefs &&
      instructorPrefs?.data()?.hourlyRate
    ) {
      instructionCost +=
        (totalInstructorHours - (instructorGroundHours || 0)) *
        (instructorPrefs.data().hourlyRate || 0);
    }

    const discountAmountInstruction =
      Math.round(instructionCost * instructionDiscount * 100) / 100;

    if (
      selectedBooking?.extendedProps?.type?.enabledOptions?.instructor?.taxable
    ) {
      tax += (instructionCost - discountAmountInstruction) * taxRate;
    }

    if (
      !selectedBooking?.extendedProps?.type?.enabledOptions?.instructor
        ?.nonRev &&
      instructorGroundHours &&
      instructorGroundHours > 0 &&
      instructorPrefs &&
      instructorPrefs?.data()?.hourlyRateGround
    ) {
      instructionCostGround +=
        instructorGroundHours * (instructorPrefs.data().hourlyRateGround || 0);
    }

    const discountAmountInstructionGround =
      Math.round(instructionCostGround * instructionDiscount * 100) / 100;

    if (
      selectedBooking?.extendedProps?.type?.enabledOptions?.instructor?.taxable
    ) {
      tax +=
        (instructionCostGround - discountAmountInstructionGround) * taxRate;
    }

    totalFlightCost =
      flightCost -
      discountAmountFlight +
      (instructionCost - discountAmountInstruction) +
      (instructionCostGround - discountAmountInstructionGround) +
      tax;
    // if (selectedClub.preferences?.clubPaysFees) {
    //   setTotalAmount(totalFlightCost - totalFuelCost);
    //   return;
    // }

    const calculatedFees = calculateFees(
      totalFlightCost,
      totalFuelCost,
      preHeatCost,
      !selectedPaymentMethod || selectedPaymentMethod === ""
        ? selectedClub.preferences?.clubPaysFees ||
            selectedClub.preferences?.clubPaysFeesOnAccount
        : selectedClub.preferences?.clubPaysFees,
      false,
      selectedPaymentMethod
    );
    setTotalAmount(calculatedFees.chargeToPilot);
    setRecalculatingTotal(false);
  }, [
    totalHobbs,
    totalTach,
    preHeatCost,
    totalInstructorHours,
    totalFuelCost,
    selectedPaymentMethod,
    selectedRate,
  ]);

  const handleCheckIn = async (complete) => {
    setIsSubmitting(true);

    if (
      aircraft?.data() &&
      !aircraft?.data()?.simulator &&
      !aircraft?.data()?.tachFollowsHobbs &&
      (!tachEnd || tachEnd < tachStart || !hobbsEnd || hobbsEnd < hobbsStart)
    ) {
      setIsSubmitting(false);
      toastr.error("Please enter a valid hobbs and tach value");
      return;
    }

    if (
      (aircraft?.data() &&
        (totalHobbs < 0 ||
          (selectedClub.preferences?.limitMaxHobbsTach &&
            totalHobbs > selectedClub.preferences?.maxHobbsTach) ||
          (!aircraft?.data()?.simulator &&
            !aircraft?.data()?.tachFollowsHobbs &&
            (totalTach < 0 ||
              (selectedClub.preferences?.limitMaxHobbsTach &&
                totalTach > selectedClub.preferences?.maxHobbsTach))))) ||
      (booking?.extendedProps?.instructor?.value && !totalInstructorHours)
    ) {
      setIsSubmitting(false);
      return;
    }

    if (
      selectedClub.preferences?.fuelReimbursement?.forceReceipt &&
      (fuelReimbursement?.amount > 0 || fuelReimbursement?.quantity > 0) &&
      !receipt
    ) {
      setReceiptError("Please add a receipt");
      setIsSubmitting(false);
      return;
    }

    let receiptData;
    if (receipt) {
      const storage = getStorage();
      const fileName = uuidv4() + "_" + receipt.name;
      const storageRef = ref(
        storage,
        `clubs/${selectedClubId}/receipts/${booking.id}/${fileName}`
      );
      const fi = await uploadBytes(storageRef, receipt);
      const url = await getDownloadURL(ref(storage, fi.ref.fullPath));
      receiptData = {
        createdAt: new Date(),
        documentType: receipt.fileType,
        url,
        storagePath: fi.ref.fullPath,
        fileType: receipt.type.split("/")[1],
        fileName: fi.ref.name,
      };
    }

    const functions = getFunctions();
    const completeBooking = httpsCallable(functions, "completeBooking");
    completeBooking({
      bookingId: booking.id,
      paymentMethod: selectedPaymentMethod,
      clubId: booking?.extendedProps?.clubId,
      locationId: booking?.extendedProps?.locationId,
      fuelReimbursement: fuelReimbursement,
      addPreHeatFee,
      hobbsStart,
      hobbsEnd,
      tach1Start: tachStart,
      endBooking: complete,
      tach1End: tachEnd,
      instructionHours: totalInstructorHours,
      selectedRateId: selectedRate?.value,
      receipt: receiptData,
      checkrideOutcome: passCheckride?.value,
    })
      .then((checkInResult) => {
        setIsSubmitting(false);
        if (
          checkInResult.data?.payments?.[0]?.status ===
            "requires_payment_method" &&
          checkInResult.data?.payments?.[0]?.last_payment_error
        ) {
          setTransaction(checkInResult.data);
          handleOpenAwaitingPaymentModal();
          return;
        }
        if (selectedPaymentMethod?.includes("tmr_")) {
          setTransaction(checkInResult.data?.transaction);
          handleOpenAwaitingPaymentModal();
        } else {
          toastr.success("Booking Completed!");
          handleClose();
        }
      })
      .catch((error) => {
        console.error("error", error);
        toastr.error("Error checking in. Please try again.");
        setIsSubmitting(false);
      });
  };

  const handleOnPaymentMethodSelected = (paymentMethod) => {
    setRecalculatingTotal(true);
    setSelectedPaymentMethod(paymentMethod);
  };

  useEffect(() => {
    if (
      selectedClub.preferences?.disableCheckoutPayments &&
      booking?.extendedProps?.type?.value === "checkout"
    ) {
      setSelectedPaymentMethod("nonRev");
    }
  }, [booking, selectedClub.preferences]);

  const renderBackButton = () => {
    if (bookingsToSelectFrom) {
      return (
        <SoftButton
          onClick={() => setBooking(undefined)}
          sx={{ left: 0, top: 1, position: "absolute" }}
          color="dark"
          variant="text"
        >
          <ArrowBack fontSize="medium" lineHeight={2} marginRight={2} />

          <SoftTypography variant="h6" fontWeight="light">
            &nbsp;&nbsp;&nbsp;Back to list
          </SoftTypography>
        </SoftButton>
      );
    }

    return null;
  };

  if (
    isLoading ||
    isLoadingAircraft ||
    isLoadingInstructor ||
    isLoadingMember
  ) {
    return (
      <ModalContainer handleClose={handleClose}>
        <SoftBox
          p={3}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <div style={{ transform: "scale(0.75)" }}>
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#329baa"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
          <SoftTypography variant="h6" fontWeight="bold">
            Loading...
          </SoftTypography>
        </SoftBox>
      </ModalContainer>
    );
  }

  if (!booking) {
    if (bookingsToSelectFrom) {
      return (
        <ModalContainer handleClose={handleClose}>
          <SoftBox
            p={2}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <BookingSelector
              bookings={bookingsToSelectFrom}
              onSelectBooking={(bk) => setBooking(bk)}
            />
          </SoftBox>
        </ModalContainer>
      );
    }

    return (
      <SoftBox>
        <ModalContainer handleClose={handleClose}>
          <SoftBox p={2}>
            <SoftTypography variant="h4" fontWeight="bold">
              You don&rsquo;t have any dispatched bookings...
            </SoftTypography>
          </SoftBox>
        </ModalContainer>
      </SoftBox>
    );
  }

  return (
    <SoftBox>
      <ModalContainer handleClose={handleClose}>
        <SoftBox p={2}>
          <SoftBox>
            <SoftBox
              display="flex"
              justifyContent="center"
              position="relative"
              alignItems="center"
            >
              {renderBackButton()}
              <SoftTypography variant="h4" fontWeight="bold" lineHeight={2}>
                Check-In
              </SoftTypography>
            </SoftBox>
            <Divider />
            <ReservationDate
              start={booking.start.toDate()}
              end={booking.end.toDate()}
            />
            <Divider />
            {aircraft && (
              <>
                <AircraftDataForm
                  aircraft={aircraft.data()}
                  onHobbsChanged={calculateTotalHobbs}
                  totalHobbs={totalHobbs}
                  onTachChanged={calculateTotalTach}
                  totalTach={totalTach}
                />
                <Divider />
              </>
            )}
            {instructor && (
              <>
                <InstructorDataForm
                  instructor={instructor}
                  instructorGroundHours={instructorGroundHours}
                  onInstructorGroundTimeChanged={calculateTotalInstructor}
                />
                <Divider />
                {booking?.extendedProps?.type?.value === "checkride" ||
                  (booking?.extendedProps?.type?.enabledOptions?.checkRide
                    ?.enabled && (
                    <>
                      <PassFailForm
                        value={passCheckride}
                        onChange={setPassCheckride}
                      />
                      <Divider />
                    </>
                  ))}
              </>
            )}
            {selectedClub?.preferences?.fuelReimbursement?.enabled &&
              aircraft &&
              !aircraft?.data()?.simulator &&
              booking?.extendedProps?.type?.enabledOptions?.aircraft
                ?.fuelReimbursement && (
                <>
                  <FuelReimbursementForm
                    totalFuelCost={totalFuelCost}
                    onFuelAmountChanged={onFuelAmountChanged}
                    receipt={receipt}
                    onAddReceipt={onAddReceipt}
                    receiptError={receiptError}
                  />
                  <Divider />
                </>
              )}
            {selectedClub?.preferences?.preHeatFee?.enabled &&
              aircraft &&
              !aircraft?.data()?.simulator &&
              booking?.extendedProps?.type?.enabledOptions?.preHeat
                ?.enabled && (
                <>
                  <PreHeatReimbursementForm
                    addPreHeatFee={addPreHeatFee}
                    onAddPreHeatChanged={onAddPreHeatChanged}
                  />
                  <Divider />
                </>
              )}
            {aircraft && (
              <SquawksList
                clubId={booking?.extendedProps?.clubId}
                aircraft={{ ...aircraft.data(), id: aircraft.id }}
              />
            )}
            {member &&
              ((selectedClub.preferences?.disableCheckoutPayments &&
                booking?.extendedProps?.type?.value !== "checkout" &&
                booking?.extendedProps?.type?.value !== "introFlight") ||
                (!selectedClub.preferences?.disableCheckoutPayments &&
                  booking?.extendedProps?.type?.value !== "introFlight")) &&
              (totalAmount !== 0 ||
                recalculatingTotal ||
                selectedPaymentMethod === "nonRev") && (
                <SelectPaymentMethod
                  user={member}
                  user2={member2}
                  onPaymentMethodSelected={handleOnPaymentMethodSelected}
                  totalAmount={totalAmount}
                  aircraft={aircraft?.data()}
                  selectedRate={selectedRate}
                  onRateSelected={setSelectedRate}
                  clubRates={hideRateSelection ? [] : clubRates}
                />
              )}
            <SoftBox
              mt={4}
              width="100%"
              display="flex"
              justifyContent="space-between"
            >
              <SoftButton variant="outlined" color="dark" onClick={handleClose}>
                Close
              </SoftButton>
              <SoftBox>
                <SoftButton
                  disabled={
                    isSubmitting ||
                    (aircraft?.data() &&
                      ((!totalHobbs && !totalHobbs === 0) ||
                        totalHobbs < 0 ||
                        !hobbsEnd)) ||
                    (aircraft?.data() &&
                      !aircraft?.data().simulator &&
                      ((!totalTach && !totalTach === 0) ||
                        totalTach < 0 ||
                        !hobbsEnd)) ||
                    (booking?.extendedProps?.instructor?.value &&
                      !totalInstructorHours)
                  }
                  type="submit"
                  onClick={() => handleCheckIn(false)}
                  variant={
                    booking.start.toDate() < new Date() &&
                    booking.end.toDate() > new Date()
                      ? "outlined"
                      : "gradient"
                  }
                  color="error"
                >
                  check in
                </SoftButton>
                {booking.start.toDate() < new Date() &&
                  booking.end.toDate() > new Date() && (
                    <SoftButton
                      disabled={
                        isSubmitting ||
                        (aircraft?.data() &&
                          ((!totalHobbs && !totalHobbs === 0) ||
                            totalHobbs < 0 ||
                            !hobbsEnd)) ||
                        (aircraft?.data() &&
                          !aircraft?.data().simulator &&
                          ((!totalTach && !totalTach === 0) ||
                            totalTach < 0 ||
                            !hobbsEnd)) ||
                        (booking?.extendedProps?.instructor?.value &&
                          !totalInstructorHours)
                      }
                      style={{ marginLeft: "15px" }}
                      type="submit"
                      onClick={() => handleCheckIn(true)}
                      variant="gradient"
                      color="error"
                    >
                      Check In & Complete
                    </SoftButton>
                  )}
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </ModalContainer>
      <Modal
        hideBackdrop
        open={openAwaitingPaymentModal}
        onClose={handleCloseAwaitingPaymentModal}
        disableEscapeKeyDown
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <AwaitCardTerminalPayment
          handleClose={handleCloseAwaitingPaymentModal}
          transaction={transaction}
        />
      </Modal>
    </SoftBox>
  );
}

CheckInModal.propTypes = {
  handleClose: PropTypes.func,
  selectedBooking: PropTypes.object,
};
