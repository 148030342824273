import { useMemo, useState } from "react";
import { Card } from "@mui/material";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import SoftEditor from "src/components/SoftEditor";
import SoftInput from "src/components/SoftInput";
import SoftButton from "src/components/SoftButton";
import PropTypes from "prop-types";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useClubs } from "src/features/club/ClubProvider";
import SoftCurrencyInput from "src/components/SoftCurrencyInput";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { getCourseLessonsCollection } from "src/features/lms/collections";
import SoftSelect from "src/components/SoftSelect";
import { gradingOptions, formDefaultGrading } from "src/pages/lms/utils";

const schema = yup
  .object({
    title: yup.string().required("A lesson title is required."),
    flightTime: yup.number().required("Flight time is required."),
    groundTime: yup.number().required("Ground time is required."),
    simTime: yup.number().required("Sim time is required."),
    grading: yup.object({
      label: yup.string(),
      value: yup.string().required("A selection is required."),
    }),
    approvedInstructors: yup.array(),
  })
  .required();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "15px",
  overflow: "scroll",
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
};

export function AddLessonModal({
  handleClose,
  courseId,
  stageId,
  lessonCount,
  lessonData,
}) {
  const { selectedClubId, instructors } = useClubs();
  const { addData, updateData } = entityCrudUtils();

  const instructorOptions = useMemo(
    () =>
      instructors?.map((instructor) => ({
        label: instructor.displayName,
        value: instructor.id,
      })),
    [instructors]
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setError,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      title: lessonData?.title,
      flightTime: lessonData?.flightTime || 0.0,
      groundTime: lessonData?.groundTime || 0.0,
      simTime: lessonData?.simTime || 0.0,
      approvedInstructors: [],
      ...(lessonData?.gradingPermissions && {
        grading: formDefaultGrading(lessonData),
      }),
    },
    resolver: yupResolver(schema),
  });

  const watchGrading = watch("grading");

  const [objectiveValue, setObjectiveValue] = useState(
    lessonData?.objective || ""
  );
  const [objectiveTextValue, setObjectiveTextValue] = useState(
    lessonData?.objectiveText || ""
  );
  const [completionStandardsValue, setCompletionStandardsValue] = useState(
    lessonData?.completionStandards || ""
  );
  const [completionStandardsTextValue, setCompletionStandardsTextValue] =
    useState(lessonData?.completionStandardsText || "");

  const [additionalStudyValue, setAdditionalStudyValue] = useState(
    lessonData?.additionalStudy || ""
  );
  const [additionalStudyTextValue, setAdditionalStudyTextValue] = useState(
    lessonData?.additionalStudyText || ""
  );
  const [lectureNotesValue, setLectureNotesValue] = useState(
    lessonData?.lectureNotes || ""
  );
  const [lectureNotesTextValue, setLectureNotesTextValue] = useState(
    lessonData?.lectureNotesText || ""
  );
  const [approvedInstructorsValue, setApprovedInstructorsValue] = useState(
    lessonData?.gradingPermissions?.approvedInstructors || []
  );

  const showInstructorsDropdown = useMemo(
    () => watchGrading?.value === "custom",
    [watchGrading]
  );

  const onSubmit = async (data) => {
    const { grading, approvedInstructors, ...remainingData } = data;
    if (grading.value === "custom" && !approvedInstructors.length) {
      setError("approvedInstructors", {
        type: "required",
        message: "You must select at least one instructor.",
      });
      return;
    }
    if (!grading.value !== "custom") {
      setApprovedInstructorsValue([]);
    }

    const gradingPermissions = {
      allowAssignedInstructor:
        grading.value === "all" || grading.value === "assigned",
      allowUnassignedInstructors:
        grading.value === "all" || grading.value === "unassigned",
      approvedInstructors: approvedInstructorsValue,
    };

    if (lessonData?.id) {
      await updateData(
        {
          entity: getCourseLessonsCollection(selectedClubId, courseId, stageId),
          pathSegmentsArr: [lessonData.id],
        },
        {
          ...lessonData,
          ...remainingData,
          completionStandards: completionStandardsValue,
          completionStandardsText: completionStandardsTextValue,
          objective: objectiveValue,
          objectiveText: objectiveTextValue,
          additionalStudy: additionalStudyValue,
          additionalStudyText: additionalStudyTextValue,
          lectureNotes: lectureNotesValue,
          letureNotesText: lectureNotesTextValue,
          gradingPermissions,
        },
        lessonData.id
      );
      handleClose();
    } else {
      const lessonToAdd = {
        ...remainingData,
        completionStandards: completionStandardsValue,
        completionStandardsText: completionStandardsTextValue,
        objective: objectiveValue,
        objectiveText: objectiveTextValue,
        additionalStudy: additionalStudyValue,
        additionalStudyText: additionalStudyTextValue,
        lectureNotes: lectureNotesValue,
        letureNotesText: lectureNotesTextValue,
        gradingPermissions,
        courseId,
        stageId,
        index: lessonCount,
      };
      const addedLesson = await addData(
        getCourseLessonsCollection(selectedClubId, courseId, stageId),
        lessonToAdd
      );
      handleClose({ ...lessonToAdd, id: addedLesson.id });
    }
  };

  const onObjectiveChange = (content, delta, source, editor) => {
    setObjectiveValue(content);
    setObjectiveTextValue(editor.getText());
  };

  const onCompletionStandardsChange = (content, delta, source, editor) => {
    setCompletionStandardsValue(content);
    setCompletionStandardsTextValue(editor.getText());
  };

  const onAdditionalStudyChange = (content, delta, source, editor) => {
    setAdditionalStudyValue(content);
    setAdditionalStudyTextValue(editor.getText());
  };

  const onLectureNotesChange = (content, delta, source, editor) => {
    setLectureNotesValue(content);
    setLectureNotesTextValue(editor.getText());
  };

  const onApprovedInstructorsChange = (newValue) => {
    setApprovedInstructorsValue(newValue);
    setValue("approvedInstructors", newValue);
  };

  return (
    <Card sx={style}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="bold">
            {lessonData?.id ? "Edit" : "Add New"} Lesson
          </SoftTypography>
          <SoftBox mt={1.625}>
            <Grid container spacing={3}>
              <Grid item xs={12} mt={2}>
                <SoftBox ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Title
                  </SoftTypography>
                </SoftBox>
                <SoftBox px={2} pt={2}>
                  <SoftInput
                    {...register("title", {
                      required: true,
                    })}
                    placeholder="Eg. Lesson 1 - Ground Training"
                  />
                  {errors?.title?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.title.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={4}>
                <SoftBox ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Flight Time (h)
                  </SoftTypography>
                </SoftBox>
                <SoftBox px={2} pt={2}>
                  <Controller
                    control={control}
                    name="flightTime"
                    render={({ field: { onChange, value, ref: inputRef } }) => (
                      <SoftCurrencyInput
                        currencySymbol=""
                        placeholder="0.0"
                        decimalPlaces={1}
                        outputFormat="number"
                        onBlur={onChange}
                        inputRef={inputRef}
                        value={value}
                      />
                    )}
                  />
                  {errors?.flightTime?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.flightTime.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={4}>
                <SoftBox ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Ground Time (h)
                  </SoftTypography>
                </SoftBox>
                <SoftBox px={2} pt={2}>
                  <Controller
                    control={control}
                    name="groundTime"
                    render={({ field: { onChange, value, ref: inputRef } }) => (
                      <SoftCurrencyInput
                        currencySymbol=""
                        placeholder="0.0"
                        decimalPlaces={1}
                        outputFormat="number"
                        onBlur={onChange}
                        inputRef={inputRef}
                        value={value}
                      />
                    )}
                  />
                  {errors?.groundTime?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.groundTime.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={4}>
                <SoftBox ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Sim Time (h)
                  </SoftTypography>
                </SoftBox>
                <SoftBox px={2} pt={2}>
                  <Controller
                    control={control}
                    name="simTime"
                    render={({ field: { onChange, value, ref: inputRef } }) => (
                      <SoftCurrencyInput
                        currencySymbol=""
                        placeholder="0.0"
                        decimalPlaces={1}
                        outputFormat="number"
                        onBlur={onChange}
                        inputRef={inputRef}
                        value={value}
                      />
                    )}
                  />
                  {errors?.simTime?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.simTime.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={12}>
                <SoftBox ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Objective
                  </SoftTypography>
                </SoftBox>
                <SoftBox p={2} sx={{ height: "150px" }}>
                  <SoftEditor
                    defaultValue={objectiveValue}
                    onChange={onObjectiveChange}
                  />
                </SoftBox>
              </Grid>
              <Grid item xs={12} mt={2}>
                <SoftBox ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Completion Standards
                  </SoftTypography>
                </SoftBox>
                <SoftBox p={2} sx={{ height: "150px" }}>
                  <SoftEditor
                    defaultValue={completionStandardsValue}
                    onChange={onCompletionStandardsChange}
                  />
                </SoftBox>
              </Grid>
              <Grid item xs={12} mt={2}>
                <SoftBox ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Additional Study
                  </SoftTypography>
                </SoftBox>
                <SoftBox p={2} sx={{ height: "150px" }}>
                  <SoftEditor
                    defaultValue={additionalStudyValue}
                    onChange={onAdditionalStudyChange}
                  />
                </SoftBox>
              </Grid>
              <Grid item xs={12} mt={2}>
                <SoftBox ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Lecture Notes
                  </SoftTypography>
                </SoftBox>
                <SoftBox p={2} sx={{ height: "150px" }}>
                  <SoftEditor
                    defaultValue={lectureNotesValue}
                    onChange={onLectureNotesChange}
                  />
                </SoftBox>
              </Grid>
              <Grid item xs={12} mt={2}>
                <SoftBox ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Grading Permissions
                  </SoftTypography>
                </SoftBox>
                <SoftBox px={2} pt={2}>
                  <Controller
                    control={control}
                    name="grading"
                    render={({ field: { onChange, value, ref: inputRef } }) => (
                      <SoftSelect
                        onChange={onChange}
                        value={value || formDefaultGrading(lessonData)}
                        ref={inputRef}
                        placeholder="Select which instructors are allowed to grade this lesson"
                        defaultValue={formDefaultGrading(lessonData)}
                        options={gradingOptions}
                      />
                    )}
                  />
                  {errors?.grading?.value?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.grading?.value?.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              {showInstructorsDropdown && (
                <Grid item xs={12} mt={2}>
                  <SoftBox ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Approved Instructors
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox px={2} pt={2}>
                    <Controller
                      control={control}
                      name="approvedInstructors"
                      render={({ field: { ref: inputRef } }) => (
                        <SoftSelect
                          isMulti
                          multiple
                          onChange={onApprovedInstructorsChange}
                          value={approvedInstructorsValue}
                          ref={inputRef}
                          placeholder="Manually select approved instructors"
                          options={instructorOptions}
                        />
                      )}
                    />
                    {errors?.approvedInstructors?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {errors.approvedInstructors?.message}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </Grid>
              )}
            </Grid>
            <Grid container spacing={1} mt={3}>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={handleClose}
                  >
                    Cancel
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    type="submit"
                  >
                    {lessonData?.id ? "Update Lesson" : "Add Lesson"}
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

AddLessonModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  courseId: PropTypes.string.isRequired,
  stageId: PropTypes.string.isRequired,
  lessonCount: PropTypes.number.isRequired,
  lessonData: PropTypes.object,
};
