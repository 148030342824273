import { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import { IconButton, Icon } from "@mui/material";
import Modal from "@mui/material/Modal";

import { EditMembershipModal } from "src/modals/EditMembershipModal";

// Soft UI Dashboard PRO React components
import SoftBadge from "src/components/SoftBadge";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import CurrencyFormat from "react-currency-format";
import { format } from "date-fns";

const iconButtonStyles = ({ typography: { size }, breakpoints }) => ({
  px: 0.9,

  "& .material-icons, .material-icons-round": {
    fontSize: `${size.md} !important`,
  },

  "& .MuiTypography-root": {
    display: "none",

    [breakpoints.up("sm")]: {
      display: "inline-block",
      lineHeight: 1.2,
      ml: 0.5,
    },
  },
});

function MembershipItem({ membership, noGutter = false }) {
  const [openEditMembership, setOpenEditMembership] = useState(false);
  const handleCloseEditMembership = () => {
    setOpenEditMembership(false);
  };

  return (
    <SoftBox
      component="li"
      key={membership.clubId}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={1}
      pr={1}
      mb={noGutter ? 0 : 1}
    >
      <SoftBox lineHeight={1.125}>
        <SoftTypography display="block" variant="button" fontWeight="medium">
          {membership.membershipPlan.label}{" "}
          {membership.nextMembershipPlan &&
            `-> ${membership.nextMembershipPlan.label}`}
        </SoftTypography>
        <SoftTypography variant="caption" fontWeight="regular" color="text">
          {membership?.club?.name}
        </SoftTypography>
      </SoftBox>
      <SoftBox display="flex" alignItems="center">
        <SoftBadge
          variant="contained"
          color={membership.active ? "success" : "error"}
          size="xs"
          badgeContent={membership.active ? "Active" : "Inactive"}
          container
          sx={{
            marginRight: 1,
          }}
        />
        <SoftBox display="flex" alignItems="center" lineHeight={1} ml={3}>
          <SoftTypography
            variant="button"
            fontWeight="regular"
            color="text"
            textTransform="capitalize"
          >
            <CurrencyFormat
              value={
                membership.nextMembershipPlan
                  ? membership.nextMembershipPlan.cost?.amount || 0
                  : membership.membershipPlan.cost?.amount || 0
              }
              displayType="text"
              thousandSeparator
              prefix="$"
            />
            {membership.nextMembershipPlan
              ? membership.nextMembershipPlan.cost &&
                ` (${membership.nextMembershipPlan.cost.billed})`
              : membership.membershipPlan.cost &&
                ` (${membership.membershipPlan.cost.billed})`}
          </SoftTypography>
        </SoftBox>
        {membership.membershipPlan.cost && (
          <SoftBox lineHeight={0.5} ml={4} mr={1}>
            <SoftTypography variant="caption" fontWeight="regular" color="text">
              Next Due
            </SoftTypography>
            <SoftTypography
              display="block"
              variant="button"
              fontWeight="medium"
            >
              {format(membership.nextBillDate.toDate(), "MMM dd yyyy")}
            </SoftTypography>
          </SoftBox>
        )}
        <SoftBox lineHeight={0.5} ml={1} mr={1}>
          <IconButton
            aria-label="delete"
            sx={iconButtonStyles}
            onClick={() => setOpenEditMembership(true)}
          >
            <Icon>edit</Icon>
          </IconButton>
        </SoftBox>
      </SoftBox>
      <Modal
        open={openEditMembership}
        onClose={handleCloseEditMembership}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <EditMembershipModal
            handleClose={handleCloseEditMembership}
            selectedMembership={membership}
          />
        </SoftBox>
      </Modal>
    </SoftBox>
  );
}

// Typechecking props for the Invoice
MembershipItem.propTypes = {
  membership: PropTypes.object.isRequired,
  noGutter: PropTypes.bool,
};

export default MembershipItem;
