import { useState } from "react";
import PropTypes from "prop-types";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import { AircraftLookup } from "src/pages/resources/aircrafts/AircraftLookup";
import AircraftDetailsForm from "src/pages/resources/aircrafts/AircraftDetailsForm";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  maxWidth: "100vw",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "15px",
  overflow: "scroll",
  boxShadow: 24,
  maxHeight: "90vh",
  p: 4,
};

function NewAircraftDetails({ onCompleted, onClose, aircrafts }) {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedAircraft, setSelectedAircraft] = useState(null);
  const steps = ["Aircraft Lookup", "Add Details"];
  const handleOnNext = (aircraft) => {
    setSelectedAircraft(aircraft);
    setActiveStep(1);
  };

  const showStep = (ind) => {
    switch (ind) {
      case 0:
        return <AircraftLookup onNext={handleOnNext} onClose={onClose} aircrafts={aircrafts} />;
      case 1:
        return (
          <AircraftDetailsForm
            aircraft={selectedAircraft}
            onCompleted={() => onCompleted()}
            onClose={onClose}
          />
        );
      default:
        return <AircraftLookup onNext={handleOnNext} onClose={onClose} aircrafts={aircrafts} />;
    }
  };

  return (
    <SoftBox sx={style}>
      <SoftTypography textAlign="center" textTransform="capitalize">
        Add new aircraft
      </SoftTypography>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {showStep(activeStep)}
    </SoftBox>
  );
}

NewAircraftDetails.propTypes = {
  onCompleted: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NewAircraftDetails;
