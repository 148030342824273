import * as yup from "yup";
import { useEffect, useMemo, useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import PropTypes from "prop-types";
import { useClubs } from "src/features/club/ClubProvider";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { getAircraftCollection } from "src/features/aircraft/collections";
import { getClubCustomRatesCollection } from "src/features/club/collections";
import * as toastr from "toastr";
import { CircularProgress, IconButton, Grid } from "@mui/material";
import SoftTypography from "src/components/SoftTypography";
import SoftBox from "src/components/SoftBox";
import SoftInput from "src/components/SoftInput";
import SoftSelect from "src/components/SoftSelect";
import AircraftCard from "src/pages/resources/aircrafts/AircraftCard";
import Checkbox from "@mui/material/Checkbox";
import SoftButton from "src/components/SoftButton";
import SoftCurrencyInput from "src/components/SoftCurrencyInput";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { getClubDocumentTypesCollection } from "src/features/club/collections";
import { query, where } from "firebase/firestore";
import { standardDocumentTypes } from "src/config/localDocuments";
import { Select, Option } from "src/components/SoftMultiSelect";
import SoftLoader from "src/components/SoftLoader/SoftLoader";

const schema = yup
  .object({
    displayName: yup.string().required("A display name is required."),
    engineCount: yup.object().required("Number of engines is required."),
    billFrom: yup.object().required("Billing from is required."),
    quickbooksItem: yup.object().optional(),
    instructorRate: yup.object().optional(),
    rates: yup.lazy((obj) => {
      const shape = {};
      Object.keys(obj).forEach((key) => {
        if (key === "standard") {
          shape[key] = yup
            .number()
            .transform((value) => (Number.isNaN(value) ? undefined : value))
            .required("Hourly rate is required.");
        } else {
          shape[key] = yup
            .number()
            .transform((value) => (Number.isNaN(value) ? undefined : value));
        }
      });
      return yup.object().shape(shape);
    }),
    hobbs: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .required("Starting hobbs is required."),
    tt: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .required("Airframe Total Time is required."),
    tach1: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .when("engineCount", {
        is: (engineCount) => engineCount.value >= 1,
        then: (elementSchema) => elementSchema.required("Tach 1 is required."),
        otherwise: (elementSchema) => elementSchema.notRequired(),
      }),
    tach2: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .when("engineCount", {
        is: (engineCount) => engineCount.value >= 2,
        then: (elementSchema) => elementSchema.required("Tach 2 is required."),
        otherwise: (elementSchema) => elementSchema.notRequired(),
      }),
    tach3: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .when("engineCount", {
        is: (engineCount) => engineCount.value >= 3,
        then: (elementSchema) => elementSchema.required("Tach 3 is required."),
        otherwise: (elementSchema) => elementSchema.notRequired(),
      }),
    tach4: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .when("engineCount", {
        is: (engineCount) => engineCount.value >= 4,
        then: (elementSchema) => elementSchema.required("Tach 4 is required."),
        otherwise: (elementSchema) => elementSchema.notRequired(),
      }),
  })
  .required();

export default function AircraftDetailsForm({ aircraft, onCompleted }) {
  const functions = getFunctions();
  const [quickbooksItems, setQuickbooksItems] = useState([]);
  // const [quickbooksAccounts, setQuickbooksAccounts] = useState([]);
  // const [quickbooksPrefs, setQuickbooksPrefs] = useState({});
  const [firebaseInProgress, setFirebaseInProgress] = useState(false);
  const [aircraftRates, setAircraftRates] = useState([]);
  const {
    selectedClubId,
    selectedClub,
    clubLocations,
    clubMemberships,
    clubAccounts,
  } = useClubs();
  const { addData, updateData } = entityCrudUtils();

  const [aircraftDocs, setAircraftDocs] = useState(
    (aircraft?.requiredDocs ?? []).map((d) => d.id)
  );

  const documentTypesRef = getClubDocumentTypesCollection(selectedClubId);
  const { data: documentTypes, isDataLoaded: documentTypesLoaded } =
    useRealtimeCollectionData(
      query(documentTypesRef, where("deleted", "==", false))
    );

  const docTypes = useMemo(
    () => standardDocumentTypes.concat(documentTypes ?? []),
    [documentTypes]
  );

  useEffect(() => {
    const getItems = httpsCallable(functions, "getQuickbooksItems-default");
    getItems({ clubId: selectedClubId })
      .then((result) => {
        if (typeof result.data !== "string") {
          setQuickbooksItems(result.data);
        }
      })
      .catch((error) => {
        console.error("error", error);
      });
    // const getAccounts = httpsCallable(
    //   functions,
    //   "getQuickbooksAccounts-default"
    // );
    // getAccounts({ clubId: selectedClubId })
    //   .then((result) => {
    //     if (typeof result.data !== "string") {
    //       setQuickbooksAccounts(result.data);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log("error", error);
    //   });

    // const getQuickbooksPreferences = httpsCallable(
    //   functions,
    //   "getQuickbooksPreferences-default"
    // );
    // getQuickbooksPreferences({ clubId: selectedClubId })
    //   .then((result) => {
    //     if (typeof result.data !== "string") {
    //       setQuickbooksPrefs(result.data);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log("error", error);
    //   });
  }, [selectedClubId]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...aircraft,
      billFrom: {
        label: aircraft.billFrom
          ? aircraft.billFrom.charAt(0).toUpperCase() +
            aircraft.billFrom.slice(1)
          : "Hobbs",
        value: aircraft.billFrom || "hobbs",
      },
      instructorRate: aircraft.instructorRate,
      enabledRates: aircraft.enabledRates || [],
      tachFollowsHobbs: aircraft.tachFollowsHobbs || false,
      rates: aircraft.rates || {},
      engineCount: {
        value: aircraft.engineCount || 1,
        label: `${aircraft.engineCount || 1}`,
      },
    },
  });

  useEffect(() => {
    console.debug("Yup Errors", errors);
  }, [errors]);

  const engineCount = watch("engineCount");
  const enabledRates = watch("enabledRates");
  const instructorRate = watch("instructorRate");
  const locations = watch("locations");
  const selectedMemberships = watch("restrictions.memberships");

  const { data: clubRates } = useRealtimeCollectionData(
    getClubCustomRatesCollection(selectedClubId),
    false
  );

  useEffect(() => {
    const rates = [];
    clubAccounts.forEach((account) => {
      if (account.hasFixedRate) {
        rates.push({
          id: account.id,
          label: `${account.name}`,
        });
      }
    });

    clubRates.forEach((rate) => {
      rates.push({
        id: rate.id,
        label: rate.label,
      });
    });

    setAircraftRates(rates);
  }, [clubAccounts, clubRates]);

  const [picture, setPicture] = useState(null);
  const [imgData, setImgData] = useState(null);
  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      setPicture(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onSubmit = async (data) => {
    try {
      setFirebaseInProgress(true);
      const storage = getStorage();

      if (data.engineCount) {
        data.engineCount = data.engineCount.value;
      }
      if (data.billFrom) {
        data.billFrom = data.billFrom.value;
      }

      const docToUpload = {
        ...aircraft,
        ...data,
        requiredDocs: docTypes.filter((t) => aircraftDocs.includes(t.id)),
        index: aircraft?.index || 0,
        deleted: false,
        disabled: false,
      };
      if (data.locations && typeof data.locations === "string") {
        docToUpload.locations = [data.locations];
      }

      if (data.memberships && typeof data.memberships === "string") {
        docToUpload.memberships = [data.memberships];
      }

      if (picture) {
        const fileName = uuidv4() + picture.name;
        const storageRef = ref(storage, fileName);

        const fi = await uploadBytes(storageRef, picture);
        docToUpload.photo = await getDownloadURL(ref(storage, fi.ref.name));
      }

      if (!aircraft.id) {
        await addData(getAircraftCollection(selectedClubId), docToUpload);
      } else {
        await updateData(
          {
            entity: getAircraftCollection(selectedClubId),
            pathSegmentsArr: [aircraft.id],
          },
          docToUpload
        );
      }

      toastr.success("Aircraft added!", "Success");

      onCompleted(true);
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      setFirebaseInProgress(false);
    }
  };

  const isLocationSelected = (location) => {
    if (typeof locations === "string") {
      return location === locations;
    }
    if (typeof locations === "object") {
      return (
        typeof locations?.find((element) => element === location) === "string"
      );
    }

    return false;
  };

  const isMembershipSelected = (membership) => {
    if (typeof selectedMemberships === "string") {
      return membership === selectedMemberships;
    }
    if (typeof selectedMemberships === "object") {
      return (
        typeof selectedMemberships?.find(
          (element) => element === membership
        ) === "string"
      );
    }

    return false;
  };

  const renderTachInputs = () => {
    const tachInputs = [];
    tachInputs.push(
      <Grid item xs={6} key="tachFollowsHobbs">
        <SoftBox mb={2} lineHeight={1.25}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Tach follows Hobbs?
            </SoftTypography>
          </SoftBox>
          <Controller
            control={control}
            name="tachFollowsHobbs"
            render={({ field: { onChange, value, ref: inputRef } }) => (
              <Checkbox
                inputRef={inputRef}
                value={value}
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
              />
            )}
          />
        </SoftBox>
      </Grid>
    );
    for (let i = 1; i <= engineCount?.value; i += 1) {
      tachInputs.push(
        <Grid item xs={6} key={i}>
          <SoftBox mb={2} lineHeight={1.25}>
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Tach
                {engineCount?.value > 1 && ` ${i}`} *
              </SoftTypography>
            </SoftBox>
            <SoftInput
              placeholder="0"
              {...register(`tach${i}`, {
                required: true,
              })}
            />
            {errors[`tach${i}`]?.message && (
              <SoftTypography marginTop={1} fontSize={12} color="error">
                {errors[`tach${i}`].message}
              </SoftTypography>
            )}
          </SoftBox>
        </Grid>
      );
    }
    return tachInputs;
  };

  if (firebaseInProgress) {
    return (
      <Grid
        container
        columns={12}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>
          <CircularProgress />
          <SoftTypography mt={2}>Saving</SoftTypography>
        </Grid>
      </Grid>
    );
  }

  return (
    <SoftBox>
      <SoftBox component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <AircraftCard aircraft={aircraft} image={imgData}>
              <SoftBox mb={2} lineHeight={1.25}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Upload photo
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  height="1.6rem"
                  size="large"
                  placeholder="0"
                  type="file"
                  onChange={onChangePicture}
                />
              </SoftBox>
            </AircraftCard>
          </Grid>

          <Grid item xs={12} sm={6}>
            <SoftBox mb={2} lineHeight={1.25}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Display Name *
                </SoftTypography>
              </SoftBox>
              <SoftInput
                placeholder="Plane Name"
                {...register("displayName", {
                  required: true,
                })}
              />
              {errors?.displayName?.message && (
                <SoftTypography marginTop={1} fontSize={12} color="error">
                  {errors.displayName.message}
                </SoftTypography>
              )}
            </SoftBox>
            <Grid container spacing={{ xs: 2 }}>
              <Grid item xs={6}>
                <SoftBox lineHeight={1.25}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Bill From *
                    </SoftTypography>
                  </SoftBox>
                  <Controller
                    control={control}
                    name="billFrom"
                    render={({ field: { onChange, value, ref: inputRef } }) => (
                      <SoftSelect
                        options={[
                          { label: "Tach", value: "tach" },
                          { label: "Hobbs", value: "hobbs" },
                        ]}
                        onChange={onChange}
                        inputRef={inputRef}
                        value={value}
                      />
                    )}
                  />
                  {errors?.billFrom?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.billFrom.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox lineHeight={1.25}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Airframe Total *
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    placeholder="0"
                    {...register("tt", {
                      required: true,
                    })}
                  />
                  {errors?.tt?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.tt.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox lineHeight={1.25}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Hobbs *
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    placeholder="0"
                    {...register("hobbs", {
                      required: true,
                    })}
                  />
                  {errors?.hobbs?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.hobbs.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox mb={2} lineHeight={1.25}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      # of Engines *
                    </SoftTypography>
                  </SoftBox>
                  <Controller
                    control={control}
                    name="engineCount"
                    render={({ field: { onChange, value, ref: inputRef } }) => (
                      <SoftSelect
                        isDisabled={
                          aircraft.aircraft_description?.type ===
                            "Fixed Wing Single-Engine" ||
                          aircraft.details?.typeAcft ===
                            "Fixed Wing Single-Engine"
                        }
                        options={[
                          { label: "None", value: 0 },
                          { label: "1", value: 1 },
                          { label: "2", value: 2 },
                          { label: "3", value: 3 },
                          { label: "4", value: 4 },
                        ]}
                        onChange={onChange}
                        inputRef={inputRef}
                        value={value}
                      />
                    )}
                  />
                  {errors?.engineCount?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.engineCount.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
            </Grid>
            <Grid container spacing={{ xs: 2 }}>
              {renderTachInputs()}
            </Grid>
            <Grid container spacing={{ xs: 2 }}>
              <Grid item xs={6}>
                <SoftBox mb={2} lineHeight={1.25}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Required User Documents
                    </SoftTypography>
                  </SoftBox>
                  <SoftLoader isLoading={!documentTypesLoaded}>
                    <Select
                      id="docSelect"
                      multiple
                      value={aircraftDocs}
                      onChange={(event, newValue) => {
                        if (event === null) return;
                        setAircraftDocs(newValue);
                      }}
                      renderValue={(selected) => {
                        if (selected.length > 0) {
                          return selected.length === docTypes.length
                            ? "All"
                            : `${selected.length} selected`;
                        } else {
                          return "None Selected";
                        }
                      }}
                    >
                      {docTypes.map((option) => (
                        <Option key={option.id} value={option.id}>
                          <SoftBox sx={{ display: "flex" }}>
                            <Checkbox
                              checked={aircraftDocs?.some(
                                (selected) => selected === option?.id
                              )}
                            />
                            <SoftTypography variant="body2">
                              {option.name}
                            </SoftTypography>
                          </SoftBox>
                        </Option>
                      ))}
                    </Select>
                  </SoftLoader>
                </SoftBox>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <SoftBox mb={2} lineHeight={1.25}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Aircraft Rates
                </SoftTypography>
                <Grid container spacing={{ xs: 2 }}>
                  {aircraftRates
                    .sort((a, b) =>
                      a.id === "standard" ? -1 : b.id === "standard" ? 1 : 0
                    )
                    .map((rate) => (
                      <Grid key={rate.id} item xs={12} sm={4}>
                        <SoftBox mb={2} lineHeight={1.25}>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              {rate.label}
                            </SoftTypography>
                          </SoftBox>
                          <SoftBox display="flex" alignItems="center">
                            {rate.id !== "standard" && (
                              <Checkbox
                                value={rate.id}
                                checked={enabledRates?.includes(rate.id)}
                                {...register("enabledRates", {})}
                              />
                            )}

                            <SoftBox sx={{ width: "150px" }}>
                              <Controller
                                control={control}
                                name={`rates[${rate.id}]`}
                                render={({ field: { onChange, value } }) => (
                                  <SoftCurrencyInput
                                    currencySymbol="$"
                                    placeholder="0.00"
                                    outputFormat="number"
                                    onBlur={onChange}
                                    value={value}
                                  />
                                )}
                              />
                            </SoftBox>

                            {rate.id !== "standard" && (
                              <SoftBox>
                                <IconButton
                                  aria-label="delete"
                                  // onClick={}
                                >
                                  <PersonAddIcon />
                                </IconButton>
                              </SoftBox>
                            )}
                          </SoftBox>
                          {errors?.rates?.[rate.id]?.message && (
                            <SoftTypography
                              marginTop={1}
                              fontSize={12}
                              color="error"
                            >
                              {errors.rates[rate.id].message}
                            </SoftTypography>
                          )}
                        </SoftBox>
                      </Grid>
                    ))}
                </Grid>
              </SoftBox>
            </SoftBox>
          </Grid>

          <Grid item xs={12}>
            <SoftBox mb={2} lineHeight={1.25}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Override Instructor Rates
                </SoftTypography>
                <Grid container spacing={{ xs: 2 }}>
                  <Grid item xs={12} sm={4}>
                    <SoftBox mb={2} lineHeight={1.25}>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Hourly Rate
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox display="flex" alignItems="center">
                        <Checkbox
                          checked={instructorRate?.enabled}
                          {...register("instructorRate.enabled", {})}
                        />

                        <SoftBox sx={{ width: "150px" }}>
                          <Controller
                            control={control}
                            name={`instructorRate.hourlyRate`}
                            render={({ field: { onChange, value } }) => (
                              <SoftCurrencyInput
                                currencySymbol="$"
                                placeholder="0.00"
                                outputFormat="number"
                                onBlur={onChange}
                                value={value}
                              />
                            )}
                          />
                        </SoftBox>
                      </SoftBox>
                      {errors?.instructorRate?.hourlyRate?.message && (
                        <SoftTypography
                          marginTop={1}
                          fontSize={12}
                          color="error"
                        >
                          {errors.instructorRate.hourlyRate.message}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  </Grid>
                </Grid>
              </SoftBox>
            </SoftBox>
          </Grid>

          {selectedClub?.quickbooks?.enabled && quickbooksItems.length > 0 && (
            <Grid item xs={12}>
              <SoftBox mb={2} lineHeight={1.25}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Quickbooks Override
                  </SoftTypography>
                  <Grid pt={2} container spacing={{ xs: 2 }}>
                    <Grid item xs={12}>
                      <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={2}
                        lineHeight={1}
                      >
                        <SoftTypography
                          variant="button"
                          fontWeight="regular"
                          color="text"
                        >
                          QuickBooks Item for Aircraft Rental
                        </SoftTypography>
                        <SoftBox ml={2} mr={1} width={300}>
                          <Controller
                            control={control}
                            name="quickbooksItem"
                            render={({
                              field: { onChange, value, ref: inputRef },
                            }) => (
                              <SoftSelect
                                ref={inputRef}
                                options={quickbooksItems?.map((item) => ({
                                  label: item.Name,
                                  value: item.Id,
                                }))}
                                value={value}
                                onChange={onChange}
                                label="Aircraft Rental"
                              />
                            )}
                          />
                        </SoftBox>
                      </SoftBox>
                    </Grid>
                  </Grid>
                </SoftBox>
              </SoftBox>
            </Grid>
          )}

          <Grid item xs={12}>
            <SoftBox mb={2} lineHeight={1.25}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Description
                </SoftTypography>
              </SoftBox>
              <SoftInput
                multiline
                rows={5}
                placeholder="optional description"
                {...register("description", {})}
              />
              {errors?.description?.message && (
                <SoftTypography marginTop={1} fontSize={12} color="error">
                  {errors.description.message}
                </SoftTypography>
              )}
            </SoftBox>
          </Grid>

          <Grid item xs={6}>
            <SoftBox mb={2} lineHeight={1.25}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Select Locations
                </SoftTypography>
              </SoftBox>
              {clubLocations.map((loc) => (
                <SoftBox display="flex" alignItems="center" key={loc.id}>
                  <Checkbox
                    value={loc.id}
                    checked={isLocationSelected(loc.id)}
                    {...register("locations", {})}
                  />

                  <SoftTypography component="a" href="#" variant="button">
                    {loc.name}
                  </SoftTypography>
                </SoftBox>
              ))}
              {errors?.locations?.message && (
                <SoftTypography marginTop={1} fontSize={12} color="error">
                  {errors.locations.message}
                </SoftTypography>
              )}
            </SoftBox>
          </Grid>
          {clubMemberships.length > 0 && (
            <Grid item xs={6}>
              <SoftBox mb={2} lineHeight={1.25}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Required Membership Levels
                  </SoftTypography>
                </SoftBox>
                {clubMemberships.map((membership) => (
                  <SoftBox
                    display="flex"
                    alignItems="center"
                    key={membership.id}
                  >
                    <Checkbox
                      value={membership.id}
                      checked={isMembershipSelected(membership.id)}
                      {...register("restrictions.memberships", {})}
                    />

                    <SoftTypography component="a" href="#" variant="button">
                      {membership.data().label}
                    </SoftTypography>
                  </SoftBox>
                ))}
                {errors?.memberships?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.memberships.message}
                  </SoftTypography>
                )}
              </SoftBox>
            </Grid>
          )}
        </Grid>
        <SoftBox mt={4} mb={1}>
          <SoftButton variant="gradient" color="info" fullWidth type="submit">
            Save
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}

AircraftDetailsForm.propTypes = {
  aircraft: PropTypes.object,
  onCompleted: PropTypes.func,
};
