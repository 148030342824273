import { useEffect, useState } from "react";
import { useStripe, Elements } from "@stripe/react-stripe-js";
import { getFunctions, httpsCallable } from "firebase/functions";
import InvoicePaymentDetails from "./InvoicePaymentDetails";
import { useLocation } from "react-router-dom";
import SoftTypography from "src/components/SoftTypography";
import { ThreeDots } from "react-loader-spinner";
import SoftBox from "src/components/SoftBox";
import { Card } from "@mui/material";

const loadingStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "15px",
  overflow: "scroll",
  boxShadow: 24,
  maxHeight: "90vh",
  p: 4,
};

export default function InvoicePaymentDetailsWrapper({ ...rest }) {
  const stripe = useStripe();
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [paymentIntentSecret, setPaymentIntentSecret] = useState(null);
  const location = useLocation();
  const { clubTransaction, fees, paymentMethod } = location.state;

  useEffect(() => {
    if (clubTransaction?.totalCost) {
      const functions = getFunctions();

      const createPaymentIntent = httpsCallable(
        functions,
        "createPaymentIntent"
      );
      createPaymentIntent({
        clubId: clubTransaction.club.id,
        locationId: clubTransaction.location.id,
        transactionId: clubTransaction.id,
        description: `Payment Request - ${clubTransaction.club.name}`,
        userId: clubTransaction?.pilot?.uid || clubTransaction?.member?.uid,
        amount: clubTransaction.totalCost,
        email: clubTransaction?.pilot?.email || clubTransaction?.member?.email,
        paymentType: paymentMethod === "ach" ? "us_bank_account" : "card",
      }).then((result) => {
        if (result?.data) {
          setPaymentIntentSecret(result.data.secret);
          setPaymentIntent(result.data);
        } else {
          console.error("Unable to create Stripe payment intent", result);
        }
      });
    }
  }, [clubTransaction, fees]);

  const loader = "auto";

  return paymentIntent && paymentIntentSecret && clubTransaction && stripe ? (
    <Elements
      stripe={stripe}
      options={{ clientSecret: paymentIntentSecret, loader }}
    >
      <InvoicePaymentDetails
        clubTransaction={clubTransaction}
        paymentIntent={paymentIntent}
        paymentMethod={paymentMethod}
        {...rest}
      />
    </Elements>
  ) : (
    <Card sx={loadingStyle}>
      <SoftBox
        p={3}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <div style={{ transform: "scale(0.75)" }}>
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#329baa"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
        <SoftTypography variant="h6" fontWeight="bold">
          Loading...
        </SoftTypography>
      </SoftBox>
    </Card>
  );
}
