import { useState } from "react";
import { Card } from "@mui/material";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import SoftEditor from "src/components/SoftEditor";
import SoftButton from "src/components/SoftButton";
import PropTypes from "prop-types";
import { useClubs } from "src/features/club/ClubProvider";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { getCourseStagesCollection } from "src/features/lms/collections";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "15px",
  overflow: "scroll",
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
};

export function AddStageModal({
  handleClose,
  courseId,
  stageCount,
  stageData,
}) {
  const { selectedClubId } = useClubs();
  const { addData, updateData } = entityCrudUtils();

  const [objectiveValue, setObjectiveValue] = useState(
    stageData?.objective || ""
  );
  const [objectiveTextValue, setObjectiveTextValue] = useState(
    stageData?.objectiveText || ""
  );

  const [completionStandardsValue, setCompletionStandardsValue] = useState(
    stageData?.completionStandards || ""
  );
  const [completionStandardsTextValue, setCompletionStandardsTextValue] =
    useState(stageData?.completionStandardsText || "");

  const onSubmit = async () => {
    if (stageData?.id) {
      await updateData(
        {
          entity: getCourseStagesCollection(selectedClubId, courseId),
          pathSegmentsArr: [stageData.id],
        },
        {
          ...stageData,
          completionStandards: completionStandardsValue,
          completionStandardsText: completionStandardsTextValue,
          objective: objectiveValue,
          objectiveText: objectiveTextValue,
        },
        stageData.id
      );
      handleClose();
    } else {
      await addData(getCourseStagesCollection(selectedClubId, courseId), {
        completionStandards: completionStandardsValue,
        completionStandardsText: completionStandardsTextValue,
        objective: objectiveValue,
        objectiveText: objectiveTextValue,
        courseId,
        index: stageCount,
      });
      handleClose(stageCount);
    }
  };

  const onObjectiveChange = (content, delta, source, editor) => {
    setObjectiveValue(content);
    setObjectiveTextValue(editor.getText());
  };

  const onCompletionStandardsChange = (content, delta, source, editor) => {
    setCompletionStandardsValue(content);
    setCompletionStandardsTextValue(editor.getText());
  };

  return (
    <Card sx={style}>
      <SoftBox p={2}>
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="bold">
            {stageData?.id ? "Edit" : "Add New"} Stage{" "}
            {stageData && stageData.index + 1}
          </SoftTypography>
          <SoftBox>
            <Grid container spacing={3}>
              <Grid item xs={12} mt={2}>
                <SoftBox ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Stage Objective
                  </SoftTypography>
                </SoftBox>
                <SoftBox p={2} sx={{ height: "150px" }}>
                  <SoftEditor
                    defaultValue={objectiveValue}
                    onChange={onObjectiveChange}
                  />
                </SoftBox>
              </Grid>
              <Grid item xs={12} mt={2}>
                <SoftBox ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Stage Completion Standards
                  </SoftTypography>
                </SoftBox>
                <SoftBox p={2} sx={{ height: "150px" }}>
                  <SoftEditor
                    defaultValue={completionStandardsValue}
                    onChange={onCompletionStandardsChange}
                  />
                </SoftBox>
              </Grid>
            </Grid>
            <Grid container spacing={1} mt={3}>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={() => handleClose()}
                  >
                    Cancel
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    onClick={onSubmit}
                  >
                    {stageData?.id ? "Update Stage" : "Add Stage"}
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

AddStageModal.propTypes = {
  handleClose: PropTypes.func,
  courseId: PropTypes.string,
  stageCount: PropTypes.number,
  stageData: PropTypes.object,
};
