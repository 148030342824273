import { useEffect, useState } from "react";
import ModalContainer from "src/components/ModalContainer";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import { Select, Option } from "src/components/SoftMultiSelect";
import SoftSelect from "src/components/SoftSelect";
import SoftInput from "src/components/SoftInput";
import SoftPhoneNumberInput from "src/components/SoftPhoneNumberInput";
import SoftButton from "src/components/SoftButton";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import { useClubs } from "src/features/club/ClubProvider";
import { useCollection } from "react-firebase-hooks/firestore";
import { getClubMembershipsCollection } from "src/features/club/collections";
import { query, where } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Stack } from "@mui/material";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";

const schema = yup
  .object({
    email: yup
      .string()
      .email("Please enter a valid email.")
      .required("An email is required."),
    firstName: yup.string().required("A first name is required."),
    lastName: yup.string().required("A last name is required."),
    phoneNumber: yup.string(),
    sendInvite: yup.boolean(),
  })
  .required();

export function AddNewUserModal({ handleClose }) {
  const {
    selectedClubId,
    selectedClub,
    selectedLocationId,
    clubLocations,
    clubUserRoles,
    globalUsersByEmail,
  } = useClubs();
  const [membershipOptions, setMembershipOptions] = useState([]);
  const [existingMember, setExistingMember] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    defaultValues: {
      locations: [selectedLocationId],
      sendInvite: true,
      userRoles: [],
    },
    resolver: yupResolver(schema),
  });

  const sendInvite = watch("sendInvite");
  const email = watch("email");
  const selectedRoles = watch("userRoles");
  const selectedLocations = watch("locations");

  useEffect(() => {
    console.log("Selected Roles:", selectedRoles);
  }, [selectedRoles]);

  useEffect(() => {
    if (email && globalUsersByEmail.has(email)) {
      const user = globalUsersByEmail.get(email);
      setExistingMember(user);
      //setValue('userRoles', user.userRoles)
      setValue("firstName", user.firstName);
      setValue("lastName", user.lastName);
      user?.phoneNumber && setValue("phoneNumber", user.phoneNumber);
    } else {
      setExistingMember(undefined);
    }
  }, [email, globalUsersByEmail]);

  const [membershipsListSnapshot, loadingMembershipsList] = useCollection(
    query(
      getClubMembershipsCollection(selectedClubId),
      where("deleted", "==", false),
      where("active", "==", true)
    ),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    }
  );

  useEffect(() => {
    if (loadingMembershipsList) return;
    setMembershipOptions(
      membershipsListSnapshot.docs.map((membershipSnap) => ({
        label: membershipSnap.data().label,
        value: membershipSnap.id,
        membershipData: membershipSnap.data(),
      }))
    );
  }, [membershipsListSnapshot, loadingMembershipsList]);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const permissions = {};
    for (let key in data.userRoles) {
      permissions[key] = data.userRoles[key] || [];
    }

    const invite = {
      clubId: selectedClubId,
      locationId: selectedLocationId,
      clubName: selectedClub.name,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      permissions,
      sendInvite: data.sendInvite,
    };

    if (data.phoneNumber) {
      invite.phoneNumber = data.phoneNumber;
    }

    if (data.membership?.value) {
      invite.membership = data.membership?.value;
    }

    // await addData(getInvitesCollection(), invite);
    const functions = getFunctions();
    const addUser = httpsCallable(functions, "addUser");
    addUser(invite)
      .then(() => {
        handleClose();
        setIsSubmitting(false);
      })
      .catch((error) => {
        console.error("Add User Error:", error);
        setIsSubmitting(false);
      });
  };

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="bold">
            Add New User
          </SoftTypography>
          <SoftBox mt={1.625}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={8}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Email Address
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  {...register("email", {
                    required: true,
                  })}
                />
                {errors?.email?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.email.message}
                  </SoftTypography>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    User Roles
                  </SoftTypography>
                </SoftBox>
                <Controller
                  control={control}
                  name={`userRoles.${selectedLocationId}`}
                  render={({ field: { onChange, value, ref } }) => (
                    <Select
                      multiple
                      ref={ref}
                      value={value}
                      onChange={(event, newValue) => onChange(newValue)}
                      renderValue={(selected) => {
                        if (selected.length > 0) {
                          return selected.length === clubUserRoles.length
                            ? "All"
                            : selected?.map((s) => s.label).join(", ");
                        } else {
                          return "None Selected";
                        }
                      }}
                    >
                      {clubUserRoles?.map((option) => (
                        <Option key={option.id} value={option.id}>
                          <SoftBox sx={{ display: "flex" }}>
                            {selectedRoles?.[selectedLocationId]?.some(
                              (selected) => selected === option.id
                            ) ? (
                              <SoftTypography
                                variant="body2"
                                fontSize="20px"
                                color="primary"
                                mr={2}
                              >
                                <CheckBox />
                              </SoftTypography>
                            ) : (
                              <SoftTypography
                                variant="body2"
                                fontSize="20px"
                                mr={2}
                              >
                                <CheckBoxOutlineBlank />
                              </SoftTypography>
                            )}
                            <SoftTypography variant="body2">
                              {option.label}
                            </SoftTypography>
                          </SoftBox>
                        </Option>
                      ))}
                    </Select>
                  )}
                />
                {errors?.userRoles?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.userRoles.message}
                  </SoftTypography>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    First Name
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  {...register("firstName", {
                    required: true,
                  })}
                  disabled={existingMember}
                />
                {errors?.firstName?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.firstName.message}
                  </SoftTypography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Last Name
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  {...register("lastName", {
                    required: true,
                  })}
                  disabled={existingMember}
                />
                {errors?.lastName?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.lastName.message}
                  </SoftTypography>
                )}
              </Grid>
              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Phone Number
                  </SoftTypography>
                </SoftBox>
                <Controller
                  control={control}
                  name="phoneNumber"
                  render={({ field: { onChange, value, ref: inputRef } }) => (
                    <SoftPhoneNumberInput
                      value={value}
                      international
                      countryCallingCodeEditable={false}
                      defaultCountry="US"
                      onChange={onChange}
                      inputRef={inputRef}
                    />
                  )}
                />
                {errors?.phoneNumber?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.phoneNumber.message}
                  </SoftTypography>
                )}
              </Grid>
              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Membership
                  </SoftTypography>
                </SoftBox>
                <Controller
                  control={control}
                  name="membership"
                  render={({ field: { onChange, value, ref } }) => (
                    <SoftSelect
                      options={membershipOptions}
                      onChange={onChange}
                      inputRef={ref}
                      value={value}
                    />
                  )}
                />
                {errors?.membership?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.membership.message}
                  </SoftTypography>
                )}
              </Grid>
              <Grid item xs={12}>
                <SoftBox mb={2} lineHeight={1.25}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Add to other locations?
                    </SoftTypography>
                  </SoftBox>
                  {clubLocations
                    .filter((cl) => cl.id !== selectedLocationId)
                    .map((loc) => (
                      <Stack direction="row" spacing={2}>
                        <SoftBox
                          display="flex"
                          alignItems="center"
                          key={loc.id}
                        >
                          <Checkbox
                            value={loc.id}
                            {...register("locations", {})}
                          />

                          <SoftTypography
                            variant="button"
                            color={errors?.loc?.message && "error"}
                          >
                            {loc.name}
                          </SoftTypography>
                        </SoftBox>
                        {selectedLocations?.includes(loc.id) && (
                          <>
                            <SoftBox py={1}>
                              <Controller
                                control={control}
                                name={`userRoles.${loc.id}`}
                                render={({
                                  field: { onChange, value, ref },
                                }) => (
                                  <Select
                                    id={`roleSelect.${loc.id}`}
                                    multiple
                                    ref={ref}
                                    value={value}
                                    onChange={(event, newValue) =>
                                      onChange(newValue)
                                    }
                                    renderValue={(selected) => {
                                      if (selected.length > 0) {
                                        return selected.length ===
                                          clubUserRoles.length
                                          ? "All"
                                          : selected
                                              ?.map((s) => s.label)
                                              .join(", ");
                                      } else {
                                        return "None Selected";
                                      }
                                    }}
                                  >
                                    {clubUserRoles?.map((option) => (
                                      <Option key={option.id} value={option.id}>
                                        <SoftBox sx={{ display: "flex" }}>
                                          {selectedRoles?.[loc.id]?.some(
                                            (selected) => selected === option.id
                                          ) ? (
                                            <SoftTypography
                                              variant="body2"
                                              fontSize="20px"
                                              color="primary"
                                              mr={2}
                                            >
                                              <CheckBox />
                                            </SoftTypography>
                                          ) : (
                                            <SoftTypography
                                              variant="body2"
                                              fontSize="20px"
                                              mr={2}
                                            >
                                              <CheckBoxOutlineBlank />
                                            </SoftTypography>
                                          )}
                                          <SoftTypography variant="body2">
                                            {option.label}
                                          </SoftTypography>
                                        </SoftBox>
                                      </Option>
                                    ))}
                                  </Select>
                                )}
                              />
                            </SoftBox>
                          </>
                        )}
                      </Stack>
                    ))}
                  {errors?.locations?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.locations.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={12}>
                <SoftBox
                  lineHeight={1.25}
                  display="flex"
                  flexDirection="row-reverse"
                >
                  <SoftBox ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      mr={2}
                    >
                      Send Invite?
                    </SoftTypography>
                    <Checkbox {...register("sendInvite", {})} defaultChecked />
                  </SoftBox>
                </SoftBox>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={handleClose}
                  >
                    Cancel
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    disabled={isSubmitting}
                    fullWidth
                    type="submit"
                  >
                    {!sendInvite ? "Create User" : "Create & Invite"}
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

AddNewUserModal.propTypes = {
  handleClose: PropTypes.func,
};
