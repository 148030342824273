import { useEffect, useState, useMemo } from "react";
import { CSVLink } from "react-csv";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftButton from "src/components/SoftButton";
import SoftDateRangePicker from "src/components/SoftDateRangePicker";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";
import DataTable from "src/components/Tables/DataTable";

import DefaultCell from "src/pages/reports/components/DefaultTableCell";
import {
  fetchAircraftResourceData,
  fetchClubPilotsData,
  fetchInstructorResourceData,
  fetchMeetingRoomResourceData,
} from "src/pages/book-flight/utils";

import { useClubs } from "src/features/club/ClubProvider";
import {
  format,
  startOfMonth,
  endOfMonth,
  subMonths,
  isWithinInterval,
  startOfDay,
  endOfDay,
} from "date-fns";
import { query, orderBy, where, doc, getDoc } from "firebase/firestore";
import { getClubBookingsCollection } from "src/features/club/collections";
import {
  getAircraftCollection,
  getSimulatorsCollection,
} from "src/features/aircraft/collections";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import { openModal$ } from "src/modals/modalConfiguration";
import useClubMeetingRooms from "src/features/meeting-room/meetingRoomsHelper";

const columns = [
  {
    Header: "start date",
    accessor: "start",
    Cell: ({ value }) =>
      value ? <DefaultCell value={format(value?.toDate(), "PPp")} /> : null,
  },
  {
    Header: "end date",
    accessor: "end",
    Cell: ({ value }) =>
      value ? <DefaultCell value={format(value?.toDate(), "PPp")} /> : null,
  },
  {
    Header: "Pilot",
    accessor: "extendedProps.pilot.displayName",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Pilot 2",
    accessor: "extendedProps.pilot2.displayName",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Instructor",
    accessor: "extendedProps.instructor.displayName",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Aircraft",
    accessor: "extendedProps.aircraft.tailNumber",
    Cell: ({ value, row: { original } }) => {
      return (
        <DefaultCell
          value={`${value ? `N${value}` : ""} - ${
            original?.extendedProps?.aircraft?.displayName || ""
          }`}
        />
      );
    },
  },
  {
    Header: "Type",
    accessor: "extendedProps.type",
    Cell: ({ value }) => {
      return <DefaultCell value={value.label ? value.label : value.value} />;
    },
  },
  {
    Header: "Status",
    width: 75,
    accessor: "completed",
    Cell: ({ value, row: { original } }) => (
      <DefaultCell
        value={`${
          value
            ? "Completed"
            : original.cancelled
            ? "Cancelled"
            : "Not Completed"
        }`}
      />
    ),
  },
];

function ReservationsReport() {
  const {
    selectedClubId,
    selectedClub,
    selectedLocationId,
    selectedLocation,
    globalUsers,
    locationUsers,
    clubUsersPermissions,
    instructors: clubInstructors,
    instructorPreferences: clubInstructorPrefs,
  } = useClubs();
  const { meetingRooms } = useClubMeetingRooms();
  const [rows, setRows] = useState([]);
  const [resources, setResources] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [dateRange, setDateRange] = useState([
    startOfMonth(subMonths(new Date(), 1)),
    endOfMonth(subMonths(new Date(), 1)),
  ]);

  const instructors = useMemo(() => {
    return fetchInstructorResourceData(clubInstructors, clubInstructorPrefs);
  }, [clubInstructors, clubInstructorPrefs]);

  const fetchData = async () => {
    const aircrafts = await fetchAircraftResourceData(
      selectedClubId,
      selectedLocationId,
      selectedClub
    );

    const meetingRoomData = fetchMeetingRoomResourceData(meetingRooms);

    setResources([...instructors, ...aircrafts, ...meetingRoomData]);
  };

  useEffect(() => {
    fetchData();
  }, [
    selectedClubId,
    selectedLocationId,
    instructors,
    meetingRooms,
    locationUsers,
    clubUsersPermissions,
  ]);

  const pilots = fetchClubPilotsData(locationUsers, clubUsersPermissions);

  const updateDateRange = (dates) => {
    dates[0] = startOfDay(dates[0]);
    dates[1] = endOfDay(dates[1]);

    setDateRange(dates);
  };

  const locationBookingsCollectionRef = query(
    getClubBookingsCollection(selectedClubId, selectedLocationId),
    where("start", ">=", dateRange[0]),
    orderBy("start", "desc")
  );
  const { data: allBookings, isDataLoaded: bookingsLoaded } =
    useRealtimeCollectionData(locationBookingsCollectionRef, true);

  const addBookingData = async (bookings) => {
    const filteredBookings = bookings.filter((booking) => {
      return isWithinInterval(booking.start.toDate(), {
        start: dateRange[0],
        end: dateRange[1],
      });
    });
    const rowData = await Promise.all(
      filteredBookings.map(async (booking) => {
        const pilot =
          booking?.extendedProps?.pilot?.value?.length > 0
            ? globalUsers.get(booking?.extendedProps?.pilot?.value)
            : null;
        const pilot2 =
          booking?.extendedProps?.pilot2?.value?.length > 0
            ? globalUsers.get(booking?.extendedProps?.pilot2?.value)
            : null;
        const instructor =
          booking?.extendedProps?.instructor?.value?.length > 0
            ? globalUsers.get(booking?.extendedProps?.instructor?.value)
            : null;
        let aircraft =
          booking?.extendedProps?.aircraft?.value?.length > 0
            ? await getDoc(
                doc(
                  getAircraftCollection(selectedClubId),
                  booking?.extendedProps?.aircraft?.value
                )
              )
            : null;
        if (!aircraft?.data()) {
          aircraft =
            booking?.extendedProps?.aircraft?.value?.length > 0
              ? await getDoc(
                  doc(
                    getSimulatorsCollection(selectedClubId),
                    booking?.extendedProps?.aircraft?.value
                  )
                )
              : null;
        }
        const fullBookingData = {
          ...booking,
          extendedProps: {
            ...booking?.extendedProps,
            pilot,
            pilot2,
            instructor,
            aircraft: aircraft?.data(),
          },
        };
        return fullBookingData;
      })
    );

    const csvDataFromRows = rowData.map((row) => ({
      Date: format(row.start?.toDate(), "MM/dd/yyyy HH:mm"),
      Pilot: row?.extendedProps?.pilot?.displayName || "---",
      "Pilot 2": row?.extendedProps?.pilot2?.displayName || "---",
      Instructor: row?.extendedProps?.instructor?.displayName || "---",
      "Aircraft Tail":
        (row?.extendedProps?.aircraft?.aircraft_description?.tail_number ??
          row?.extendedProps?.aircraft?.tailNumber) ||
        "---",
      "Aircraft Name": row?.extendedProps?.aircraft?.displayName || "---",
      Status: `${row?.completed ? "Completed" : "Not Completed"}`,
    }));

    setCsvData(csvDataFromRows);
    setRows(rowData);
  };

  useEffect(() => {
    if (!bookingsLoaded || !allBookings) return;
    addBookingData(allBookings);
  }, [allBookings, bookingsLoaded, dateRange]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={2}
        >
          <SoftBox />
          <SoftBox display="flex">
            <SoftDateRangePicker
              value={dateRange}
              onChange={(newDates) => updateDateRange(newDates)}
            />
            <SoftBox mx={1}>
              <Divider orientation="vertical" />
            </SoftBox>
            <SoftBox>
              <CSVLink
                data={csvData}
                filename={`${selectedClub.name}-${
                  selectedLocation?.icao
                }-check_ins-${format(new Date(), "MM/dd/yyyy")}.csv`}
                target="_blank"
              >
                <SoftButton variant="outlined" color="dark">
                  <Icon>description</Icon>
                  &nbsp;export csv
                </SoftButton>
              </CSVLink>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <Card>
          <DataTable
            table={{ columns, rows }}
            entriesPerPage={{ defaultValue: 10, entries: [10, 20, 50, 100] }}
            onRowSelect={(booking) => {
              openModal$.next({
                modalName: "BOOKING_INFO_MODAL",
                modalProps: { bookingId: booking.id, pilots, resources },
              });
            }}
            canSearch
          />
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ReservationsReport;
