import { useEffect, useState } from "react";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import Calendar from "src/components/Calendar/Calendar";
import Footer from "src/components/Footer";
import DashboardNavbar from "src/containers/DashboardNavbar";
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import { useUser } from "src/features/user/UserProvider";
import { useCollection } from "react-firebase-hooks/firestore";
import { query, where } from "firebase/firestore";
import { getClubBookingsCollection } from "src/features/club/collections";
import { getClassName } from "src/pages/book-flight/Calendar/uiHelper";
import { useClubs } from "src/features/club/ClubProvider";

import { fetchClubPilotsData } from "src/pages/book-flight/utils";

import SoftSelect from "src/components/SoftSelect";
import { Modal } from "@mui/material";
import BookingInfoModal from "src/modals/BookingInfoModal";
import SoftButton from "src/components/SoftButton";
import { ManageMeetingRoomsModal } from "src/modals/ManageMeetingRoomsModal";
import useClubMeetingRooms from "src/features/meeting-room/meetingRoomsHelper";
import { BookingModal } from "src/modals/BookingModal";
import SoftSnackbar from "src/components/SoftSnackbar";
import WithPermissionWrapper from "src/components/WithPermissions/WithPermissionWrapper";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";
import { usePermissions } from "src/hooks/usePermissions";

const allSelectValue = { label: "All", value: "all" };

function MeetingRoomsSchedule() {
  const { userId } = useUser();
  const { hasAccess } = usePermissions();
  const {
    selectedClubId,
    selectedLocationId,
    locationUsers,
    clubUsersPermissions,
  } = useClubs();
  const { meetingRooms } = useClubMeetingRooms();
  const [bookingData, setBookingData] = useState([]);
  const [selectedMeetingRoom, setSelectedMeetingRoom] =
    useState(allSelectValue);
  const [selectValues, setSelectValues] = useState([allSelectValue]);
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [resourceData, setResourceData] = useState([]);
  const [pilotData, setPilotData] = useState([]);
  const [openBookingInfoModal, setOpenBookingInfoModal] = useState(false);
  const [openAddEditBookingModal, setOpenAddEditBookingModal] = useState(false);
  const [showManageMeetingRoomsModal, setShowManageMeetingRoomsModal] =
    useState(false);
  const [selectedCalendarData, setSelectedCalendarData] = useState(null);
  const [showNoMeetingRoomsSnackbar, setShowNoMeetingRoomsSnackbar] =
    useState(false);

  const closeNoMeetingRoomsSnackbar = () => {
    setShowNoMeetingRoomsSnackbar(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const pilotsData = fetchClubPilotsData(
        locationUsers,
        clubUsersPermissions
      );

      setPilotData(pilotsData);
    };

    fetchData();
  }, [selectedLocationId]);

  const [bookingsSnapshot, loadingBookings] = useCollection(
    query(
      getClubBookingsCollection(selectedClubId, selectedLocationId),
      where("cancelled", "==", false),
      where("extendedProps.type.value", "==", "meetingRoom")
    ),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    }
  );

  useEffect(() => {
    if (!meetingRooms) return;

    const meetingRoomSelectValues = [allSelectValue];
    const meetingRoomResourceData = [];

    meetingRooms.forEach((x) => {
      meetingRoomSelectValues.push({
        label: x.title,
        value: x.id,
      });
      meetingRoomResourceData.push({
        id: x.id,
        title: x.title,
        type: "MeetingRooms",
        payload: x,
      });
    });

    setSelectValues(meetingRoomSelectValues);
    setResourceData(meetingRoomResourceData);
  }, [meetingRooms]);

  useEffect(() => {
    if (loadingBookings) return;

    const processLoadedBookings = (loadedBookings) => {
      const selectedId = selectedMeetingRoom.value;
      const bookings = [];

      loadedBookings.map((document) => {
        const booking = document.data();

        if (
          booking?.extendedProps?.meetingRoom &&
          booking?.extendedProps?.meetingRoom?.value &&
          booking?.extendedProps?.meetingRoom?.label
        ) {
          if (
            selectedId !== "all" &&
            booking.extendedProps.meetingRoom.value !== selectedId
          )
            return;

          bookings.push({
            ...booking,
            title: booking?.extendedProps?.meetingRoom?.label,
            id: document.id,
            start: booking.start.toDate(),
            end: booking.end.toDate(),
            editable: false,
            className: getClassName(booking, userId),
          });
        }
      });

      return bookings;
    };

    setBookingData(processLoadedBookings(bookingsSnapshot.docs));
  }, [bookingsSnapshot, selectedMeetingRoom]);

  const handleSelectionChange = (event) => {
    setSelectedMeetingRoom(event);
  };

  const handleBookingInfoOpen = (booking) => {
    setSelectedBookingId(booking.id);
    setOpenBookingInfoModal(true);
  };

  const handleBookingInfoClose = () => {
    setOpenBookingInfoModal(false);
    setSelectedBookingId(null);
  };

  const handleMeetingRoomManagementModalClose = () => {
    setShowManageMeetingRoomsModal(false);
  };

  const handleAddEditBookingOpen = (data) => {
    if (meetingRooms?.length > 0) {
      if (
        hasAccess([
          systemPermissions.MODIFY_OTHERS_BOOKING,
          systemPermissions.MODIFY_OWN_BOOKING,
        ])
      )
        return;

      data.resource =
        selectedMeetingRoom.value === "all" ? null : selectedMeetingRoom;

      setSelectedCalendarData(data);
      setOpenAddEditBookingModal(true);
    } else {
      setShowNoMeetingRoomsSnackbar(true);
    }
  };

  const handleAddEditBookingClose = () => {
    setOpenAddEditBookingModal(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox p={3}>
        <SoftTypography variant="h5">Meeting Rooms</SoftTypography>
      </SoftBox>

      <SoftBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <SoftSelect
              options={selectValues}
              value={selectedMeetingRoom}
              onChange={handleSelectionChange}
            />
          </Grid>
          <WithPermissions permissions={systemPermissions.MANAGE_MEETING_ROOMS}>
            <Grid item xs={12} lg={3}>
              <SoftButton
                variant="outlined"
                color="info"
                size="small"
                onClick={() => setShowManageMeetingRoomsModal(true)}
              >
                manage meeting rooms
              </SoftButton>
            </Grid>
          </WithPermissions>
        </Grid>
      </SoftBox>

      <SoftBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SoftBox mt={3}>
              <Card>
                <Calendar
                  events={bookingData}
                  eventClick={(info) => handleBookingInfoOpen(info.event)}
                  select={(data) => handleAddEditBookingOpen(data)}
                  displayEventEnd
                  listView
                  initialView="timeGridWeek"
                  selectable
                  /*    events={bookingData}
                    pilots={pilotData}
                    resourceLabelContent={renderResourceContent}
                    resources={fetchResourceData}
                    resourceData={resourceData}
                    // eventDidMount={eventDidMount}
                   */
                />
              </Card>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
      <Modal
        open={openBookingInfoModal}
        onClose={handleBookingInfoClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <BookingInfoModal
            bookingId={selectedBookingId}
            handleClose={handleBookingInfoClose}
            pilots={pilotData}
            resources={resourceData}
          />
        </SoftBox>
      </Modal>
      <Modal
        open={showManageMeetingRoomsModal}
        onClose={handleMeetingRoomManagementModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <ManageMeetingRoomsModal
            handleClose={handleMeetingRoomManagementModalClose}
          />
        </SoftBox>
      </Modal>
      <Modal
        open={openAddEditBookingModal}
        onClose={handleAddEditBookingClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <BookingModal
            pilots={pilotData}
            resources={resourceData}
            selection={selectedCalendarData}
            handleClose={handleAddEditBookingClose}
            reservationType={{
              label: "Meeting Room",
              value: "meetingRoom",
            }}
          />
        </SoftBox>
      </Modal>
      <SoftSnackbar
        duration={3000}
        color="error"
        icon="warning"
        title="No Meeting Rooms"
        content="Please add a meeting room first."
        open={showNoMeetingRoomsSnackbar}
        onClose={closeNoMeetingRoomsSnackbar}
        close={closeNoMeetingRoomsSnackbar}
        bgWhite
      />
      <Footer />
    </DashboardLayout>
  );
}

export default WithPermissionWrapper(
  systemPermissions.VIEW_MEETING_ROOMS,
  MeetingRoomsSchedule
);
