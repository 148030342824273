import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import SoftBox from "src/components/SoftBox";
import { useClubs } from "src/features/club/ClubProvider";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import { query, where } from "firebase/firestore";
import Modal from "@mui/material/Modal";

import { getClubDocumentTypesCollection } from "src/features/club/collections";
import { getLessonDocumentsCollection } from "src/features/lms/collections";
import CompactDocumentsList from "../CompactDocumentsList";
import { UploadGenericDocumentModal } from "src/modals/UploadGenericDocument";

const standardDocumentTypes = [
  { label: "Lesson Outline", value: "lessonOutline" },
  { label: "FAA Requirements", value: "requirements" },
];

function Documents({ courseId, stageId, lessonId, add }) {
  const { selectedClubId } = useClubs();
  const [allDocumentTypes, setAllDocumentTypes] = useState();
  const [openAddDocument, setOpenAddDocument] = useState(false);

  const { addData } = entityCrudUtils();

  const handleOpenAddDocument = () => setOpenAddDocument(true);
  const handleCloseAddDocument = async (documentsAdded) => {
    if (documentsAdded && documentsAdded.length > 0) {
      await Promise.all(
        documentsAdded.map(async (document) => {
          await addData(
            getLessonDocumentsCollection(
              selectedClubId,
              courseId,
              stageId,
              lessonId
            ),
            document
          );
        })
      );
    }
    setOpenAddDocument(false);
  };

  const clubDocumentTypesRef = getClubDocumentTypesCollection(selectedClubId);
  const { data: clubDocumentTypes, isDataLoaded: clubDocumentTypesLoaded } =
    useRealtimeCollectionData(
      query(clubDocumentTypesRef, where("deleted", "==", false))
    );

  useEffect(() => {
    const types = [...standardDocumentTypes];
    if (clubDocumentTypesLoaded) {
      clubDocumentTypes.forEach((element) => {
        types.push({
          label: element.name,
          value: element.id,
        });
      });
    }
    setAllDocumentTypes(types);
  }, [clubDocumentTypes, clubDocumentTypesLoaded]);

  return (
    <>
      <SoftBox>
        <Grid container spacing={3}>
          <Grid item xs={12} marginTop={2} borderRadius="sm">
            {allDocumentTypes?.length > 0 && clubDocumentTypesLoaded && (
              <CompactDocumentsList
                title="Documents"
                documentsCollection={getLessonDocumentsCollection(
                  selectedClubId,
                  courseId,
                  stageId,
                  lessonId
                )}
                openUploadModal={handleOpenAddDocument}
                documentTypes={allDocumentTypes}
                noAdd={!add}
              />
            )}
          </Grid>
        </Grid>
      </SoftBox>
      <Modal
        open={openAddDocument}
        onClose={handleCloseAddDocument}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <UploadGenericDocumentModal
            handleClose={handleCloseAddDocument}
            storagePath={`clubs/${selectedClubId}/courses/${courseId}/stages/${stageId}/lessons/${lessonId}/documents`}
          />
        </SoftBox>
      </Modal>
    </>
  );
}

export default Documents;
