import { useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";

import Swal from "sweetalert2";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useUser } from "src/features/user/UserProvider";

import AddNewCard from "src/modals/AddNewCard";
import Bill from "../Bill";

function PaymentMethod({ cards, fetchCards, setDefaultCard }) {
  const { user } = useUser();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = (result) => {
    if (result.success) fetchCards();
    setOpen(false);
  };

  const isDefaultCard = (cardId) => {
    if (user.defaultCard === cardId) return true;
    return false;
  };

  const deleteCard = (cardId) => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button",
        confirmButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "You will not be able to recover this card!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        icon: "warning",
      })
      .then((result) => {
        if (result.isConfirmed) {
          const functions = getFunctions();
          const deleteCardFromCustomer = httpsCallable(
            functions,
            "deleteCardFromCustomer"
          );
          deleteCardFromCustomer({
            customerId: user.stripeId,
            paymentMethodId: cardId,
          })
            .then(() => {
              fetchCards();
            })
            .catch((error) => {
              console.error(error);
            });
        }
      });
  };

  return (
    <Card id="delete-account">
      <SoftBox
        pt={2}
        px={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <SoftTypography variant="h6" fontWeight="medium">
          Payment Methods
        </SoftTypography>
        <SoftBox>
          <SoftButton variant="gradient" color="dark" onClick={handleOpen}>
            <Icon
              sx={{
                fontWeight: "bold",
              }}
            >
              add
            </Icon>
            &nbsp;add new card
          </SoftButton>
        </SoftBox>
      </SoftBox>
      <SoftBox pt={1} pb={2} px={2}>
        <SoftBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
        >
          {cards.length > 0 &&
            cards.map((card) => (
              <Bill
                key={card.id}
                card={card.card}
                cardId={card.id}
                name={card.billing_details.name}
                billingAddress={card.billing_details.address}
                defaultCard={isDefaultCard(card.id)}
                setDefaultCard={setDefaultCard}
                deleteCard={() => deleteCard(card.id)}
              />
            ))}
        </SoftBox>
      </SoftBox>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <AddNewCard userId={user?.uid} handleClose={handleClose} />
        </SoftBox>
      </Modal>
    </Card>
  );
}

PaymentMethod.propTypes = {
  cards: PropTypes.array,
  fetchCards: PropTypes.func,
  setDefaultCard: PropTypes.func,
};

export default PaymentMethod;
