import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import logo from "src/assets/images/logo.png";
import { format } from "date-fns";
import { calculateReminderRemainingTime } from "src/features/aircraft/utils";

const fontSize = 12;
// Create styles
const styles = StyleSheet.create({
  page: {
    color: "#3a3a3c",
    display: "flex",
    flexDirection: "column",
    padding: 20,
    fontSize,
    fontFamily: "Helvetica",
    height: "100%",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    fontFamily: "Helvetica-Bold",
  },
  section: {
    margin: 5,
    padding: 5,
    flexGrow: 1,
  },
  aircraftName: {
    fontSize,
  },
  sectionTitle: {
    fontSize: fontSize + 3,
    fontFamily: "Helvetica-Bold",
    fontWeight: 600,
    width: "100%",
    textAlign: "center",
    marginBottom: 5,
    flexGrow: 1,
  },
  disclaimerTitle: {
    marginTop: "20px",
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
  },
  disclaimerText: {
    fontSize: fontSize - 2,
    fontFamily: "Helvetica",
  },
  table: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: 5,
  },
  logo: { height: 46, marginLeft: "auto", marginRight: "auto" },
  slogan: {
    fontSize: 12,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 10,
  },
  splitContainer: { display: "flex", flexDirection: "row", width: "100%" },
  sideContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  sideContainerWithBorder: {
    flex: 1,
    backgroundImage: "linear-gradient(#67748e 50%, rgba(255,255,255,0) 0%)",
    backgroundPosition: "right",
    backgroundSize: "1px 15px",
    backgroundRepeat: "repeat-y",
    display: "flex",
    flexDirection: "column",
  },
  mainContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    paddingTop: 20,
  },
  dispatchSheet: {
    fontSize: 16,
    marginTop: 10,
    marginBottom: 10,
    fontFamily: "Helvetica-Bold",
    textAlign: "center",
  },
  squawkContainer: {
    display: "flex",
    flexDirection: "row",
    fontSize: fontSize - 2,
    margin: 0,
    padding: 0,
    lineHeight: 0,
  },
  aircraftFields: {
    marginBottom: 15,
    fontFamily: "Helvetica-Bold",
  },
  disclaimerContainer: {
    marginTop: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    flex: 1,
  },
});

const labelAndValueStyles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 15,
  },
  label: {
    fontFamily: "Helvetica-Bold",
    textAlign: "right",
    fontSize,
    marginRight: "5px",
  },
  value: {
    fontSize,
  },
});

function LabelAndValue({ label, value }) {
  return (
    <View style={labelAndValueStyles.container}>
      <View style={labelAndValueStyles.label}>
        <Text>{label}</Text>
      </View>
      <View style={labelAndValueStyles.value}>
        <Text>{value}</Text>
      </View>
    </View>
  );
}

function nullToNone(val) {
  if (!val) return "None";
  return val;
}

function getDateAndTime(val) {
  if (val)
    return `${format(val.toDate(), "MM/dd/yyyy")} @ ${format(
      val.toDate(),
      "HH:mm"
    )}`;
  return val;
}

// Create Document Component
export function BookingDispatchPdf({
  clubName,
  bookingData,
  aircraftData,
  locationData,
  pilots,
  aircraftSquawks,
  aircraftReminders,
}) {
  const bookingPilot = pilots.find(
    (pilot) => pilot.id === bookingData.extendedProps.pilot.value
  );

  const getSquawks = () => {
    if (aircraftSquawks.length === 0) return <Text>No squawks</Text>;
    return aircraftSquawks.map((squawk) => (
      <View style={styles.squawkContainer} key={squawk.id}>
        <View style={{ flex: 8, padding: 2, paddingRight: 4 }}>
          <Text>{squawk.description}</Text>
        </View>
        <View style={{ flex: 2, padding: 2 }}>
          <Text>{squawk.date.toDate().toLocaleDateString()}</Text>
        </View>
        <View style={{ flex: 2, padding: 2 }}>
          <Text>{squawk.verified ? "Verified" : "Not Verified"}</Text>
        </View>
      </View>
    ));
  };
  const getReminders = () => {
    if (aircraftReminders.length === 0) return <Text>No reminders</Text>;
    const calculatedReminders = aircraftReminders
      .map((reminder) => {
        reminder.aircraft = aircraftData;
        return {
          ...reminder,
          ...calculateReminderRemainingTime(reminder),
        };
      })
      .sort((a, b) => {
        const aNum = a.percentComplete || 0;
        const bNum = b.percentComplete || 0;
        if (aNum > bNum) {
          return -1;
        }
        if (aNum < bNum) {
          return 1;
        }
        return 0;
      })
      .slice(0, 5);

    return calculatedReminders.map((reminder) => (
      <View style={styles.squawkContainer} key={reminder.id}>
        <View style={{ flex: 7, paddingRight: 4 }}>
          <Text>{reminder.name}</Text>
        </View>
        <View style={{ flex: 1, textAlign: "right" }}>
          <Text>{reminder.percentRemaining}% |</Text>
        </View>
        <View style={{ flex: 4 }}>
          <Text> {reminder.timeRemaining} hours remaining</Text>
        </View>
      </View>
    ));
  };

  return (
    <Document>
      <Page size="A4" style={styles.page} orientation="landscape">
        <View style={styles.mainContainer}>
          <View style={{ paddingBottom: 20 }}>
            <Text style={styles.dispatchSheet}>Dispatch Sheet</Text>
          </View>

          <View style={styles.splitContainer}>
            <View
              style={{ ...styles.sideContainerWithBorder, paddingRight: 0 }}
            >
              <View style={{ marginTop: -80, marginBottom: 15 }}>
                <Image style={styles.logo} src={logo} />
                <Text style={styles.slogan}>Have a safe flight!</Text>
              </View>
              <View
                style={{
                  ...styles.sectionTitle,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text style={{ fontFamily: "Helvetica-Bold" }}>{clubName}</Text>
              </View>

              <View>
                <View style={styles.section}>
                  <LabelAndValue
                    label="Location:"
                    value={
                      `${locationData?.icao} - ${locationData?.name}` ||
                      "No location selected"
                    }
                  />
                  <View style={styles.splitContainer}>
                    <View style={styles.sideContainer}>
                      <LabelAndValue
                        label="Departure:"
                        value={getDateAndTime(bookingData?.start)}
                      />
                    </View>
                    <View style={styles.sideContainer}>
                      <LabelAndValue
                        label="Arrival:"
                        value={getDateAndTime(bookingData?.end)}
                      />
                    </View>
                  </View>
                </View>
                <View style={styles.section}>
                  <LabelAndValue
                    label="Member 1:"
                    value={`${bookingData?.extendedProps?.pilot?.label} (${bookingPilot?.email})`}
                  />
                  <LabelAndValue
                    label="Instructor:"
                    value={nullToNone(
                      bookingData?.extendedProps?.instructor?.label
                    )}
                  />
                </View>
              </View>
              <View
                style={{
                  ...styles.sectionTitle,
                  paddingBottom: 10,
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {aircraftData?.aircraft_description?.tail_number ??
                      aircraftData?.tailNumber}
                    :
                  </Text>
                  <Text style={{ marginLeft: 2, fontFamily: "Helvetica" }}>
                    {aircraftData?.displayName}
                  </Text>
                </View>
              </View>
              <View style={styles.splitContainer}>
                <View style={styles.sideContainer}>
                  <LabelAndValue
                    style={styles.aircraftFields}
                    label="Hobbs Out: "
                    value={aircraftData.hobbs}
                  />
                  <LabelAndValue
                    style={styles.aircraftFields}
                    label="Tach 1 Out: "
                    value={aircraftData.tach1}
                  />
                </View>
                <View
                  style={{
                    ...styles.sideContainer,
                    alignItems: "flex-end",
                    paddingRight: 20,
                  }}
                >
                  <LabelAndValue
                    style={styles.aircraftFields}
                    label="Hobbs In: "
                    value="_____________"
                  />
                  <LabelAndValue
                    style={styles.aircraftFields}
                    label="Tach 1 In: "
                    value="_____________"
                  />
                  <LabelAndValue
                    style={styles.aircraftFields}
                    label="Flight Time: "
                    value="_____________"
                  />
                  {bookingData?.extendedProps?.instructor?.value && (
                    <LabelAndValue
                      style={styles.aircraftFields}
                      label="Ground Time: "
                      value="_____________"
                    />
                  )}
                </View>
              </View>
              <View style={{ ...styles.splitContainer, marginTop: 10 }}>
                <View style={styles.sideContainer}>
                  <LabelAndValue
                    style={styles.aircraftFields}
                    label="Fuel Added (g): "
                    value="__________"
                  />
                </View>
                <View
                  style={{
                    ...styles.sideContainer,
                    alignItems: "flex-end",
                    paddingRight: 20,
                  }}
                >
                  <LabelAndValue
                    style={styles.aircraftFields}
                    label="Oil Added (qts): "
                    value="_____________"
                  />
                </View>
              </View>
              <View
                style={{
                  marginTop: 80,
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
              >
                <LabelAndValue
                  label="Signature:"
                  value="___________________________________________"
                />
              </View>
              <View />
            </View>
            <View style={{ ...styles.sideContainer, paddingLeft: 30 }}>
              <View style={{ display: "flex", flexDirection: "column" }}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 10,
                  }}
                >
                  <View>
                    <Text
                      style={{ fontFamily: "Helvetica-Bold", marginBottom: 5 }}
                    >
                      Squawks / Discrepancies:
                    </Text>
                  </View>
                  <View style={{ display: "flex", flexDirection: "column" }}>
                    {getSquawks()}
                  </View>
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 10,
                  }}
                >
                  <View>
                    <Text
                      style={{ fontFamily: "Helvetica-Bold", marginBottom: 5 }}
                    >
                      Reminders:
                    </Text>
                  </View>
                  <View style={{ display: "flex", flexDirection: "column" }}>
                    {getReminders()}
                  </View>
                </View>

                <View style={{ display: "flex", flexDirection: "column" }}>
                  <View>
                    <Text
                      style={{ fontFamily: "Helvetica-Bold", marginBottom: 5 }}
                    >
                      Notes
                    </Text>
                  </View>
                  <Text style={styles.squawkContainer}>
                    {bookingData.notes || "No public notes."}
                  </Text>
                </View>
              </View>

              <View style={styles.disclaimerContainer}>
                <View>
                  <Text style={styles.disclaimerTitle}>Important: </Text>
                </View>
                <View>
                  <Text style={styles.disclaimerText}>
                    It is the pilot-in-command&lsquo;s ultimate responsibility
                    to ensure that they are qualified to conduct flight
                    operations in the aircraft and that the aircraft is
                    airworthy before each flight.
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}
