import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import EditableCourseCard from "../components/EditableCourseCard";
import Modal from "@mui/material/Modal";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";
import SoftInput from "src/components/SoftInput";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";

import { useClubs } from "src/features/club/ClubProvider";
import { getClubCoursesCollection } from "src/features/lms/collections";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";

import Swal from "sweetalert2";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { AddCourseModal } from "src/modals/AddCourseModal";
import { CircularProgress } from "@mui/material";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";
import { systemPermissions } from "src/interfaces/roles/role.interface";

function ManageCourses() {
  const { selectedClubId } = useClubs();
  const [openAddCourseModal, setOpenAddCourseModal] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [filteredCourses, setFilteredCourses] = useState([]);
  const { deleteData } = entityCrudUtils();
  const navigate = useNavigate();
  const { data: allCourses, isDataLoaded } = useRealtimeCollectionData(
    getClubCoursesCollection(selectedClubId),
    false
  );

  useEffect(() => {
    setFilteredCourses(
      allCourses.filter((course) => {
        return course.title.toLowerCase().includes(searchValue.toLowerCase());
      })
    );
  }, [allCourses, searchValue]);

  const closeAddCourseModal = () => {
    setSelectedCourse(null);
    setOpenAddCourseModal(false);
  };

  const handleCourseSelection = (course) => {
    navigate(`/lms/courses/${course.id}`);
  };

  const handleEditClick = (e, course) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedCourse(course);
    setOpenAddCourseModal(true);
  };

  const handleDeleteClick = (e, course) => {
    e.preventDefault();
    e.stopPropagation();
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.value) {
          await deleteData({
            entity: getClubCoursesCollection(selectedClubId),
            pathSegmentsArr: [course.id],
          });
        }
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  mb={3}
                  pt={2}
                >
                  <SoftBox lineHeight={1}>
                    <SoftTypography variant="h5" fontWeight="medium">
                      All Courses
                    </SoftTypography>
                    <SoftTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      All courses currently available to your students.
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox display="flex" gap="1em">
                    <SoftInput
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      placeholder="Search..."
                      sx={{ minWidth: "300px" }}
                    />
                    <WithPermissions
                      permissions={systemPermissions.CREATE_LMS_COURSE}
                    >
                      <SoftButton
                        color="info"
                        onClick={() => setOpenAddCourseModal(true)}
                        sx={{ minWidth: "140px" }}
                      >
                        <Icon>add</Icon>
                        Add Course
                      </SoftButton>
                    </WithPermissions>
                  </SoftBox>
                </SoftBox>
                {isDataLoaded ? (
                  <Grid container spacing={4} mb={3}>
                    {filteredCourses.map((course) => (
                      <EditableCourseCard
                        onDeleteClick={handleDeleteClick}
                        onEditClick={handleEditClick}
                        onCardClick={() => handleCourseSelection(course)}
                        course={course}
                        clubId={selectedClubId}
                        key={course.id}
                      />
                    ))}
                  </Grid>
                ) : (
                  <SoftBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="8rem"
                    width="100%"
                  >
                    <CircularProgress />
                  </SoftBox>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
      <Modal
        open={openAddCourseModal}
        onClose={closeAddCourseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <AddCourseModal
            courseData={selectedCourse}
            handleClose={closeAddCourseModal}
          />
        </SoftBox>
      </Modal>
    </DashboardLayout>
  );
}

export default ManageCourses;
