import { useState } from "react";
import { Card } from "@mui/material";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import SoftEditor from "src/components/SoftEditor";
import SoftInput from "src/components/SoftInput";
import SoftButton from "src/components/SoftButton";
import PropTypes from "prop-types";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useClubs } from "src/features/club/ClubProvider";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { getClubCoursesCollection } from "src/features/lms/collections";
import { useNavigate } from "react-router-dom";

const schema = yup
  .object({
    title: yup.string().required("A lesson title is required."),
  })
  .required();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "15px",
  overflow: "scroll",
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
};

export function AddCourseModal({ handleClose, courseData }) {
  const { selectedClubId } = useClubs();
  const navigate = useNavigate();
  const { addData, updateData } = entityCrudUtils();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: courseData?.title,
    },
    resolver: yupResolver(schema),
  });

  const [descriptionValue, setDescriptionValue] = useState(
    courseData?.objective ?? ""
  );
  const [descriptionTextValue, setDescriptionTextValue] = useState(
    courseData?.objectiveText ?? ""
  );

  const [objectiveValue, setObjectiveValue] = useState(
    courseData?.objective || ""
  );
  const [objectiveTextValue, setObjectiveTextValue] = useState(
    courseData?.objectiveText || ""
  );

  const [completionStandardsValue, setCompletionStandardsValue] = useState(
    courseData?.completionStandards || ""
  );
  const [completionStandardsTextValue, setCompletionStandardsTextValue] =
    useState(courseData?.completionStandardsText || "");

  const onSubmit = async (data) => {
    if (courseData?.id) {
      await updateData(
        {
          entity: getClubCoursesCollection(selectedClubId),
          pathSegmentsArr: [courseData.id],
        },
        {
          ...courseData,
          ...data,
          description: descriptionValue,
          descriptionText: descriptionTextValue,
          completionStandards: completionStandardsValue,
          completionStandardsText: completionStandardsTextValue,
          objective: objectiveValue,
          objectiveText: objectiveTextValue,
        },
        courseData.id
      );
      handleClose();
    } else {
      const newCourse = await addData(
        getClubCoursesCollection(selectedClubId),
        {
          ...data,
          description: descriptionValue,
          descriptionText: descriptionTextValue,
          completionStandards: completionStandardsValue,
          completionStandardsText: completionStandardsTextValue,
          objective: objectiveValue,
          objectiveText: objectiveTextValue,
          createdAt: new Date(),
        }
      );

      newCourse && navigate(`/lms/courses/${newCourse.id}`);
      handleClose();
    }
  };

  const onDescriptionChange = (content, delta, source, editor) => {
    setDescriptionValue(content);
    setDescriptionTextValue(editor.getText());
  };

  const onObjectiveChange = (content, delta, source, editor) => {
    setObjectiveValue(content);
    setObjectiveTextValue(editor.getText());
  };

  const onCompletionStandardsChange = (content, delta, source, editor) => {
    setCompletionStandardsValue(content);
    setCompletionStandardsTextValue(editor.getText());
  };

  return (
    <Card sx={style}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="bold">
            {courseData?.id ? "Edit" : "Add New"} Course
          </SoftTypography>
          <SoftBox mt={1.625}>
            <Grid container spacing={3}>
              <Grid item xs={12} mt={2}>
                <SoftBox ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Title
                  </SoftTypography>
                </SoftBox>
                <SoftBox pl={2} pt={2}>
                  <SoftInput
                    {...register("title", {
                      required: true,
                    })}
                    placeholder="Eg. Private Pilot - Part 141"
                  />
                  {errors?.title?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.title.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={12}>
                <SoftBox ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Description
                  </SoftTypography>
                </SoftBox>
                <SoftBox p={2} sx={{ height: "150px" }}>
                  <SoftEditor
                    defaultValue={descriptionValue}
                    onChange={onDescriptionChange}
                  />
                </SoftBox>
              </Grid>
              <Grid item xs={12}>
                <SoftBox ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Objective
                  </SoftTypography>
                </SoftBox>
                <SoftBox p={2} sx={{ height: "150px" }}>
                  <SoftEditor
                    defaultValue={objectiveValue}
                    onChange={onObjectiveChange}
                  />
                </SoftBox>
              </Grid>
              <Grid item xs={12} mt={2}>
                <SoftBox ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Completion Standards
                  </SoftTypography>
                </SoftBox>
                <SoftBox p={2} sx={{ height: "150px" }}>
                  <SoftEditor
                    defaultValue={completionStandardsValue}
                    onChange={onCompletionStandardsChange}
                  />
                </SoftBox>
              </Grid>
            </Grid>
            <Grid container spacing={1} mt={3}>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={handleClose}
                  >
                    Cancel
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    type="submit"
                  >
                    {courseData?.id ? "Update Course" : "Add Course"}
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

AddCourseModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  courseData: PropTypes.object,
};
