import { useMemo, useState, useEffect } from "react";

// @mui core components
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";

import { useClubs } from "src/features/club/ClubProvider";
import { getFunctions, httpsCallable } from "firebase/functions";

import { getTableColumns, TableActions } from "./tableColumns";
import DataTable from "src/components/Tables/DataTable";

import { AddPaymentTerminalModal } from "src/modals/AddPaymentTerminalModal";

function PaymentTerminals() {
  const { selectedClubId } = useClubs();
  const [terminals, setTerminals] = useState([]);
  const [openAddTerminalModal, setOpenAddTerminalModal] = useState(false);

  const fetchTerminals = async () => {
    const functions = getFunctions();
    const getClubPaymentTerminals = httpsCallable(
      functions,
      "getClubPaymentTerminals"
    );
    const terminalsList = await getClubPaymentTerminals({
      clubId: selectedClubId,
    });

    setTerminals(terminalsList.data);
  };

  useEffect(() => {
    fetchTerminals();
  }, []);

  const handleTableActions = (action) => {
    switch (action) {
      case TableActions.DELETE:
        break;
      case TableActions.EDIT:
        setOpenAddTerminalModal(true);
        break;

      default:
        break;
    }
  };

  const handleAddTerminalModalClose = (terminal) => {
    if (terminal.data?.id) setTerminals([...terminals, terminal.data]);
    setOpenAddTerminalModal(false);
  };

  const tableColumns = useMemo(() => getTableColumns(handleTableActions), []);

  return (
    <Card
      id="payment-terminals"
      sx={{
        overflow: "visible",
      }}
    >
      <SoftBox p={3} lineHeight={1}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5">Payment Terminals</SoftTypography>
        </SoftBox>
        <SoftTypography variant="button" color="text" fontWeight="regular">
          Manage your payment terminals, each terminal is assigned to a club
          location.
        </SoftTypography>
      </SoftBox>
      <SoftBox pb={3} px={3}>
        <SoftBox
          xs={12}
          padding={1}
          sx={{
            maxWidth: "100%",
            overflow: "hidden",
          }}
        >
          {terminals && terminals.length > 0 && (
            <DataTable
              entriesPerPage={false}
              maxWidth="100%"
              table={{
                columns: tableColumns,
                rows: terminals,
              }}
            />
          )}
        </SoftBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} justifyContent="flex-end" display="flex">
            <SoftButton
              color="primary"
              variant="contained"
              type="submit"
              size="large"
              onClick={() => setOpenAddTerminalModal(true)}
            >
              Add New Terminal
            </SoftButton>
          </Grid>
        </Grid>
      </SoftBox>
      <Modal
        open={openAddTerminalModal}
        onClose={handleAddTerminalModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <AddPaymentTerminalModal handleClose={handleAddTerminalModalClose} />
        </SoftBox>
      </Modal>
    </Card>
  );
}

export default PaymentTerminals;
