import { useEffect, useState } from "react";
// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import MiniStatisticsCard from "src/containers/Cards/StatisticsCards/MiniStatisticsCard";
import HoursRemainingCard from "src/containers/Cards/HoursRemainingCard";

import { doc } from "firebase/firestore";

import { useUser } from "src/features/user/UserProvider";
import { useLms } from "src/features/lms/LmsProvider";
import { useClubs } from "src/features/club/ClubProvider";
import { useNavigate } from "react-router-dom";
import { useDocument } from "react-firebase-hooks/firestore";

import { formatCurrency } from "src/features/utils";
import { getBillingCollection } from "src/features/billing/collections";
import { Card, Icon, Modal } from "@mui/material";

import { AddFundsModal } from "src/modals/AddFundsModal";

import borders from "src/assets/theme/base/borders";
import boxShadows from "src/assets/theme/base/boxShadows";
import "./SidenavAccountBalance.scss";

// Soft UI Dashboard PRO React context
import { useSoftUIController } from "src/context";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import { usePermissions } from "src/hooks/usePermissions";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";
import AccountBalanceCard from "../Cards/AccountBalanceCard";

function SidenavAccountBalance() {
  const { userId } = useUser();
  const { selectedClubId, selectedClub } = useClubs();
  const { hasAccess } = usePermissions();
  const [balance, setBalance] = useState(0);
  const [openAddFunds, setOpenAddFunds] = useState(false);
  const navigate = useNavigate();
  const [controller] = useSoftUIController();
  const { miniSidenav } = controller;
  const { isStudent } = useLms();

  const handleCloseAddFunds = () => {
    setOpenAddFunds(false);
  };

  const { md } = boxShadows;
  const { borderRadius } = borders;

  const handleAccountBalanceClick = () => {
    navigate("/account/billing");
  };

  const handleAddFunds = () => {
    setOpenAddFunds(true);
  };

  const [billingSnapshot, isBillingLoading] = useDocument(
    userId && doc(getBillingCollection(), userId),
    {
      snapshotListenOptions: {
        includeMetadataChanges: false,
      },
    }
  );

  useEffect(() => {
    if (selectedClubId && billingSnapshot && !isBillingLoading) {
      const accountBalances = billingSnapshot?.data();
      if (accountBalances) {
        const clubBalance = accountBalances[selectedClubId];
        if (clubBalance) {
          setBalance(clubBalance);
        } else {
          setBalance(0);
        }
      }
    }
  }, [selectedClubId, billingSnapshot, isBillingLoading]);

  if (
    hasAccess(systemPermissions.PREFLIGHT_ADMIN) &&
    selectedClubId === "backoffice"
  ) {
    return null;
  }

  return (
    <SoftBox my={2} mx={2}>
      <SoftBox mb={3}>
        {isBillingLoading && (
          <SoftBox
            sx={{
              borderRadius: borderRadius.xl,
              boxShadow: md,
              cursor: "pointer",
            }}
            onClick={handleAccountBalanceClick}
          >
            <MiniStatisticsCard
              title={{
                text: "Account Balance",
                fontWeight: "bold",
              }}
              count="Loading..."
              icon={{
                color: "info",
                component: "paid",
              }}
            />
          </SoftBox>
        )}
        {billingSnapshot && (
          <SoftBox>
            {miniSidenav ? (
              <SoftBox
                variant="gradient"
                bgColor="info"
                color="white"
                width="3rem"
                height="3rem"
                margin="auto"
                borderRadius="md"
                display="flex"
                justifyContent="center"
                alignItems="center"
                shadow="md"
              >
                <Icon fontSize="small" color="inherit">
                  paid
                </Icon>
              </SoftBox>
            ) : (
              <>
                <SoftBox
                  sx={{
                    borderRadius: borderRadius.xl,
                    boxShadow: md,
                    zIndex: 3,
                    position: "relative",
                    cursor: "pointer",
                  }}
                  onClick={handleAccountBalanceClick}
                >
                  {selectedClubId === "WIiWPjziBp5WbB27y0Y2" ||
                  // selectedClubId === "2mx7hN4R1majiwtYHUzv" ||
                  selectedClubId === "v1Xj2DtMWGnCJkp" ? (
                    <>
                      {isStudent ? (
                        <HoursRemainingCard
                          title={{
                            text: "Lessons Remaining",
                            fontWeight: "bold",
                          }}
                          // count={formatCurrency(billingSnapshot?.data()?.balance)}
                          lessonsRemaining={balance / 170}
                          balance={formatCurrency(balance)}
                          showBalanceVertical={true}
                          icon={{
                            color: "info",
                            component: "paid",
                          }}
                        />
                      ) : (
                        <HoursRemainingCard
                          title={{
                            text: "Hours Remaining",
                            fontWeight: "bold",
                          }}
                          // count={formatCurrency(billingSnapshot?.data()?.balance)}
                          dualRemaining={balance / 115}
                          soloRemaining={balance / 230}
                          balance={formatCurrency(balance)}
                          showBalanceVertical={true}
                          icon={{
                            color: "info",
                            component: "paid",
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <AccountBalanceCard
                      title={{
                        text: "Account Balance",
                        fontWeight: "bold",
                      }}
                      // count={formatCurrency(billingSnapshot?.data()?.balance)}
                      count={formatCurrency(balance)}
                      icon={{
                        color: "info",
                        component: "paid",
                      }}
                    />
                  )}
                </SoftBox>
                {selectedClub?.preferences?.disableAddFunds ? null : (
                  <WithPermissions
                    permissions={systemPermissions.ADD_FUNDS_TO_ACCOUNT}
                  >
                    <SoftBox
                      id="addFunds"
                      mb={1}
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Card
                        onClick={handleAddFunds}
                        sx={{
                          margin: "0 20px",
                          padding: "5px 0",
                          borderRadius: "0px 0px 10px 10px",
                          backgroundColor: "primary.main",
                          cursor: "pointer",
                        }}
                      >
                        <SoftBox display="inline-flex" alignSelf="center">
                          <Icon style={{ color: "white" }}>add</Icon>
                          <SoftTypography
                            variant="button"
                            fontWeight="bold"
                            color="white"
                          >
                            Add Funds
                          </SoftTypography>
                        </SoftBox>
                      </Card>
                    </SoftBox>
                  </WithPermissions>
                )}
              </>
            )}
          </SoftBox>
        )}
      </SoftBox>
      <Modal
        open={openAddFunds}
        onClose={handleCloseAddFunds}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <AddFundsModal handleClose={handleCloseAddFunds} />
        </SoftBox>
      </Modal>
    </SoftBox>
  );
}

export default SidenavAccountBalance;
