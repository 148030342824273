import { useMemo, useState } from "react";
import Swal from "sweetalert2";

// @mui core components
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";

import { useClubs } from "src/features/club/ClubProvider";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import { getClubMembershipsCollection } from "src/features/club/collections";

import { getTableColumns, TableActions } from "./tableColumns";
import DataTable from "src/components/Tables/DataTable";

import { AddMembershipModal } from "src/modals/AddMembershipModal";
import { query, where } from "firebase/firestore";

function Membership() {
  const { updateData, deleteData } = entityCrudUtils();
  const { selectedClubId } = useClubs();
  const [selectedMembership, setSelectedMembership] = useState(null);
  const [openAddMembershipModal, setOpenAddMembershipModal] = useState(false);

  const membershipsRef = getClubMembershipsCollection(selectedClubId);
  const { data: memberships, isDataLoaded: isMembershipsLoaded } =
    useRealtimeCollectionData(
      query(membershipsRef, where("deleted", "==", false))
    );

  const deleteMembership = (membership) => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button",
        confirmButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "You will not be able to recover this membership! Existing users will remain on this membership plan until they are assigned a new one.",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        icon: "warning",
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteData({
            entity: getClubMembershipsCollection(selectedClubId),
            pathSegmentsArr: [membership.id],
          });
        }
      });
  };

  const handleTableActions = (action, membership) => {
    switch (action) {
      case TableActions.DELETE:
        deleteMembership(membership);
        break;
      case TableActions.EDIT:
        setSelectedMembership(membership);
        setOpenAddMembershipModal(true);
        break;
      case TableActions.TOGGLE:
        updateData(
          {
            entity: getClubMembershipsCollection(selectedClubId),
            pathSegmentsArr: [membership.id],
          },
          { active: !membership.active }
        );
        break;
      default:
        break;
    }
  };

  const handleAddMembershipModalClose = () => {
    setOpenAddMembershipModal(false);
    setSelectedMembership(null);
  };

  const tableColumns = useMemo(() => getTableColumns(handleTableActions), []);

  return (
    <Card
      id="membership"
      sx={{
        overflow: "visible",
      }}
    >
      <SoftBox p={3} lineHeight={1}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5">Membership</SoftTypography>
        </SoftBox>
        <SoftTypography variant="button" color="text" fontWeight="regular">
          Create your different club membership plans and set the price for
          each.
        </SoftTypography>
        <br />
        <SoftTypography variant="button" color="text" fontWeight="regular">
          Users assigned to a membership will be billed automatically.
        </SoftTypography>
      </SoftBox>
      <SoftBox pb={3} px={3}>
        <SoftBox
          xs={12}
          padding={1}
          sx={{
            maxWidth: "100%",
            overflow: "hidden",
          }}
        >
          {isMembershipsLoaded && (
            <DataTable
              entriesPerPage={false}
              maxWidth="100%"
              table={{
                columns: tableColumns,
                rows: memberships,
              }}
            />
          )}
        </SoftBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} justifyContent="flex-end" display="flex">
            <SoftButton
              color="primary"
              variant="contained"
              type="submit"
              size="large"
              onClick={() => setOpenAddMembershipModal(true)}
            >
              Add New Membership
            </SoftButton>
          </Grid>
        </Grid>
      </SoftBox>
      <Modal
        open={openAddMembershipModal}
        onClose={handleAddMembershipModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <AddMembershipModal
            handleClose={handleAddMembershipModalClose}
            membership={selectedMembership}
          />
        </SoftBox>
      </Modal>
    </Card>
  );
}

export default Membership;
