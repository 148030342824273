import { useEffect, useState } from "react";
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import SoftBox from "src/components/SoftBox";
import Card from "@mui/material/Card";
import SoftTypography from "src/components/SoftTypography";
import Footer from "src/components/Footer";
import { useClubs } from "src/features/club/ClubProvider";
import DualView from "src/components/DualView/DualView";
import { formatRelative } from "date-fns";
import EventInfo from "./components/EventInfo";

import TimelineList from "./components/TimelineList";
import TimelineItem from "./components/TimelineItem";
import useClubEvents from "src/components/UpcomingEvents/eventsHelper";
import Modal from "@mui/material/Modal";
import { AddEventModal } from "../../../modals/AddEventModal";
import Swal from "sweetalert2";
import { getClubEventsCollection } from "src/features/club/collections";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import WithPermissionWrapper from "src/components/WithPermissions/WithPermissionWrapper";

function EventsSchedule() {
  const { events, eventsLoaded } = useClubEvents();
  const { selectedClub, selectedClubId } = useClubs();
  const [showPanel, setShowPanel] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [openAddEventModal, setOpenAddEventModal] = useState(false);
  const { deleteData } = entityCrudUtils();

  const handleAddEventModalClose = () => {
    setOpenAddEventModal(false);
  };

  const handleRowSelection = (event) => {
    setSelectedEvent(event);
    setShowPanel(true);
  };

  const handlePanelClose = () => {
    setShowPanel(false);
  };

  useEffect(() => {
    if (eventsLoaded && events.length > 0) {
      handleRowSelection(events[0]);
    }
  }, [eventsLoaded, events]);

  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return `${str.slice(0, num)} ...`;
  };

  const deleteEvent = (id, title) => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button",
        confirmButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: `Are you sure?`,
        text: `You will not be able to recover '${title}'!`,
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        icon: "warning",
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteData({
            entity: getClubEventsCollection(selectedClubId),
            pathSegmentsArr: [id],
          }).then((deleteResult) => {
            if (deleteResult?.error) {
              console.error(deleteResult.error.message);
            }
          });
        }
      });
  };

  const renderTimelineItems = events.map(
    ({ id, color, icon, title, startDate, endDate, description }, index) => (
      <SoftBox
        key={id}
        onClick={() =>
          handleRowSelection({
            id,
            color,
            icon,
            title,
            startDate,
            endDate,
            description,
          })
        }
        sx={
          selectedEvent.id === id
            ? {
                border: "0 solid",
                borderColor: "primary.main",
                borderRightWidth: "2px",
              }
            : {}
        }
      >
        <TimelineItem
          key={id}
          color={color}
          icon={icon}
          dateTime={formatRelative(startDate.toDate(), new Date())}
          title={title}
          description={truncateString(description, 200)}
          isSelected={selectedEvent.id === id}
          lastItem={index === events.length - 1}
          onClick={() =>
            handleRowSelection({
              id,
              color,
              icon,
              title,
              startDate,
              endDate,
              description,
            })
          }
          onEdit={() => {
            setOpenAddEventModal(true);
          }}
          onDelete={() => {
            deleteEvent(id, title);
          }}
        />
      </SoftBox>
    )
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            p={3}
          >
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                Upcoming Events
              </SoftTypography>
              <SoftTypography
                variant="button"
                fontWeight="regular"
                color="text"
              >
                All events from {selectedClub.name}.
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <DualView
            onClosePanel={handlePanelClose}
            showPanel={showPanel}
            leftPaneMinWidth={450}
            leftPaneMaxWidth={600}
            rightMinWidth={500}
          >
            <TimelineList title="">{renderTimelineItems}</TimelineList>
            <EventInfo event={selectedEvent} />
          </DualView>
        </Card>
      </SoftBox>
      <Footer />
      <Modal
        open={openAddEventModal}
        onClose={handleAddEventModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <AddEventModal
            handleClose={handleAddEventModalClose}
            event={selectedEvent}
          />
        </SoftBox>
      </Modal>
    </DashboardLayout>
  );
}

export default WithPermissionWrapper(
  systemPermissions.VIEW_EVENTS,
  EventsSchedule
);
