import { useState, useEffect, useMemo } from "react";
import { useClubs } from "src/features/club/ClubProvider";
import BookingInfoModal from "src/modals/BookingInfoModal";
import DefaultNotification from "src/containers/NotificationItems/DefaultNotification";
import BookingNotification from "src/containers/NotificationItems/BookingNotification";
import BookingRequestNotification from "src/containers/NotificationItems/BookingRequestNotification";
import team2 from "src/assets/images/team-2.jpg";
import { Menu, CircularProgress, Modal } from "@mui/material";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import SoftBox from "src/components/SoftBox";
import Divider from "@mui/material/Divider";
import SoftButton from "src/components/SoftButton";
import { useUser } from "src/features/user/UserProvider";
import { useNotifications } from "src/features/notifications/NotificationsProvider";
import { doc, updateDoc } from "firebase/firestore";
import { formatDistanceToNow } from "date-fns";
import SoftTypography from "src/components/SoftTypography";
import { getUsersNotificationsCollection } from "src/features/user-notifications/collections";
import {
  fetchAircraftResourceData,
  fetchClubPilotsData,
  fetchInstructorResourceData,
  fetchMeetingRoomResourceData,
} from "src/pages/book-flight/utils";
import useClubMeetingRooms from "src/features/meeting-room/meetingRoomsHelper";

const StyledContainer = styled.div`
  overflow: scroll;
  max-height: 600px;
  min-width: 400px;
`;

function renderNotificationItem(notif, onClick) {
  const timeAgo = formatDistanceToNow(notif?.date?.toDate(), {
    addSuffix: true,
  });

  switch (notif.notificationType) {
    case "booking":
      return (
        <BookingNotification
          // key={notif.id}
          onClick={() => onClick(notif)}
          data={notif}
          read={notif.read}
          date={timeAgo}
        />
      );

    case "bookingRequest":
      return (
        <BookingRequestNotification
          // key={notif.id}
          onClick={() => onClick(notif)}
          data={notif}
          read={notif.read}
          date={timeAgo}
        />
      );

    default:
      return (
        <DefaultNotification
          // key={notif.id}
          onClick={() => onClick(notif)}
          image={<img src={team2} alt="notif" />}
          title={[notif.title, notif.description]}
          read={notif.read}
          date={timeAgo}
        />
      );
  }
}

function NotificationTray(props) {
  const {
    selectedClubId,
    selectedLocationId,
    selectedClub,
    locationUsers,
    clubUsersPermissions,
    instructors: clubInstructors,
    instructorPreferences: clubInstructorPrefs,
  } = useClubs();
  const { meetingRooms } = useClubMeetingRooms();
  const { openMenu, handleCloseMenu } = props;
  const { userId } = useUser();
  const { notifications, notificationsLoaded } = useNotifications();

  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [bookingDialogOpen, setBookingDialogOpen] = useState(false);

  const [resourceData, setResourceData] = useState([]);
  const [pilotData, setPilotData] = useState([]);

  const instructors = useMemo(() => {
    return fetchInstructorResourceData(clubInstructors, clubInstructorPrefs);
  }, [clubInstructors, clubInstructorPrefs]);

  const fetchData = async () => {
    const aircrafts = await fetchAircraftResourceData(
      selectedClubId,
      selectedLocationId,
      selectedClub
    );
    const pilots = fetchClubPilotsData(locationUsers, clubUsersPermissions);
    const meetingRoomData = fetchMeetingRoomResourceData(meetingRooms);

    setResourceData([...instructors, ...aircrafts, ...meetingRoomData]);
    setPilotData(pilots);
  };

  useEffect(() => {
    fetchData();
  }, [
    selectedClubId,
    selectedLocationId,
    instructors,
    meetingRooms,
    locationUsers,
    clubUsersPermissions,
  ]);

  if (!userId) return null;

  const handleClearAllNotifications = async () => {
    const promises = notifications.map((notifDoc) => {
      const docRef = doc(getUsersNotificationsCollection(userId), notifDoc.id);
      return updateDoc(docRef, { read: true });
    });

    await Promise.all(promises);
  };

  const handleBookingModalClose = () => {
    setBookingDialogOpen(false);
    setSelectedBookingId(null);
  };

  const handleNotificationClick = async (notif) => {
    if (notif.notificationType === "booking") {
      setSelectedBookingId(notif.bookingId);
      setBookingDialogOpen(true);
    }

    if (notif.read) return;
    const docRef = doc(getUsersNotificationsCollection(userId), notif.id);
    await updateDoc(docRef, { read: true });
  };

  // show no notifications
  if (notificationsLoaded && notifications.length === 0) {
    return (
      <Menu
        anchorEl={openMenu}
        anchorReference={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(openMenu)}
        onClose={handleCloseMenu}
        sx={{
          mt: 2,
        }}
      >
        <SoftBox
          sx={{ minWidth: 360, p: 3 }}
          justifyContent="center"
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          <SoftTypography variant="p" color="dark" opacity={0.5}>
            You do not have any notifications!
          </SoftTypography>
        </SoftBox>
      </Menu>
    );
  }

  // show loading
  if (!notificationsLoaded || !notifications?.[0]) {
    return (
      <Menu
        anchorEl={openMenu}
        anchorReference={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(openMenu)}
        onClose={handleCloseMenu}
        sx={{
          mt: 2,
        }}
      >
        <SoftBox
          sx={{ minWidth: 360, p: 3 }}
          justifyContent="center"
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          <CircularProgress />
          <SoftTypography variant="p" sx={{ mt: 2 }}>
            Loading...
          </SoftTypography>
        </SoftBox>
      </Menu>
    );
  }

  return (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{
        mt: 2,
        maxWidth: 500,
      }}
    >
      <SoftBox>
        <SoftBox justifyContent="center" display="flex" sx={{ mr: 1, mb: 1 }}>
          <SoftButton
            variant="text"
            color="primary"
            onClick={handleClearAllNotifications}
          >
            Mark all as Read
          </SoftButton>
        </SoftBox>
        <Divider sx={{ mt: 0, mb: 0 }} />
        <StyledContainer>
          {notifications?.map((notif, index) => (
            <SoftBox key={notif.id}>
              {renderNotificationItem(notif, handleNotificationClick)}
              {index !== notifications.length - 1 && (
                <Divider sx={{ mt: 0, mb: 0 }} />
              )}
            </SoftBox>
          ))}
        </StyledContainer>
      </SoftBox>
      <Modal
        open={bookingDialogOpen}
        onClose={handleBookingModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <BookingInfoModal
            bookingId={selectedBookingId || ""}
            handleClose={handleBookingModalClose}
            resources={resourceData}
            pilots={pilotData}
          />
        </SoftBox>
      </Modal>
    </Menu>
  );
}

NotificationTray.propTypes = {
  handleCloseMenu: PropTypes.func,
  openMenu: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

export default NotificationTray;
