import { useMemo, useState } from "react";
import Swal from "sweetalert2";

// @mui core components
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";

import { useClubs } from "src/features/club/ClubProvider";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import { getClubLocationsCollection } from "src/features/club/collections";

import { getTableColumns, TableActions } from "./tableColumns";
import DataTable from "src/components/Tables/DataTable";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";

import { AddLocationModal } from "src/modals/AddLocationModal";

function Locations() {
  const { selectedClubId } = useClubs();
  const { deleteData } = entityCrudUtils();
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [openAddLocationModal, setOpenAddLocationModal] = useState(false);

  const { data: clubLocations, isDataLoaded: isClubLocationsLoaded } =
    useRealtimeCollectionData(
      getClubLocationsCollection(selectedClubId),
      false
    );

  const deleteLocation = (location) => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button",
        confirmButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "You will not be able to recover this location! Existing resources and members will be removed from this locaiton.",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        icon: "warning",
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteData({
            entity: getClubLocationsCollection(selectedClubId),
            pathSegmentsArr: [location.id],
          });
        }
      });
  };

  const handleTableActions = (action, location) => {
    switch (action) {
      case TableActions.DELETE:
        deleteLocation(location);
        break;
      case TableActions.EDIT:
        setSelectedLocation(location);
        setOpenAddLocationModal(true);
        break;

      default:
        break;
    }
  };

  const handleAddLocationModalClose = () => {
    setOpenAddLocationModal(false);
    setSelectedLocation(null);
  };

  const tableColumns = useMemo(() => getTableColumns(handleTableActions), []);

  return (
    <Card
      id="locations"
      sx={{
        overflow: "visible",
      }}
    >
      <SoftBox p={3} lineHeight={1}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5">Locations</SoftTypography>
        </SoftBox>
        <SoftTypography variant="button" color="text" fontWeight="regular">
          Add and manage your club locations.
        </SoftTypography>
      </SoftBox>
      <SoftBox pb={3} px={3}>
        <SoftBox
          xs={12}
          padding={1}
          sx={{
            maxWidth: "100%",
            overflow: "hidden",
          }}
        >
          {isClubLocationsLoaded && (
            <DataTable
              entriesPerPage={false}
              maxWidth="100%"
              table={{
                columns: tableColumns,
                rows: clubLocations,
              }}
            />
          )}
        </SoftBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} justifyContent="flex-end" display="flex">
            <SoftButton
              color="primary"
              variant="contained"
              type="submit"
              size="large"
              onClick={() => setOpenAddLocationModal(true)}
            >
              Add New Location
            </SoftButton>
          </Grid>
        </Grid>
      </SoftBox>
      <Modal
        open={openAddLocationModal}
        onClose={handleAddLocationModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <AddLocationModal
            handleClose={handleAddLocationModalClose}
            location={selectedLocation}
          />
        </SoftBox>
      </Modal>
    </Card>
  );
}

export default Locations;
