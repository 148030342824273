import { useState } from "react";
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";

import DataTable from "src/components/Tables/DataTable";

// Soft UI Dashboard PRO React base styles
import colors from "src/assets/theme/base/colors";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";

import Modal from "@mui/material/Modal";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

import { FileIcon, defaultStyles } from "react-file-icon";
import SoftAvatar from "src/components/SoftAvatar";
import ModalContainer from "../ModalContainer";
import { useDocuments } from "src/hooks/useDocument";
import { WithPermissions } from "../WithPermissions/WithPermissions";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import { useUser } from "src/features/user/UserProvider";
import { usePermissions } from "src/hooks/usePermissions";
import { AccordionDetails, AccordionSummary, Accordion } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

const iconButtonStyles = ({ typography: { size }, breakpoints }) => ({
  px: 0.9,

  "& .material-icons, .material-icons-round": {
    fontSize: `${size.md} !important`,
  },

  "& .MuiTypography-root": {
    display: "none",

    [breakpoints.up("sm")]: {
      display: "inline-block",
      lineHeight: 1.2,
      ml: 0.5,
    },
  },
});

export const TableActions = {
  EDIT: "EDIT",
  DELETE: "DELETE",
  DOWNLOAD: "DOWNLOAD",
};

const getDocumentsColumns = (hasDocumentsWithExpiration) => {
  return [
    {
      id: "fileIcon",
      width: "10%",
      Cell: ({ row: { original } }) => {
        const { fileType, url } = original;
        return ["png", "jpeg"].includes(fileType) ? (
          <SoftAvatar src={url} alt="image" variant="rounded" />
        ) : (
          <SoftBox sx={{ width: "48px" }}>
            <FileIcon extension={fileType} {...defaultStyles[fileType]} />
          </SoftBox>
        );
      },
    },
    {
      Header: "Description",
      accessor: "fileName",
    },
    {
      Header: "Document Type",
      width: "25%",
      Cell: ({ row: { original } }) => original.documentTypeLabel,
    },
    {
      Header: hasDocumentsWithExpiration ? "Expiration Date" : "Date",
      width: "10%",
      Cell: ({ row: { original } }) => {
        const date =
          original?.medicalData?.thirdExpiration ?? original?.expireDate;
        return date?.toDate()?.toDateString?.() ?? "";
      },
    },
  ];
};

const documentsColumnsAdmin = (onAction, hasDocumentsWithExpiration) => {
  return [
    {
      id: "fileIcon",
      width: "10%",
      Cell: ({ row: { original } }) => {
        const { fileType, url } = original;
        return ["png", "jpeg"].includes(fileType) ? (
          <SoftAvatar
            src={url}
            style={{ width: "20px" }}
            alt="image"
            variant="rounded"
          />
        ) : (
          <SoftBox sx={{ width: "20px" }}>
            <FileIcon extension={fileType} {...defaultStyles[fileType]} />
          </SoftBox>
        );
      },
    },
    {
      Header: "Description",
      accessor: "fileName",
      Cell: ({ value }) => {
        return (
          <SoftTypography
            variant="caption"
            style={{ overflowWrap: "anywhere" }}
          >
            {value}
          </SoftTypography>
        );
      },
    },
    {
      Header: "Document Type",
      width: "25%",
      Cell: ({ row: { original } }) => {
        return (
          <SoftTypography variant="caption" style={{ overflowWrap: "word" }}>
            {original.documentTypeLabel}
          </SoftTypography>
        );
      },
    },
    {
      Header: hasDocumentsWithExpiration ? "Expiration Date" : "Date",
      width: "10%",
      Cell: ({ row: { original } }) => {
        const date =
          original?.medicalData?.thirdExpiration ?? original?.expireDate;
        return (
          <SoftTypography
            variant="caption"
            style={{ overflowWrap: "anywhere" }}
          >
            {date?.toDate()?.toDateString?.() ?? ""}
          </SoftTypography>
        );
      },
    },
    {
      Header: "Actions",
      width: "10%",
      Cell: ({ row: { original } }) => (
        <SoftBox sx={{ display: "flex" }}>
          <IconButton
            aria-label="download"
            sx={iconButtonStyles}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onAction(TableActions.DOWNLOAD, original);
            }}
          >
            <Icon> {"download"}</Icon>
          </IconButton>
          <IconButton
            aria-label="delete"
            sx={iconButtonStyles}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onAction(
                hasDocumentsWithExpiration
                  ? TableActions.EDIT
                  : TableActions.DELETE,
                original
              );
            }}
          >
            <Icon> {hasDocumentsWithExpiration ? "edit" : "delete"}</Icon>
          </IconButton>
        </SoftBox>
      ),
    },
  ];
};

function DocumentsList({
  documentsCollection,
  openUploadModal,
  documentTypes,
  title,
  ownerId,
  deleteDocument,
  clickHandler,
  hasDocumentsWithExpiration,
}) {
  const { light } = colors;
  const { userId: currentUserId } = useUser();
  const { hasAccess } = usePermissions();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [selectedDocument, setSelectedDocument] = useState([]);
  const handleDocumentSelected = (row) => {
    setSelectedDocument(row);
    handleOpen(true);
  };
  const { documents, loadingDocuments } = useDocuments({
    documentsCollection,
    documentTypes,
  });

  const downloadHandler = (file) => {
    // window.open(document.url, "_blank");
    fetch(file.url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute("download", file.fileName); // specify the filename
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  };

  const documentHeader = (state) => {
    if (!state.currentDocument || state.config?.header?.disableFileName) {
      return null;
    }

    return <div>{selectedDocument.fileName || ""}</div>;
  };

  const handleTableActions = (action, document) => {
    switch (action) {
      case TableActions.DELETE:
        deleteDocument(document);
        break;

      case TableActions.EDIT:
        clickHandler(document);
        break;

      case TableActions.DOWNLOAD:
        downloadHandler(document);
        break;

      default:
        break;
    }
  };

  return (
    <Accordion elevation={0} sx={{ border: 1, borderColor: light.main }}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          background: "#E9ecef",
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          bgColor="light"
          width="100%"
        >
          <SoftTypography
            variant="h6"
            fontWeight="medium"
            textTransform="capitalize"
          >
            {title || "Documents"}
          </SoftTypography>
          <WithPermissions
            permissions={
              ownerId === currentUserId
                ? [
                    systemPermissions.UPLOAD_MEMBER_DOCUMENTS,
                    systemPermissions.UPLOAD_OWN_DOCUMENTS,
                  ]
                : systemPermissions.UPLOAD_MEMBER_DOCUMENTS
            }
          >
            <SoftButton
              variant="text"
              color="primary"
              size="small"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                openUploadModal();
              }}
            >
              <Icon>add</Icon>&nbsp; add new
            </SoftButton>
          </WithPermissions>
        </SoftBox>
      </AccordionSummary>
      <AccordionDetails>
        <SoftBox
          xs={12}
          padding={1}
          sx={{
            maxWidth: "100%",
            overflow: "hidden",
          }}
        >
          {documents.length < 1 ? (
            <SoftBox p={2}>
              <SoftBox
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <SoftTypography variant="button" fontWeight="light">
                  {loadingDocuments ? (
                    <i>Loading Documents...</i>
                  ) : (
                    "No Documents Uploaded"
                  )}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          ) : (
            <DataTable
              entriesPerPage={false}
              maxWidth="100%"
              table={{
                columns: hasAccess(systemPermissions.DELETE_USER_DOCUMENTS)
                  ? documentsColumnsAdmin(
                      handleTableActions,
                      hasDocumentsWithExpiration
                    )
                  : getDocumentsColumns(hasDocumentsWithExpiration),
                rows: documents,
              }}
              onRowSelect={handleDocumentSelected}
            />
          )}
        </SoftBox>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            backdropFilter: "blur(2px)",
          }}
        >
          <SoftBox>
            <ModalContainer handleClose={handleClose}>
              <DocViewer
                documents={[
                  {
                    uri: selectedDocument.url,
                    fileType: selectedDocument.fileType,
                  },
                ]}
                pluginRenderers={DocViewerRenderers}
                config={{
                  header: {
                    overrideComponent: documentHeader,
                    disableFileName: false,
                  },
                }}
              />
            </ModalContainer>
          </SoftBox>
        </Modal>
      </AccordionDetails>
    </Accordion>
  );
}

// Typechecking props for the Calendar
DocumentsList.propTypes = {
  documentsCollection: PropTypes.any,
  openUploadModal: PropTypes.func,
  documentTypes: PropTypes.array,
  title: PropTypes.string,
  ownerId: PropTypes.string,
  deleteDocument: PropTypes.func.isRequired,
};

export default DocumentsList;
