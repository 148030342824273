import PropTypes from "prop-types";

import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import colors from "src/assets/theme/base/colors";
import { iconButtonStyles } from "src/components/Tables/DragAndDrop/iconButtonStyles";
import {
  HeaderCell,
  HeaderCellText,
} from "src/components/Tables/DragAndDrop/HeaderCell";
import Grade from "src/pages/lms/components/Grade";
import { GradeTaskModal } from "src/pages/lms/modals/GradeTaskModal";
import Modal from "@mui/material/Modal";

import { useEffect, useState } from "react";
import SoftBox from "src/components/SoftBox";
import { CircularProgress } from "@mui/material";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import { usePermissions } from "src/hooks/usePermissions";
import SoftTypography from "src/components/SoftTypography";

const { grey } = colors;

function Row({ task, handleEditTask = () => {}, editable, isLoading }) {
  const { hasAccess } = usePermissions();

  return (
    <>
      <TableRow>
        <TableCell>{task?.index + 1}</TableCell>
        <TableCell>
          {isLoading ? (
            <CircularProgress size="1.5rem" color="secondary" />
          ) : (
            <Grade value={task.grade?.grade} />
          )}
        </TableCell>
        <TableCell>
          {task?.title}
          {task?.isOptional ? (
            <SoftTypography
              display="inline-block"
              fontSize="medium"
              fontWeight="light"
              color="secondary"
              sx={{ pl: 1 }}
            >
              (optional)
            </SoftTypography>
          ) : null}
        </TableCell>
        {editable && hasAccess([systemPermissions.LMS_GRADE_ANY_LESSON]) ? (
          <TableCell align="right" sx={{ pr: 3 }}>
            <IconButton
              aria-label="edit"
              sx={iconButtonStyles}
              onClick={() => handleEditTask(task.id)}
            >
              <Icon>edit</Icon>
            </IconButton>
          </TableCell>
        ) : (
          <TableCell />
        )}
      </TableRow>
    </>
  );
}

Row.propTypes = {
  task: PropTypes.object.isRequired,
  handleEditTask: PropTypes.func,
  editable: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default function TaskTable({
  lesson,
  editable = false,
  refreshLesson = () => {},
}) {
  const { hasAccess } = usePermissions();

  const [gradeTaskModalIsOpen, setGradeTaskModalIsOpen] = useState(false);
  const [taskId, setTaskId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const openGradeTaskModal = () => setGradeTaskModalIsOpen(true);
  const closeGradeTaskModal = () => {
    refreshLesson(true);
    setIsLoading(true);
    setGradeTaskModalIsOpen(false);
  };

  const handleEditTask = (id) => {
    setTaskId(id);
    openGradeTaskModal();
  };

  useEffect(() => {
    setIsLoading(false);
  }, [lesson]);

  return (
    <TableContainer
      sx={{
        maxHeight: "100%",
        boxShadow: "none",
        borderRadius: "0",
        marginTop: 3,
        overflowY: "scroll",
      }}
    >
      <Table
        stickyHeader
        aria-label="collapsible table"
        style={{ borderSpacing: "0 0.2rem" }}
      >
        <TableHead>
          {lesson.tasks.length ? (
            <TableRow
              sx={{
                "& > *": { backgroundColor: "white!important" },
              }}
            >
              <HeaderCell>
                <HeaderCellText>#</HeaderCellText>
              </HeaderCell>
              <HeaderCell>
                <HeaderCellText>Grade</HeaderCellText>
              </HeaderCell>
              <HeaderCell>
                <HeaderCellText>Task</HeaderCellText>
              </HeaderCell>
              {editable &&
              hasAccess([systemPermissions.LMS_GRADE_ANY_LESSON]) ? (
                <HeaderCell alignRight>
                  <HeaderCellText alignRight>Actions</HeaderCellText>
                </HeaderCell>
              ) : (
                <HeaderCell />
              )}
            </TableRow>
          ) : (
            <TableRow
              sx={{
                "& > *": { backgroundColor: "white!important" },
              }}
            >
              <HeaderCell>
                <HeaderCellText>
                  <i>No Tasks</i>
                </HeaderCellText>
              </HeaderCell>
            </TableRow>
          )}
        </TableHead>
        <TableBody>
          {lesson.tasks?.map((task) => (
            <Row
              task={task}
              key={task.index}
              handleEditTask={handleEditTask}
              editable={editable}
              isLoading={isLoading && task.id === taskId}
            />
          )) ?? (
            <TableRow>
              <TableCell sx={{ color: grey[500] }}>
                <i>No Tasks Added</i>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Modal
        open={gradeTaskModalIsOpen}
        onClose={closeGradeTaskModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <GradeTaskModal
            taskId={taskId}
            lesson={lesson}
            handleClose={closeGradeTaskModal}
          />
        </SoftBox>
      </Modal>
    </TableContainer>
  );
}

TaskTable.propTypes = {
  lesson: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  refreshLesson: PropTypes.func,
};
