import { createContext, useContext, useEffect, useState, useMemo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import {
  useCollection,
  useCollectionOnce,
} from "react-firebase-hooks/firestore";
import { useUser } from "src/features/user/UserProvider";
import { collection, query, where, getDocs, doc } from "firebase/firestore";
import {
  getClubsFromLocations,
  getUsersInClub,
  getUsersInLocation,
} from "src/features/club/ClubDataUtils";
import { setRouter, useSoftUIController } from "src/context";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getLocalUserSetting,
  setLocalUserSetting,
} from "src/features/user/LocalUserSettings";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "src/features/firebase/auth/utils";
import {
  getUserPermissionsCollection,
  getUserPermissionsCollectionGroup,
  getUsersCollection,
} from "src/features/user/collections";
import {
  getClubLocationsCollection,
  getClubsCollection,
  getClubAccountsCollection,
  getClubUserRoles,
  getClubDocumentTypesCollection,
  getClubInstructorPreferencesCollection,
  getClubSquawkStatusesCollection,
} from "src/features/club/collections";
import {
  getLocationReservationTypesCollection,
  getLocationsCollectionGroup,
} from "src/features/location/collections";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import { AuthLoadingPage } from "src/components/AuthLoadingPage";

import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { standardDocumentTypes } from "src/config/localDocuments";
import { standardSquawkStatuses } from "src/config/defaultSquawkStatuses";
import { standardReservationTypes } from "src/config/defaultReservationTypes";
import { uniqBy } from "lodash-es";
import { getBillingCollection } from "../billing/collections";
import useRealtimeDocumentData from "../firebase/firestore/useRealtimeDocumentData";

const ClubContext = createContext(null);

ClubContext.displayName = "ClubContext";

function ClubProvider({ children }) {
  const [staffUsers, setStaffUsers] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [instructorPreferences, setInstructorPreferences] = useState(new Map());
  const [maintenanceUsers, setMaintenanceUsers] = useState([]);
  const [selectedClubId, setSelectedClubId] = useState(null);
  const [selectedLocationId, setSelectedLocationId] = useState(null);
  const [clubLocations, setClubLocations] = useState([]);
  const [clubMemberships, setClubMemberships] = useState([]);
  const [clubRates, setClubRates] = useState(new Map());
  const [userClubs, setUserClubs] = useState([]);
  const [userLocations, setUserLocations] = useState([]);
  const [isContextLoading, setIsContextLoading] = useState(true);
  const [locationUsers, setLocationUsers] = useState([]);
  const [globalUsers, setGlobalUsers] = useState(null);
  const [globalUsersByEmail, setGlobalUsersByEmail] = useState(null);
  const [clubUsers, setClubUsers] = useState([]);
  const [clubUserRoles, setClubUserRoles] = useState([]);
  const [clubUserRolesUserSummary, setClubUserRolesUserSummary] = useState({});
  const [clubUsersPermissions, setClubUsersPermissions] = useState(null);
  const [clubPrefs, setClubPrefs] = useState([]);
  const [currentUserPermission, setCurrentUserPermission] = useState([]);
  const [clubUsersExcludingMe, setClubUsersExcludingMe] = useState([]);
  const { pathname } = useLocation();

  const [selectedClub, setSelectedClub] = useState({});
  const [selectedLocation, setSelectedLocation] = useState({});
  const [memberHasNoClub, setMemberHasNoClub] = useState(false);

  const [savedUser] = useAuthState(auth);

  const { userId } = useUser();
  const navigate = useNavigate();
  const [, dispatch] = useSoftUIController();

  useEffect(() => {
    if (selectedClub) {
      datadogRum.setUserProperty("clubId", selectedClub.id);
      datadogRum.setUserProperty("clubName", selectedClub.name);
      datadogLogs.setUserProperty("clubId", selectedClub.id);
      datadogLogs.setUserProperty("clubName", selectedClub.name);
    } else {
      datadogRum.removeUserProperty("clubId");
      datadogRum.removeUserProperty("clubName");
      datadogLogs.removeUserProperty("clubId");
      datadogLogs.removeUserProperty("clubName");
    }
  }, [selectedClub]);

  useEffect(() => {
    if (selectedLocation) {
      datadogRum.setUserProperty("locationId", selectedLocation.id);
      datadogRum.setUserProperty("locationIcao", selectedLocation.icao);
      datadogRum.setUserProperty("locationName", selectedLocation.name);
      datadogLogs.setUserProperty("locationId", selectedLocation.id);
      datadogLogs.setUserProperty("locationIcao", selectedLocation.icao);
      datadogLogs.setUserProperty("locationName", selectedLocation.name);
    } else {
      datadogLogs.removeUserProperty("locationIcao");
      datadogLogs.removeUserProperty("locationId");
      datadogLogs.removeUserProperty("locationName");
    }
  }, [selectedLocation]);

  const [userLocationsSnap, isUserLocationsLoading] = useCollection(
    userId && collection(getUsersCollection(), userId, "permissions"),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    }
  );

  const [instructorPrefsSnap, isInstructorPrefsLoading] = useCollection(
    !!selectedClubId &&
      !!selectedLocationId &&
      getClubInstructorPreferencesCollection(
        selectedClubId,
        selectedLocationId
      ),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    }
  );

  const [userRolesSnap, isUserRolesLoading] = useCollection(
    selectedClubId &&
      query(
        getClubUserRoles(selectedClubId),
        where("locationId", "==", selectedLocationId)
      ),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    }
  );

  const permissionsSubCollectionQuery =
    selectedClubId &&
    query(
      getUserPermissionsCollectionGroup(),
      where("clubId", "==", selectedClubId)
    );
  const [clubUserPermissionsSnap, isClubUserPermissionsLoading] = useCollection(
    permissionsSubCollectionQuery,
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    }
  );

  const globalUsersQuery = selectedClubId && query(getUsersCollection());
  const [
    globalUsersSnap,
    isGlobalUsersLoading,
    globalUsersSnapError,
    globalUsersSnapReload,
  ] = useCollectionOnce(globalUsersQuery, {
    snapshotListenOptions: {
      includeMetadataChanges: false,
    },
  });

  const { data: clubDocumentTypes, isDataLoaded: clubDocumentTypesLoaded } =
    useCollection(
      selectedClubId &&
        query(
          getClubDocumentTypesCollection(selectedClubId),
          where("deleted", "==", false)
        ),
      {
        snapshotListenOptions: {
          includeMetadataChanges: true,
        },
      }
    );

  const [allDocumentTypes, setAllDocumentTypes] = useState([]);

  useEffect(() => {
    const types = [...standardDocumentTypes];
    if (clubDocumentTypesLoaded) {
      clubDocumentTypes.forEach((element) => {
        types.push({
          label: element.name,
          value: element.id,
        });
      });
    }
    setAllDocumentTypes(types);
  }, [clubDocumentTypes, clubDocumentTypesLoaded]);

  const { data: clubSquawkStatuses, isDataLoaded: clubSquawkStatusesLoaded } =
    useRealtimeCollectionData(
      getClubSquawkStatusesCollection(selectedClubId),
      false
    );

  const [squawkStatuses, setSquawkStatuses] = useState([]);

  useEffect(() => {
    const statuses = [...standardSquawkStatuses];
    if (clubSquawkStatusesLoaded) {
      clubSquawkStatuses.forEach((element) => {
        statuses.push({
          id: element.id,
          label: element.label,
          grounding: element.grounding,
          value: element.label,
        });
      });
    }
    setSquawkStatuses(statuses);
  }, [clubSquawkStatuses, clubSquawkStatusesLoaded]);

  const {
    data: locationReservationTypesData,
    isDataLoaded: locationReservationTypesDataLoaded,
  } = useRealtimeCollectionData(
    getLocationReservationTypesCollection(selectedClubId, selectedLocationId),
    true
  );

  const [locationReservationTypes, setLocationReservationTypes] = useState(
    new Map()
  );

  useEffect(() => {
    const reservationTypes = standardReservationTypes.reduce((acc, item) => {
      acc.set(item.id, item);
      return acc;
    }, new Map());
    if (locationReservationTypesData && locationReservationTypesDataLoaded) {
      locationReservationTypesData.forEach((element) => {
        if (element.deleted) {
          reservationTypes.delete(element.id);
        } else {
          reservationTypes.set(element.id, element);
        }
      });
    }
    setLocationReservationTypes(reservationTypes);
  }, [locationReservationTypesData, locationReservationTypesDataLoaded]);

  useEffect(() => {
    if (instructorPrefsSnap && !isInstructorPrefsLoading) {
      const instructorPrefsMap = new Map(
        instructorPrefsSnap.docs.map((obj) => [
          obj.id,
          { ...obj.data(), id: obj.id },
        ])
      );
      setInstructorPreferences(instructorPrefsMap);
    }
  }, [instructorPrefsSnap, isInstructorPrefsLoading]);

  useEffect(() => {
    if (globalUsersSnap && !isGlobalUsersLoading) {
      const globalUsersMap = new Map(
        globalUsersSnap.docs.map((obj) => [
          obj.id,
          { ...obj.data(), id: obj.id },
        ])
      );
      const globalUsersByEmailMap = new Map(
        globalUsersSnap.docs.map((obj) => {
          const dataObject = obj.data();
          return [dataObject.email, { ...dataObject, id: obj.id }];
        })
      );
      setGlobalUsers(globalUsersMap);
      setGlobalUsersByEmail(globalUsersByEmailMap);
    }
  }, [globalUsersSnap, isGlobalUsersLoading]);

  useEffect(() => {
    if (globalUsersSnapError)
      console.error("globalUserSnap ERROR", globalUsersSnapError);
  }, [globalUsersSnapError]);

  useMemo(() => {
    if (!clubUserPermissionsSnap) return;
    let roleCounter = {};
    setClubUsersPermissions(() => {
      const mapData = clubUserPermissionsSnap?.docs?.map((obj) => {
        const permission = obj.data();
        const userId = obj.ref.parent.parent.id;
        if (Array.isArray(permission.userRoles)) {
          permission.userRoles.forEach((roleId) => {
            if (!roleCounter[roleId]?.count) roleCounter[roleId] = { count: 0 };
            if (!roleCounter[roleId]?.users) roleCounter[roleId].users = [];
            roleCounter[roleId].count = (roleCounter[roleId].count ?? 0) + 1;
            roleCounter[roleId].users =
              roleCounter[roleId].users.concat(userId);
          });
        }
        return [userId, { ...permission, id: obj.id }];
      });

      setClubUserRolesUserSummary(roleCounter);

      return new Map(mapData);
    });

    if (!isGlobalUsersLoading) {
      globalUsersSnapReload();
    }
  }, [clubUserPermissionsSnap]);

  const clubsCollectionRef = getClubsCollection();
  const { data: allClubs, isDataLoaded: clubsLoaded } =
    useRealtimeCollectionData(clubsCollectionRef, true);

  const [clubMembershipsSnap, isClubMembershipsLoading] = useCollection(
    selectedClubId &&
      query(
        collection(getClubsCollection(), selectedClubId, "membershipPlans"),
        where("deleted", "==", false)
      ),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    }
  );
  const [clubRatesSnap, isClubRatesLoading] = useCollection(
    selectedClubId &&
      query(collection(getClubsCollection(), selectedClubId, "rentalRates")),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    }
  );

  const locationsCollectionRef = getLocationsCollectionGroup();
  const { data: allLocations } = useRealtimeCollectionData(
    locationsCollectionRef,
    true
  );

  const accountsCollectionRef = getClubAccountsCollection(selectedClubId);
  const { data: clubAccounts } = useRealtimeCollectionData(
    accountsCollectionRef,
    false
  );

  const { data: accountBalances } = useRealtimeDocumentData(
    doc(
      getBillingCollection(),
      userId || "-",
      "clubAccounts",
      selectedClubId || "-"
    ),
    false
  );

  const [clubPrefsCollectionSnap, isClubPrefsLoading] = useCollection(
    userId && collection(getUsersCollection(), userId, "clubPrefs"),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    }
  );

  const [currentUserPermissionSnap, isCurrentUserPermissionLoading] =
    useCollection(
      userId &&
        selectedLocation?.id &&
        query(
          getUserPermissionsCollection(userId),
          where("locationId", "==", selectedLocation?.id)
        ),
      {
        snapshotListenOptions: {
          includeMetadataChanges: true,
        },
      }
    );

  useEffect(() => {
    if (clubPrefsCollectionSnap?.docs?.length > 0 && selectedClubId) {
      const clubPrefsData = clubPrefsCollectionSnap.docs.find(
        (e) => e.id === selectedClubId
      );
      if (clubPrefsData) {
        setClubPrefs({ ...clubPrefsData.data(), id: clubPrefsData.id });
      }
    } else {
      setClubPrefs(null);
    }
  }, [selectedClubId, clubPrefsCollectionSnap, isClubPrefsLoading]);

  useEffect(() => {
    if (currentUserPermissionSnap?.docs?.length > 0 && selectedClubId) {
      const userPermissions = currentUserPermissionSnap.docs
        .filter((permission) => !permission?.data()?.deleted)
        .map((permission) => ({ ...permission.data(), id: permission.id }));

      setCurrentUserPermission(userPermissions);
    } else {
      setCurrentUserPermission([]);
    }
  }, [
    selectedClubId,
    selectedLocationId,
    userLocationsSnap,
    currentUserPermissionSnap,
    isCurrentUserPermissionLoading,
  ]);

  useEffect(() => {
    if (
      savedUser?.reloadUserInfo?.customAttributes &&
      JSON.parse(savedUser.reloadUserInfo.customAttributes).superadmin
    ) {
      getDocs(getLocationsCollectionGroup()).then((locations) => {
        setUserLocations(locations.docs);
      });
    }
    if (userLocationsSnap && !isUserLocationsLoading) {
      const filteredLocations = [];
      userLocationsSnap.docs.forEach((location) => {
        if (location?.data()?.deleted) return;
        filteredLocations.push(location);
      });
      setUserLocations(filteredLocations);
    }
  }, [userLocationsSnap]);

  useEffect(() => {
    if (userRolesSnap && !isUserRolesLoading) {
      const userRolesParsed = [];
      userRolesSnap.docs.forEach((userRole) => {
        const fbData = userRole.data();
        if (fbData.deleted) return;
        userRolesParsed.push({ ...fbData, id: userRole.id });
      });
      setClubUserRoles(userRolesParsed);
    }
  }, [userRolesSnap]);

  useEffect(() => {
    if (clubMembershipsSnap && !isClubMembershipsLoading) {
      setClubMemberships(clubMembershipsSnap.docs);
    }
  }, [clubMembershipsSnap]);

  useEffect(() => {
    if (clubRatesSnap && !isClubRatesLoading) {
      const clubRatesMap = new Map(
        clubRatesSnap.docs.map((obj) => [obj.id, { ...obj.data(), id: obj.id }])
      );
      setClubRates(clubRatesMap);
    }
  }, [clubRatesSnap]);

  useEffect(() => {
    if (!userId) return;
    console.debug("Loading clubs and locations for user", userId);

    if (
      savedUser?.reloadUserInfo?.customAttributes &&
      JSON.parse(savedUser.reloadUserInfo.customAttributes).superadmin
    ) {
      setSelectedClubId("backoffice");
      setSelectedLocationId("backoffice");
    }

    // if a club is saved when the user changes, fetch it
    const savedClubId = getLocalUserSetting(userId)?.selectedClubId;
    if (savedClubId) {
      setSelectedClubId(savedClubId);
      const savedLocationId =
        getLocalUserSetting(userId)?.[`selectedLocationId-${savedClubId}`];
      if (savedLocationId) {
        setSelectedLocationId(savedLocationId);
      }
    }
  }, [userId]);

  useEffect(() => {
    if (!selectedClubId || !userLocations) return;
    // when a new club is selected, save it against the user in localstorage and update its locations
    const savedClubId = getLocalUserSetting(userId)?.selectedClubId;
    if (savedClubId !== selectedClubId) {
      setLocalUserSetting(userId, {
        selectedClubId,
      });
    }

    if (!userLocations) return;
    getDocs(getClubLocationsCollection(selectedClubId)).then((locations) => {
      const filteredClubLocations = [];
      locations.docs.forEach((location) => {
        if (userLocations.some((e) => e.id === location.id)) {
          filteredClubLocations.push({
            id: location.id,
            ...location.data(),
          });
        }
      });

      if (selectedClubId) {
        const savedLocationId =
          getLocalUserSetting(userId)?.[`selectedLocationId-${selectedClubId}`];
        if (
          (!savedLocationId ||
            filteredClubLocations.filter((e) => e.id === savedLocationId)
              .length === 0) &&
          filteredClubLocations.length > 0
        ) {
          setSelectedLocationId(filteredClubLocations[0]?.id);
          setLocalUserSetting(userId, {
            [`selectedLocationId-${selectedClubId}`]: selectedLocationId,
          });
        } else if (
          savedLocationId &&
          filteredClubLocations.filter((e) => e.id === savedLocationId).length >
            0
        ) {
          setSelectedLocationId(savedLocationId);
        }
      }

      setClubLocations(filteredClubLocations);
    });
  }, [selectedClubId, userLocations]);

  const getUsersByRole = (role) => {
    const clubRoles = clubUserRoles.filter((r) =>
      r.configuration?.roleTypes?.includes(role)
    );

    const users = clubUserPermissionsSnap?.docs
      ?.filter((doc) => {
        const data = doc.data();
        if (data.deleted) return false;
        return (
          data.userRoles &&
          Array.isArray(data.userRoles) &&
          data.userRoles?.some((role) => clubRoles?.some((r) => r.id === role))
        );
      })
      .map((doc) => globalUsers?.get(doc.ref.parent.parent.id));

    return uniqBy(users, (u) => u?.uid);
  };

  useEffect(() => {
    if (!selectedClubId || !clubUserRoles || !clubUsers || !globalUsers) return;
    setInstructors(getUsersByRole("Instructor"));
    setStaffUsers(getUsersByRole("Staff"));
    setMaintenanceUsers(getUsersByRole("Maintenance"));
  }, [selectedClubId, clubUserRoles, clubUsers, globalUsers]);

  const getLocationUsers = async () => {
    const locationUserList = await getUsersInLocation(
      clubUserPermissionsSnap?.docs,
      globalUsers,
      selectedLocationId
    );
    setLocationUsers(locationUserList);
  };

  useEffect(() => {
    if (!selectedLocationId || !globalUsers) return;
    getLocationUsers(selectedLocationId);
  }, [selectedLocationId, globalUsers]);

  useEffect(() => {
    if (
      !userId ||
      !userLocations ||
      userLocations.length === 0 ||
      !clubUserPermissionsSnap ||
      clubUserPermissionsSnap?.empty
    )
      return;
    const locationIds = userLocations.map((location) => location.id);

    if (locationIds.length > 0) {
      getUsersInClub(clubUserPermissionsSnap?.docs, globalUsers).then(
        (users) => {
          setClubUsers(users);
          setClubUsersExcludingMe(users.filter((e) => e.id !== userId));
        }
      );
    }
  }, [userId, userLocations, clubUserPermissionsSnap, globalUsers]);

  useEffect(() => {
    // get all clubs for user
    if (!userLocations) return;
    if (userLocations.length === 0) {
      if (clubsLoaded && userClubs.length === 0) {
        setIsContextLoading(false);
        setMemberHasNoClub(true);
        return;
      }

      if (
        savedUser?.reloadUserInfo?.customAttributes &&
        JSON.parse(savedUser.reloadUserInfo.customAttributes).superadmin
      ) {
        setIsContextLoading(false);
      }

      return;
    }

    const userLocationIds = userLocations.map(
      (userLocationsDoc) => userLocationsDoc.id
    );
    getClubsFromLocations(userLocationIds).then((clubs) => {
      if (
        savedUser?.reloadUserInfo?.customAttributes &&
        JSON.parse(savedUser.reloadUserInfo.customAttributes).superadmin
      ) {
        clubs.unshift({
          id: "backoffice",
          data: () => ({ name: "Back Office" }),
        });
      }
      setUserClubs(clubs);
      if (userLocationIds.length === 1 && !selectedLocationId) {
        // if user has only one location, select it
        setSelectedLocationId(userLocationIds[0]);
        setSelectedClubId(clubs[0].id);
      }
      setIsContextLoading(false);
    });
  }, [userLocations]);

  useEffect(() => {
    if (
      pathname.split("/")[1] === "terms" ||
      pathname.split("/")[1] === "intro-flight"
    ) {
      return;
    }
    // if clubs are not selected and there are more than one for a user, goto club selection
    if (userId && !isContextLoading) {
      if (userClubs?.length > 1 && !selectedClubId) {
        navigate("/auth/club-select", {
          replace: true,
        });
      } else {
        if (userClubs?.length === 1 && !selectedClubId) {
          setSelectedClubId(userClubs[0]?.id);
        }
        setRouter(dispatch, "main");
      }
    }
  }, [userId, isContextLoading]);

  useEffect(() => {
    if (!userId) {
      setSelectedClubId();
      setSelectedLocationId();
    }
  }, [userId]);

  const getClubFromId = (clubId) => allClubs.find((club) => club.id === clubId);
  const getLocationFromId = (locId) =>
    allLocations?.find((loc) => loc.id === locId);

  useEffect(() => {
    if (allClubs?.length > 0 && selectedClubId) {
      const club = getClubFromId(selectedClubId);
      setSelectedClub(club);
    }
  }, [selectedClubId, allClubs]);

  // useEffect(() => {
  //   if (!selectedClubId) return;
  //   console.debug('useEffect - selectedLocationId, allLocations');
  //   onSnapshot(doc(getClubsCollection(), selectedClubId), (clubDoc) => {
  //     console.log('clubDoc', clubDoc.data());
  //     setSelectedClub({ ...clubDoc.data(), id: clubDoc.id });
  //   });
  // }, [selectedClubId]);

  useEffect(() => {
    if (allLocations?.length > 0 && selectedLocationId) {
      const loc = getLocationFromId(selectedLocationId);
      setSelectedLocation(loc);
    }

    if (selectedLocationId && selectedClubId) {
      setLocalUserSetting(userId, {
        [`selectedLocationId-${selectedClubId}`]: selectedLocationId,
      });
    }
  }, [selectedLocationId, allLocations]);

  const clubRolesBySelectedLocation = useMemo(() => {
    if (!selectedLocationId || !clubUserRoles) return [];
    return clubUserRoles.filter(r => r.locationId === selectedLocationId)
  }, [clubUserRoles, selectedLocationId])

  if (
    isClubUserPermissionsLoading ||
    isUserLocationsLoading ||
    isClubMembershipsLoading ||
    isClubPrefsLoading
  ) {
    return <AuthLoadingPage />;
  }

  const value = {
    isContextLoading,
    isUserLocationsLoading,

    selectedClubId,
    selectedLocationId,
    selectedClub,
    selectedLocation,
    clubPrefs,
    clubAccounts,
    accountBalances,

    getClubFromId,
    getLocationFromId,

    setSelectedLocationId,
    setSelectedClubId,
    globalUsersSnapReload,

    staffUsers,
    instructors,
    instructorPreferences,
    maintenanceUsers,
    allClubs,
    userClubs,
    allLocations,
    clubLocations,
    clubMemberships,
    clubRates,
    isClubMembershipsLoading,
    userLocations,
    memberHasNoClub,
    clubUsers,
    clubUsersPermissions,
    clubUsersExcludingMe,
    globalUsers,
    globalUsersByEmail,
    locationUsers,
    clubUserRoles,
    clubRolesBySelectedLocation,
    currentUserPermission,
    clubUserRolesUserSummary,
    clubDocuments: allDocumentTypes,

    squawkStatuses,
    locationReservationTypes,
  };

  return <ClubContext.Provider value={value}>{children}</ClubContext.Provider>;
}

function useClubs() {
  const context = useContext(ClubContext);

  if (!context) {
    throw new Error("useClubs should be used inside the ClubProvider.");
  }

  return context;
}

ClubProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ClubProvider, useClubs };
