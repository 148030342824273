import { db } from "src/features/firebase/auth/utils";
import { addDoc, deleteDoc, doc, getDoc, setDoc } from "firebase/firestore";

export const buildRef = (entity, pathSegmentsArr) => {
  if (entity) {
    if (pathSegmentsArr) {
      if (pathSegmentsArr.filter((c) => c === undefined).length === 0) {
        const path = pathSegmentsArr.join("/");
        return doc(db, `${entity.path}/${path}`);
      }
    } else {
      return doc(entity);
    }
  }
  return null;
};

export function entityCrudUtils() {
  const getRef = (entity, pathSegmentsArr) => {
    let ref;
    if (entity) {
      ref = buildRef(entity, pathSegmentsArr);
    }
    return ref;
  };

  const getDataFromRef = async (docRef) => {
    const docSnap = await getDoc(docRef);
    return { ...docSnap.data(), id: docSnap.id };
  };

  const getData = async ({ entity, pathSegmentsArr }) => {
    const ref = getRef(entity, pathSegmentsArr);
    const docSnap = await getDoc(ref);
    return { ...docSnap.data(), id: docSnap.id };
  };

  const updateDataFromRef = async (docRef, data) => {
    const { ...rest } = data;
    await setDoc(
      docRef,
      {
        ...rest,
      },
      { merge: true }
    );
  };

  const updateData = async ({ entity, pathSegmentsArr }, data) => {
    const ref = getRef(entity, pathSegmentsArr);
    await updateDataFromRef(ref, data);
  };

  const addData = async (collectionRef, data) => {
    const { ...rest } = data;
    const result = await addDoc(collectionRef, {
      ...rest,
      deleted: false,
    });
    return result;
  };

  const deleteDataFromRef = async (docRef) => {
    await setDoc(
      docRef,
      {
        deleted: true,
        deletedAt: new Date(),
      },
      { merge: true }
    );
  };

  const deleteData = async ({ entity, pathSegmentsArr }) => {
    const ref = getRef(entity, pathSegmentsArr);
    await deleteDataFromRef(ref);
  };

  const hardDeleteData = async (docRef) => {
    await deleteDoc(docRef);
  };

  return {
    getData,
    getDataFromRef,
    addData,
    updateDataFromRef,
    updateData,
    deleteDataFromRef,
    deleteData,
    hardDeleteData,
    getRef,
  };
}
