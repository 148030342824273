import { useState, useMemo } from "react";
// import PropTypes from 'prop-types';

// @mui material components
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import DataTable from "src/components/Tables/DataTable";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftButton from "src/components/SoftButton";
import SoftTypography from "src/components/SoftTypography";

import { AddOwnerModal } from "src/modals/AddOwnerModal";

import Swal from "sweetalert2";
import { getTableColumns, TableActions } from "./tableColumns";

function Owners({ owners, formErrors, setValue }) {
  const [openAddOwnerModal, setOpenAddOwnerModal] = useState(false);
  const [selectedOwner, setSelectedOwner] = useState(null);

  const deleteOwner = (owner) => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button",
        confirmButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "You will not be able to recover this location! Existing resources and members will be removed from this locaiton.",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        icon: "warning",
      })
      .then((result) => {
        if (result.isConfirmed) {
          const newOwners = owners.filter((o) => o.email !== owner.email);
          setValue("owners", newOwners);
          // setOwners(newOwners);
        }
      });
  };

  const handleTableActions = (action, owner) => {
    switch (action) {
      case TableActions.DELETE:
        deleteOwner(owner);
        break;
      case TableActions.EDIT:
        setSelectedOwner(owner);
        setOpenAddOwnerModal(true);
        break;

      default:
        break;
    }
  };

  const handleAddOwnerModalClose = (owner) => {
    if (owner?.firstName) {
      setValue("owners", [...owners, owner]);
    }
    setOpenAddOwnerModal(false);
    // setSelectedLocation(null);
  };

  const tableColumns = useMemo(() => getTableColumns(handleTableActions), []);

  return (
    <SoftBox>
      <SoftBox width="80%" textAlign="center" mx="auto" mb={4}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5" fontWeight="regular">
            Company Owners Details
          </SoftTypography>
        </SoftBox>
        <SoftTypography
          variant="body2"
          fontWeight="regular"
          color="text"
          pb={3}
        >
          The exact rules vary by country, but beneficial owners can include one
          or both of the following types of individuals:
        </SoftTypography>
        <SoftTypography
          component="ul"
          variant="body2"
          fontWeight="regular"
          color="text"
          pb={3}
        >
          <li>Individuals who own 25% or more of your company, and/or</li>
          <li>
            Individuals who exercise significant control over your company.
          </li>
        </SoftTypography>
        <SoftTypography variant="body2" fontWeight="regular" color="text">
          Individuals considered to “exercise significant control” over your
          company are those responsible for managing and directing the business
          and may include executive officers or senior managers, such as CEO,
          CFO, COO, Managing Member, General Partner, President, Vice President,
          or Treasurer.
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={2}>
        <Grid container spacing={12}>
          <Grid item xs={12}>
            <SoftBox
              xs={12}
              padding={1}
              sx={{
                maxWidth: "100%",
                overflow: "hidden",
              }}
            >
              <DataTable
                entriesPerPage={false}
                maxWidth="100%"
                table={{
                  columns: tableColumns,
                  rows: owners || [],
                }}
              />
              {formErrors?.owners?.message && (
                <SoftTypography marginTop={1} fontSize={12} color="error">
                  {formErrors.owners.message}
                </SoftTypography>
              )}
            </SoftBox>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} justifyContent="flex-end" display="flex">
            <SoftButton
              color="primary"
              variant="contained"
              type="submit"
              onClick={() => setOpenAddOwnerModal(true)}
            >
              Add Owner
            </SoftButton>
          </Grid>
        </Grid>
      </SoftBox>
      <Modal
        open={openAddOwnerModal}
        onClose={handleAddOwnerModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <AddOwnerModal
            handleClose={handleAddOwnerModalClose}
            owner={selectedOwner}
          />
        </SoftBox>
      </Modal>
    </SoftBox>
  );
}

export default Owners;
