import { useEffect, useState } from "react";

// @mui material components
import { Card, Grid, Modal } from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";
import SoftInput from "src/components/SoftInput";

// Security page components
import FormField from "src/pages/user/account/components/FormField";
import AddMissingStripeDetailsModal from "src/modals/AddMissingStripeDetailsModal";

import { getFunctions, httpsCallable } from "firebase/functions";
import { useClubs } from "src/features/club/ClubProvider";

function StripeDetails() {
  const { selectedClubId, selectedClub } = useClubs();
  const [stripeDetails, setStripeDetails] = useState({});
  const [hasSeparateChargeAccount, setHasSeparateChargeAccount] =
    useState(false);
  const [stripeChargeDetails, setStripeChargeDetails] = useState({});
  const [accountError, setAccountError] = useState(null);
  const [missingAccountData, setMissingAccountData] = useState(false);
  const [stripeChargeAccount, setStripeChargeAccount] = useState(null);
  const [openVerifyDetails, setOpenVerifyDetails] = useState(false);

  const handleVerifyDetailsOpen = () => {
    setOpenVerifyDetails(true);
  };

  const handleVerifyDetailsClose = () => {
    setOpenVerifyDetails(false);
  };

  const fetchAccounts = async () => {
    try {
      const functions = getFunctions();
      const getStripeConnectedBankAccounts = httpsCallable(
        functions,
        "getStripeConnectedBankAccounts"
      );
      const accounts = await getStripeConnectedBankAccounts({
        stripeAccountId: selectedClub.stripeAccountId,
      });

      const defaultAccount = accounts.data.data.find(
        (account) => account.default_for_currency
      );
      if (defaultAccount) {
        setStripeDetails({
          ...defaultAccount,
          account_number: `********${defaultAccount.last4}`,
        });
      }

      if (selectedClub.stripeChargeAccountId) {
        const getStripeConnectedAccount = httpsCallable(
          functions,
          "getStripeConnectedAccount"
        );
        const account = await getStripeConnectedAccount({
          stripeAccountId: selectedClub.stripeChargeAccountId,
        });
        if (account?.data) {
          setStripeChargeAccount(account.data);
        }
        if (
          account.data.requirements.currently_due.length > 0 ||
          account.data.requirements.eventually_due.length > 0 ||
          account.data.requirements.past_due.length > 0
        ) {
          setMissingAccountData(true);
        }

        const accountsPayable = await getStripeConnectedBankAccounts({
          stripeAccountId: selectedClub.stripeChargeAccountId,
        });
        const defaultChargeAccount = accountsPayable.data.data.find(
          (account) => account.default_for_currency
        );
        if (defaultChargeAccount) {
          setStripeChargeDetails({
            ...defaultChargeAccount,
            account_number: `********${defaultChargeAccount.last4}`,
          });
        }
        setHasSeparateChargeAccount(true);
      }
    } catch (error) {
      console.error("CUSTOMER ERROR", error);
    }
  };

  useEffect(() => {
    if (!selectedClub.stripeAccountId) return;
    fetchAccounts();
  }, [selectedClub.stripeAccountId]);

  const setAcctName = (e) => {
    setStripeDetails({ ...stripeDetails, account_holder_name: e.target.value });
  };

  const setAcctNumber = (e) => {
    setStripeDetails({ ...stripeDetails, account_number: e.target.value });
    setAccountError(null);
  };

  const setRoutingNumber = (e) => {
    setStripeDetails({ ...stripeDetails, routing_number: e.target.value });
  };

  const setChargeAcctName = (e) => {
    setStripeChargeDetails({
      ...stripeChargeDetails,
      account_holder_name: e.target.value,
    });
  };

  const setChargeAcctNumber = (e) => {
    setStripeChargeDetails({
      ...stripeChargeDetails,
      account_number: e.target.value,
    });
    setAccountError(null);
  };

  const setChargeRoutingNumber = (e) => {
    setStripeChargeDetails({
      ...stripeChargeDetails,
      routing_number: e.target.value,
    });
  };

  const updateBankDetails = async () => {
    if (stripeDetails.account_number.includes("*")) {
      setAccountError(
        "Please re-enter a valid account number. We only store the last 4 digits."
      );
      return;
    }
    try {
      const functions = getFunctions();
      const updateStripeConnectedBankAccount = httpsCallable(
        functions,
        "updateStripeConnectedBankAccount"
      );
      const account = await updateStripeConnectedBankAccount({
        clubId: selectedClubId,
        stripeAccountId: selectedClub.stripeAccountId,
        stripeDetails,
      });
      setStripeDetails({
        ...account.data,
        account_number: `********${account.data.last4}`,
      });
    } catch (error) {
      console.error("CUSTOMER ERROR", error);
    }
  };

  const updateChargeBankDetails = async () => {
    if (stripeChargeDetails.account_number.includes("*")) {
      setAccountError(
        "Please re-enter a valid account number. We only store the last 4 digits."
      );
      return;
    }
    try {
      const functions = getFunctions();
      const updateStripeConnectedChargeAccount = httpsCallable(
        functions,
        "updateStripeConnectedChargeAccount"
      );
      const account = await updateStripeConnectedChargeAccount({
        clubId: selectedClubId,
        stripeAccountId: selectedClub.stripeChargeAccountId,
        stripeDetails: stripeChargeDetails,
      });
      setStripeChargeDetails({
        ...account.data,
        account_number: `********${account.data.last4}`,
      });
    } catch (error) {
      console.error("CUSTOMER ERROR", error);
    }
  };

  return (
    <Card id="change-password" style={{ backgroundColor: "#eeeeee" }}>
      <SoftBox pt={2} px={2} lineHeight={1}>
        <SoftTypography variant="h6" fontWeight="medium">
          {hasSeparateChargeAccount ? "Account Receivable" : "Bank Account"}
        </SoftTypography>
        <SoftTypography variant="button" fontWeight="regular" color="text">
          Please ensure these details are correct, incorrect bank details may
          result in loss of funds.
        </SoftTypography>
      </SoftBox>
      <SoftBox component="form" p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormField
              label="account holder name"
              inputProps={{
                autoComplete: "",
              }}
              value={stripeDetails.account_holder_name ?? ""}
              onChange={setAcctName}
            />
          </Grid>
          <Grid item xs={6}>
            <SoftBox
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
            >
              <SoftBox ml={0.5} mb={1} display="inline-block" lineHeight={0}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Account Number
                </SoftTypography>
              </SoftBox>
              <SoftInput
                inputProps={{
                  autoComplete: "",
                }}
                value={stripeDetails.account_number ?? ""}
                onChange={setAcctNumber}
              />
            </SoftBox>
            {accountError && (
              <SoftTypography marginTop={1} fontSize={12} color="error">
                {accountError}
              </SoftTypography>
            )}
          </Grid>
          <Grid item xs={6}>
            <FormField
              label="Routing Number"
              inputProps={{
                autoComplete: "",
              }}
              value={stripeDetails.routing_number ?? ""}
              onChange={setRoutingNumber}
            />
          </Grid>
        </Grid>
        <SoftBox mt={2}>
          <SoftButton
            variant="gradient"
            color="dark"
            fullWidth
            onClick={updateBankDetails}
          >
            update account details
          </SoftButton>
        </SoftBox>
        {/* <SoftBox mt={2}>
          <Checkbox
            checked={hasSeparateChargeAccount}
            onChange={(e) => {
              console.log(
                "hasSeparateChargeAccount",
                hasSeparateChargeAccount,
                e.target.checked
              );
              setHasSeparateChargeAccount(!hasSeparateChargeAccount);
            }}
          />
          <SoftTypography variant="button" fontWeight="regular" color="text">
            Use a separate account for charges
          </SoftTypography>
        </SoftBox> */}
      </SoftBox>
      {hasSeparateChargeAccount && (
        <>
          <SoftBox pt={2} px={2} lineHeight={1}>
            <SoftTypography variant="h6" fontWeight="medium">
              Account Payable
            </SoftTypography>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              This account will be used if you have any charges to be paid.
            </SoftTypography>
          </SoftBox>
          <SoftBox component="form" p={2}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormField
                  label="account holder name"
                  inputProps={{
                    autoComplete: "",
                  }}
                  value={stripeChargeDetails.account_holder_name ?? ""}
                  onChange={setChargeAcctName}
                />
              </Grid>
              <Grid item xs={6}>
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                >
                  <SoftBox
                    ml={0.5}
                    mb={1}
                    display="inline-block"
                    lineHeight={0}
                  >
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Account Number
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    inputProps={{
                      autoComplete: "",
                    }}
                    value={stripeChargeDetails.account_number ?? ""}
                    onChange={setChargeAcctNumber}
                  />
                </SoftBox>
                {accountError && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {accountError}
                  </SoftTypography>
                )}
              </Grid>
              <Grid item xs={6}>
                <FormField
                  label="Routing Number"
                  inputProps={{
                    autoComplete: "",
                  }}
                  value={stripeChargeDetails.routing_number ?? ""}
                  onChange={setChargeRoutingNumber}
                />
              </Grid>
            </Grid>
            {missingAccountData ? (
              <SoftBox mt={2} justifyContent="space-between" display="flex">
                <SoftButton
                  pr={3}
                  variant="gradient"
                  color="error"
                  onClick={handleVerifyDetailsOpen}
                >
                  Verify Details
                </SoftButton>
                <SoftButton
                  variant="gradient"
                  color="dark"
                  onClick={updateChargeBankDetails}
                >
                  update account
                </SoftButton>
              </SoftBox>
            ) : (
              <SoftBox mt={2}>
                <SoftButton
                  variant="gradient"
                  color="dark"
                  fullWidth
                  onClick={updateChargeBankDetails}
                >
                  update account
                </SoftButton>
              </SoftBox>
            )}
          </SoftBox>
        </>
      )}
      <Modal
        open={openVerifyDetails}
        onClose={handleVerifyDetailsClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <AddMissingStripeDetailsModal
            stripeAccount={stripeChargeAccount}
            handleClose={handleVerifyDetailsClose}
          />
        </SoftBox>
      </Modal>
    </Card>
  );
}

export default StripeDetails;
